import React, { FC, useLayoutEffect } from 'react';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import classnames from 'classnames';
import spacing from '../constants/spacing';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			page: {
				position: 'absolute',
				height: '100%',
				width: '100%',
				padding:
					'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',

				overflowY: 'auto',
				overflowX: 'hidden',
				zIndex: 0,
				backgroundColor: theme.palette.background.default,
				'&[data-background="transparent"]': {
					backgroundColor: 'transparent',
				},
				'&[data-background="theme"]': {
					backgroundColor: theme.palette.primary.main,
				},
			},
			pageContent: {
				position: 'relative',
				height: '100%',

				'$page[data-background="theme"] & > *': {
					backgroundColor: theme.palette.primary.main,
				},
				'$page[data-background="transparent"] & > *': {
					backgroundColor: theme.palette.primary.main,
				},
			},
			spaceForHeader: {
				paddingTop: `calc(${spacing.HeaderHeight}px + env(safe-area-inset-top))`,
			},
			pageContentNarrow: {
				[mediaQueries.AfterMobile]: {
					minHeight: '100%',
					margin: '0 auto',
				},
				[mediaQueries.Tablet]: {
					width: '70vw',
				},
				[mediaQueries.AfterTablet]: {
					width: '50vw',
				},
			},
		}),
	{
		name: 'PageLayout',
	}
);

export interface PageLayoutProps {
	fullWidth?: boolean;
	background?: 'theme' | 'default' | 'transparent';
	spaceForHeader?: boolean;
	scrollLockScrollable?: boolean;
	setScrollContainer?: (node: HTMLElement | null) => void;
	className?: string;
}

const PageLayout: FC<PageLayoutProps> = ({
	fullWidth,
	children,
	background,
	spaceForHeader = true,
	scrollLockScrollable = false,
	setScrollContainer,
	className,
}) => {
	const cls = useStyles();
	const theme = useTheme();
	useLayoutEffect(() => {
		document.documentElement.style.backgroundColor =
			background === 'theme' ? theme.palette.primary.main : 'black';
	}, [theme.palette.primary.main, background]);
	return (
		<div
			className={classnames(cls.page, className)}
			ref={setScrollContainer}
			data-background={background || 'theme'}
			data-scroll-lock-scrollable={scrollLockScrollable}
		>
			<div
				className={classnames(
					'PageLayout-pageContent',
					cls.pageContent,
					spaceForHeader && cls.spaceForHeader,
					fullWidth || cls.pageContentNarrow
				)}
			>
				{children}
			</div>
		</div>
	);
};

export default PageLayout;
