export default {
	HeaderHeight: 98,
	Gutter: {
		Mobile: 20,
		Tablet: 20,
		Laptop: 20,
		Desktop: 20,
		BigDesktop: 20,
	},
	WidthGeneral: 940,
	WidthMain: 900,
	WidthDiaporama: 920,
	InputMinHeight: 42,
	HeightDesktopFooter: 50,
	BoderRadius: {
		Small: 5,
		Big: 5,
		XL: 8,
	},
};
