import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import fontStyles from '../constants/fontStyles';

const useStyles = makeStyles(() =>
	createStyles({
		footer: {
			display: 'flex',

			[mediaQueries.Mobile]: {
				position: 'relative',
				flexDirection: 'column',
				backgroundColor: 'rgba(151,151,151, 0.85)',
				padding: spacing.Gutter.Mobile,
			},

			[mediaQueries.AfterMobile]: {
				height: spacing.HeightDesktopFooter,
				justifyContent: 'space-between',
				padding: '0 10px',

				backgroundColor: '#979797',
			},
		},
		container: {
			display: 'flex',

			[mediaQueries.Mobile]: {
				flexWrap: 'wrap',
				margin: `0 -${spacing.Gutter.Mobile / 2}px ${
					spacing.Gutter.Mobile
				}px -${spacing.Gutter.Mobile / 2}px`,
			},

			[mediaQueries.AfterMobile]: {
				alignItems: 'center',
			},
		},
		sponsorContainer: {
			[mediaQueries.Mobile]: {
				display: 'flex',
				flexWrap: 'wrap',
				alignItems: 'center',
			},
		},
		socialContainer: {
			[mediaQueries.Mobile]: {
				justifyContent: 'center',
			},
			[mediaQueries.AfterMobile]: {
				marginLeft: 'auto',
			},
		},
		sponsor: {
			[mediaQueries.Mobile]: {
				flex: '0 0 33%',
				padding: `0 ${spacing.Gutter.Mobile / 2}px`,
				marginBottom: 20,
				textAlign: 'center',
			},

			[mediaQueries.AfterMobile]: {
				height: spacing.HeightDesktopFooter / 2,
				margin: '0 10px',
			},
		},
		sponsorImage: {
			[mediaQueries.Mobile]: {
				maxWidth: '100%',
			},

			[mediaQueries.AfterMobile]: {
				height: '100%',
			},
		},
		socialLink: {
			[mediaQueries.Mobile]: {
				flex: '0 0 25%',
				// width: '100%/4',
				padding: `0 ${spacing.Gutter.Mobile / 2}`,
				fontSize: rem(25, bodyFontSize.Mobile),
				textAlign: 'center',
			},
			[mediaQueries.AfterMobile]: {
				margin: '0 5px',
				fontSize: 15,
				color: 'white',
			},
		},
		facebook: {
			color: 'white',
			'&:before': {
				...fontIcon.iconFooterFacebook,
			},
		},
		instagram: {
			color: 'white',
			'&:before': {
				...fontIcon.iconFooterInstagram,
			},
		},
		whatsApp: {
			color: 'white',
			'&:before': {
				...fontIcon.iconFooterWhatsApp,
			},
		},
		shopInfos: {
			[mediaQueries.Mobile]: {
				display: 'block',
				color: 'white',
				textAlign: 'center',
			},
			[mediaQueries.AfterMobile]: {
				display: 'none',
			},
		},
		title: {
			...fontStyles.defaultText,
			fontWeight: 'bold',
			marginBottom: spacing.Gutter.Mobile,
		},
		text: {
			...fontStyles.smallText,
			fontWeight: 500,
			width: '80%',
			margin: '0 auto',
		},
		secondaryPagesContainer: {
			[mediaQueries.Mobile]: {
				justifyContent: 'center',
			},
		},
		secondaryPageLink: {
			color: 'white',
			...fontStyles.smallText,

			[mediaQueries.Mobile]: {
				width: '100%',
				marginBottom: spacing.Gutter.Mobile / 2,
				textAlign: 'center',
			},
			[mediaQueries.AfterMobile]: {
				...(fontStyles.smallText as any)[mediaQueries.AfterMobile],
				margin: '0 5px',
			},
		},
	})
);

const Footer: FC = () => {
	const cls = useStyles();
	const { app } = useApp();
	const [config, setConfig] = useState<ConfigDto>();
	const [secondaryPages, setSecondaryPages] = useState<SecondaryPageDto[]>();

	useEffect(() => {
		if (!app) {
			return;
		}
		setConfig(app.Config);
		setSecondaryPages(app.SecondaryPages);
	}, [app]);

	return (
		<footer className={cls.footer}>
			{config && (
				<>
					<div className={classNames(cls.container, cls.sponsorContainer)}>
						{config.SponsorItems.filter(({ Image: img }) => img).map((s) => (
							<a
								className={cls.sponsor}
								href={s.Link}
								key={s.Image.Id}
								target='_blank'
								rel='noreferrer'
							>
								<img
									src={s.Image.Src}
									className={cls.sponsorImage}
									alt={s.Name}
								/>
							</a>
						))}
					</div>
					<div className={classNames(cls.container, cls.shopInfos)}>
						<h5 className={cls.title}>colourkey Shop</h5>
						<div
							className={cls.text}
							dangerouslySetInnerHTML={{ __html: config.ShopInfos }}
						/>
					</div>
					<div className={classNames(cls.container, cls.socialContainer)}>
						{config.Facebook && (
							// eslint-disable-next-line jsx-a11y/anchor-has-content
							<a
								aria-label='link to facebook'
								href={config.Facebook}
								target='_blank'
								rel='noreferrer'
								className={classNames(cls.socialLink, cls.facebook)}
							/>
						)}
						{config.Instagram && (
							// eslint-disable-next-line jsx-a11y/anchor-has-content
							<a
								aria-label='link to instagram'
								href={config.Instagram}
								target='_blank'
								rel='noreferrer'
								className={classNames(cls.socialLink, cls.instagram)}
							/>
						)}
						{config.WhatsApp && (
							// eslint-disable-next-line jsx-a11y/anchor-has-content
							<a
								aria-label='link to instagram'
								href={config.WhatsApp}
								className={classNames(cls.socialLink, cls.whatsApp)}
							/>
						)}
					</div>
					<div
						className={classNames(cls.container, cls.secondaryPagesContainer)}
					>
						<a
							href='https://confirmsubscription.com/h/i/B1C83CDC2A0E6AA6'
							target='_blank'
							rel='noreferrer'
							// data-listid='i/D9/8A3/8C5/197AF56A7ED561D4'
							className={cls.secondaryPageLink}
						>
							Newsletter
						</a>
						{secondaryPages &&
							secondaryPages.map((s) => (
								<Link
									to={`/${s.UID}`}
									key={s.UID}
									className={cls.secondaryPageLink}
								>
									{s.Title}
								</Link>
							))}
					</div>
				</>
			)}
		</footer>
	);
};

export default Footer;
