import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import fontStyles from '../constants/fontStyles';
import { bodyFontSize } from '../constants/stylesheet';
import { rem } from '../utils/Units';

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			...fontStyles.title,
			textAlign: 'center',
			marginBottom: rem(20, bodyFontSize.Mobile),
		},
	})
);

const PanelTitle: FC<{ title: string }> = ({ title }) => {
	const cls = useStyles();
	return <h4 className={cls.title}>{title}</h4>;
};

export default PanelTitle;
