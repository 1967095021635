import React, { useEffect, useCallback, FC } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useReactRouter from 'use-react-router';
import { useApi } from './@context/Api';
import PanelPage from './Pages/PanelPage';
import OfferPage from './Pages/OfferPage';

const useStyles = makeStyles(() =>
	createStyles({
		loadingContainer: {
			transition: 'opacity 0.2s',
			pointerEvents: 'none',
			top: '50vh',
			left: '50vw',
			position: 'fixed',
			transform: 'translate(-50%,-50%)',
		},
		loadingText: {
			// ...stylesheet.BoldText,
		},
	})
);

const Pages: FC = () => {
	const cls = useStyles();
	const {
		location: { pathname },
	} = useReactRouter();
	const { page: getPage } = useApi();
	const [page, setPage] = React.useState<IPageDto>();

	const [fetching, setFetching] = React.useState(false);

	// const scrollContainer = document.getElementById("scrollContainer");
	// React.useEffect(() => {
	//   if (!scrollContainer) {
	//     return;
	//   }

	//   if (scrollContainer.scrollTop > 0) {
	//     scrollContainer.scrollTop = 0;
	//   }
	// }, [page]);

	const fetchPage = useCallback(
		async (endpoint: string) => {
			setFetching(true);
			const pageDto = await getPage(endpoint);
			setPage(pageDto);
			setFetching(false);
		},
		[getPage]
	);

	useEffect(() => {
		fetchPage(pathname);
	}, [fetchPage, pathname]);

	if (fetching) return null;
	return (
		<>
			{page && page.type === 'PanelPage' && (
				<PanelPage page={page as PanelPageDto} />
			)}
			{page && page.type === 'SecondaryPage' && (
				<PanelPage page={page as SecondaryPageDto} />
			)}
			{page && page.type === 'Offer' && <OfferPage offer={page as OfferDto} />}
			{page && page.type === 'OfferAdminDto' && (
				<OfferPage offer={page as OfferAdminDto} />
			)}
		</>
	);
};

export default Pages;
