import { makeStyles } from '@material-ui/core/styles';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import mediaQueries from '../constants/mediaQueries';

export default makeStyles(
	(theme) => ({
		root: {
			marginBottom: spacing.Gutter.Desktop,
		},
		title: {
			width: '100%',
			textAlign: 'center',
			marginBottom: 10,
			'&:first-child': {
				marginTop: spacing.Gutter.Desktop,
			},
		},
		filtersSection: {
			[mediaQueries.AfterMobile]: {
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				margin: '0 auto',
			},
			gap: spacing.Gutter.Mobile,
		},
		dropdownContent: {
			[mediaQueries.Mobile]: {
				flex: '0 0 100%',
			},
			[mediaQueries.AfterMobile]: {
				flex: '0 0 50%',
			},
		},

		// offersBtn: {
		// 	border: '1px solid black',
		// 	color: theme.palette.text.primary,

		// 	'&:before': {
		// 		color: theme.palette.text.primary,
		// 		display: 'inline-block',
		// 		marginRight: 5,
		// 	},

		// 	[mediaQueries.Mobile]: {
		// 		fontSize: '2rem',
		// 		textAlign: 'center',
		// 		padding: `${spacing.Gutter.Mobile / 2}px ${spacing.Gutter.Mobile}px`,
		// 		margin: `0 ${spacing.Gutter.Mobile / 2}px`,
		// 		display: 'block',
		// 		backgroundColor: 'black',
		// 		borderRadius: spacing.BoderRadius.Small,

		// 		'&[data-active="false"]': {
		// 			opacity: 0.5,
		// 			cursor: 'default',
		// 		},
		// 	},
		// 	[mediaQueries.AfterMobile]: {
		// 		minWidth: 250,
		// 		fontSize: rem(24, bodyFontSize.Desktop),
		// 		padding: `${spacing.Gutter.Desktop / 2}px ${spacing.Gutter.Desktop}px`,
		// 		backgroundColor: 'black',
		// 		margin: `${spacing.Gutter.Desktop / 2}px ${
		// 			spacing.Gutter.Desktop / 2
		// 		}px`,
		// 		borderRadius: spacing.BoderRadius.Big,
		// 	},
		// },
		btnWrapper: {
			textAlign: 'center',
			marginTop: spacing.Gutter.Mobile,
		},
		btn: {
			border: '1px solid black',
			color: theme.palette.text.primary,
			backgroundColor: 'black',
			display: 'inline-block',
			borderRadius: spacing.BoderRadius.Big,

			[mediaQueries.Mobile]: {
				fontSize: '1.2rem',
				textAlign: 'center',
				padding: `${spacing.Gutter.Mobile / 2}px ${spacing.Gutter.Mobile}px`,
			},

			[mediaQueries.AfterMobile]: {
				// width: 250,
				margin: '0 auto',
				fontSize: rem(18, bodyFontSize.Desktop),
				padding: `${spacing.Gutter.Desktop / 2}px ${spacing.Gutter.Desktop}px`,

				'&:hover': {
					color: 'black',
					backgroundColor: theme.palette.text.primary,
				},
			},
		},
	}),
	{ name: 'AdminFilters' }
);
