import * as React from 'react';
import { useContext, useEffect, useState, createContext, FC } from 'react';
import { useApi } from './Api';

type TAnnouncementsContext = {
	announcements: AdvertisementDto[];
};
export const AnnouncementsContext = createContext<TAnnouncementsContext>(
	{} as never
);
export const Announcements: FC = ({ children }) => {
	const { get } = useApi();
	const [announcements, setAnnouncements] = useState<AdvertisementDto[]>([]);

	useEffect(() => {
		(async () => {
			const { data, error } = await get<AdvertisementDto[]>(
				`/2.0/offer/announcements`
			);

			if (error || !data) throw new Error(error?.Message);
			setAnnouncements(data);
		})();
	}, [get]);

	const value = {
		announcements,
	};
	return (
		<AnnouncementsContext.Provider value={value}>
			{children}
		</AnnouncementsContext.Provider>
	);
};

export const useAnnouncements = (): TAnnouncementsContext =>
	useContext(AnnouncementsContext);
