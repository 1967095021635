/* eslint-disable no-console */
/*
 * src/@context/PushNotificationsContext.tsx
 *
 * This context provider takes care of handling push notifications on the mobile
 * platforms.
 *
 */
import React, { FC, useEffect, useRef } from 'react';
import type { ActionPerformed } from '@capacitor/push-notifications';
import { useHistory } from 'react-router-dom';
import { usePlatformMobile } from './PlatformMobileContext';
import { useApi } from './Api';
import { useUsers } from './Users';

export type PushNotifications = null;

export const PushNotificationsContext =
	React.createContext<PushNotifications>(null);

type PushNotificationsProviderProps = {
	children: React.ReactNode;
};

export const PushNotificationsProvider: FC<PushNotificationsProviderProps> = ({
	children,
}) => {
	const {
		isActive,
		platform,
		pushNotificationToken,
		onPushNotificationActionPerformed,
	} = usePlatformMobile();
	const { put } = useApi();
	const { current } = useUsers();
	const history = useHistory();

	const putRef = useRef<typeof put>(put);
	putRef.current = put;

	useEffect(() => {
		if (!isActive) {
			return;
		}

		const unsubscribe = onPushNotificationActionPerformed(
			({ notification }: ActionPerformed) => {
				console.log('Notification clicked:', notification);

				if (
					'actionClick' in notification.data &&
					!!notification.data.actionClick
				) {
					console.log(
						'Navigating via notification:',
						notification.data.actionClick
					);
					history.push(notification.data.actionClick);
					setTimeout(() => {
						// Refresh the page
						// eslint-disable-next-line no-self-assign
						window.location.href = window.location.href;
					});
				}
			}
		);

		// eslint-disable-next-line consistent-return
		return () => {
			unsubscribe();
		};
	}, [isActive, onPushNotificationActionPerformed, history]);

	useEffect(() => {
		if (!isActive) {
			return;
		}

		const apiPut = putRef.current;

		if (pushNotificationToken) {
			console.log('We have a notification token!', pushNotificationToken);
			console.log('Current user:', current);

			apiPut('/2.0/push-notification/register', {
				RegistrationToken: pushNotificationToken,
				Device: platform,
			})
				.then(() => {
					console.log('Successfully registered push notification token');
				})
				.catch((err) => {
					console.error('Error registering push notification token:');
					console.error(err);
				});
		}
	}, [current, isActive, platform, pushNotificationToken]);

	return (
		<PushNotificationsContext.Provider value={null}>
			{children}
		</PushNotificationsContext.Provider>
	);
};
