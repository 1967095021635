import shuffle from 'lodash.shuffle';
import * as React from 'react';
import {
	useContext,
	useState,
	createContext,
	useEffect,
	useCallback,
	useRef,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAnnouncements } from './Announcements';
import { useApi } from './Api';
import { useApp } from './App';
import { useUsers } from './Users';

type OffersFilters = Partial<
	SearchQueryBinding & {
		count: number;
		start: number;
	}
>;
type OffersSort = Partial<{
	Order?: OfferSortOrder;
	Criteria?: OfferSortCriteria;
}>;

type Offers = {
	loading: boolean;
	hasMore: boolean;
	current?: OfferDto;
	filters: OffersFilters;
	setFilters: (filters: OffersFilters) => void;
	setShow: (show: OffersShow[]) => void;
	show: OffersShow[];
	sort: OffersSort;
	setSort: (sort: OffersSort) => void;
	count: number | undefined;
	offers: (OfferDto | OfferEditDto | OfferAdminDto)[];
	load: () => void;
	post: (binding: HTMLFormElement) => Promise<ApiResponse<OfferAdminDto>>;
	postFormData: (binding: FormData) => Promise<ApiResponse<OfferAdminDto>>;
	put: (
		objectId: string,
		binding: HTMLFormElement
	) => Promise<ApiResponse<OfferAdminDto>>;
	putFormData: (
		objectId: string,
		binding: FormData
	) => Promise<ApiResponse<OfferAdminDto>>;
	get: (objectId: string) => Promise<OfferAdminDto>;
	saveSort: () => Promise<void>;
	likeOffer: (objectId: string) => Promise<ApiResponse<OfferDto>>;
	unlikeOffer: (objectId: string) => Promise<ApiResponse<OfferDto>>;
};

const DEFAULT_FILTERS: OffersFilters = {
	Gratis: false,
	Plus18: false,
	Special: false,
	Sustainability: false,
	Likes: false,
	Except: [],
	count: 50,
	start: 0,
};

let requestCounter = 0;
export const OffersContext = createContext<Offers>({} as never);

const makeQueryString = (filters: OffersFilters) => {
	const modified = (
		Object.entries(filters) as Array<
			[
				keyof SearchQueryBinding,
				boolean | SearchQueryBinding[keyof SearchQueryBinding]
			]
		>
	).filter(
		([k]) => JSON.stringify(filters[k]) !== JSON.stringify(DEFAULT_FILTERS[k])
	);

	const query = modified
		.map(([k, v]) =>
			Array.isArray(v)
				? (v as Array<string | number>)
						.filter((v) => !!v)
						.map(($v) => `${k}[]=${encodeURIComponent($v)}`)
						.join('&')
				: `${k}=${encodeURIComponent(v === undefined ? '' : v)}`
		)
		.filter((v) => !!v)
		.sort();

	return query.join('&');
};

const parseQueryString = (queryString = ''): OffersFilters => {
	const normalized = queryString.replace(/^\?/, '');
	const pairs = normalized ? normalized.split('&') : [];

	return pairs.reduce((acc, c) => {
		const pair = c.split('=');
		const isArray = pair[0].includes('[]');
		const key = decodeURIComponent(
			pair[0].replace('[]', '')
		) as keyof OffersFilters;
		const val = decodeURIComponent(pair[1] || 'true');
		const value = isArray ? [val] : val;
		const previous = acc[key];
		if (previous) {
			return {
				...acc,
				[key]: isArray ? [...(previous as []), ...value] : [previous, ...value],
			};
		}
		return {
			...acc,
			[key]: value,
		};
	}, DEFAULT_FILTERS);
};

const perPage = 50;

export const Offers: React.FC = ({ children }) => {
	const history = useHistory();
	const location = useLocation();

	const { app } = useApp();
	const { get, form, formFormData, put: putApi, patch } = useApi();
	const { admin, current: currentUser } = useUsers();
	const { announcements } = useAnnouncements();

	const initFilters = parseQueryString(location.search);
	const [loading, setLoading] = useState(false);
	// const [current] = useState<OfferDto>();
	const [offers, setOffers] = useState<OfferDto[]>([]);
	const [count, setCount] = useState<undefined | number>();
	const randomPages = useRef<number[]>([]);
	const [hasMore, setHasMore] = useState(true);
	const [filters, setFilters] = useState<OffersFilters>(initFilters);
	const [sort, setSort] = useState<OffersSort>({});
	const [show, setShow] = useState<OffersShow[]>([]);
	const requestID = useRef({ count: 0, load: 0 });

	const getQueryString = useCallback(
		(
			$admin: boolean,
			$filters: OffersFilters,
			$suffix: string,
			$start = 0,
			$criteria: OfferSortCriteria | undefined,
			$order: OfferSortOrder | undefined
		) => {
			const curentFilters = {
				...DEFAULT_FILTERS,
				...$filters,
				start: $start,
				count: perPage,
			};

			const adminQuery = `${$criteria ? `&criteria=${$criteria}` : ''}${
				$order ? `&order=${$order}` : ''
			}`;
			const query = makeQueryString(curentFilters) + ($admin ? adminQuery : '');
			const endpoint = `/2.0${$admin ? '/admin' : ''}/offers${$suffix}`;
			return `${endpoint}?${query}`;
		},
		[]
	);

	const getRandomStart = useCallback(($start) => {
		const page = Math.floor($start / perPage);
		return randomPages.current[page] * perPage;
	}, []);

	const loadOffers = useCallback(
		async (
			$admin: boolean,
			$filters: OffersFilters,
			$start: number,
			reqId: number,
			$criteria: OfferSortCriteria | undefined = app?.Config?.OfferSortCriteria,
			$order: OfferSortOrder | undefined = app?.Config?.OfferSortOrder
		) => {
			const random = $order === 'Random';

			const endpoint = getQueryString(
				$admin,
				$filters,
				'',
				random ? getRandomStart($start) : $start,
				$criteria,
				$order
			);

			const { data: $offers, error } = await get<OfferDto[]>(endpoint);
			if (error || reqId !== requestID.current.load) {
				return undefined;
			}
			return random ? shuffle($offers) : $offers;
		},
		[
			app?.Config?.OfferSortCriteria,
			app?.Config?.OfferSortOrder,
			getQueryString,
			getRandomStart,
			get,
		]
	);

	const countOffers = useCallback(
		async (
			$admin: boolean,
			$filters: OffersFilters,
			reqId: number,
			$criteria?: OfferSortCriteria,
			$order?: OfferSortOrder
		) => {
			const endpoint = getQueryString(
				$admin,
				$filters,
				'/count',
				0,
				$criteria,
				$order
			);

			const { data: $count, error } = await get<{ Count: number }>(endpoint);
			if (error || reqId !== requestID.current.count) {
				return undefined;
			}

			return $count?.Count;
		},
		[get, getQueryString]
	);

	const startLoadRequest = useCallback(() => {
		requestCounter += 1;
		requestID.current.load = requestCounter;
		setLoading(true);
		return requestID.current.load;
	}, []);

	const startCountRequest = useCallback(() => {
		requestCounter += 1;
		requestID.current.count = requestCounter;
		setLoading(true);
		return requestID.current.count;
	}, []);

	const endLoadRequest = useCallback((reqId: number) => {
		if (reqId === requestID.current.load) requestID.current.load = 0;
		setLoading(requestID.current.load !== 0 || requestID.current.count !== 0);
	}, []);

	const endCountRequest = useCallback((reqId: number) => {
		if (reqId === requestID.current.count) requestID.current.count = 0;
		setLoading(requestID.current.load !== 0 || requestID.current.count !== 0);
	}, []);

	const addAds = useCallback(
		($offers: OfferDto[], $start: number, $count = 50) => {
			if (announcements && announcements.length <= 0) {
				return $offers;
			}

			const matchAds = announcements.filter(
				(a) => a.Positon >= $start && a.Positon < $count
			);

			if (matchAds.length <= 0) {
				return $offers;
			}

			matchAds.forEach((ad) => {
				$offers.splice(ad.Positon, 0, ad as unknown as OfferDto);
			});

			return $offers;
		},
		[announcements]
	);

	const load = useCallback(
		async ($count?: number, previous: OfferDto[] = []) => {
			const countReqId = startCountRequest();
			let allCount = $count;
			if (allCount === undefined) {
				allCount = await countOffers(
					admin,
					filters,
					countReqId,
					sort.Criteria,
					sort.Order
				);

				setCount(allCount);
				randomPages.current = shuffle(
					new Array(Math.ceil((allCount || 0) / perPage))
						.fill(0)
						.map((_, i) => i)
				);
				if (!allCount) {
					endCountRequest(countReqId);
					return;
				}
			}
			endCountRequest(countReqId);
			const loadReqId = startLoadRequest();

			const $offers = await loadOffers(
				admin,
				filters,
				previous.length,
				loadReqId,
				sort.Criteria,
				sort.Order
			);

			if (!$offers || !$offers.length) {
				endLoadRequest(loadReqId);
				setHasMore(false);
				return;
			}

			const all = [...previous, ...$offers];

			const allWithAds = addAds(all, previous.length, $count);

			setOffers(allWithAds);
			setHasMore(!!all && all.length < allCount);
			endLoadRequest(loadReqId);
		},
		[
			addAds,
			admin,
			countOffers,
			endCountRequest,
			endLoadRequest,
			filters,
			loadOffers,
			sort.Criteria,
			sort.Order,
			startCountRequest,
			startLoadRequest,
		]
	);

	const updateQueryString = useCallback(($filters, $history, $location) => {
		const query = makeQueryString($filters);
		const search = $location.search.replace('?', '');
		if (search === query) return;
		$history.push({
			...$location,
			search: `?${query}`,
		});
	}, []);

	useEffect(() => {
		(async () => {
			// if (!announcements || announcements.length <= 0) {
			// 	return;
			// }

			setOffers([]);
			await load();
		})();
	}, [filters, sort, admin, announcements, load]);

	useEffect(() => {
		(async () => {
			updateQueryString(filters, history, location);
		})();
	}, [filters, history, location, updateQueryString]);

	const loadMore = useCallback(() => {
		(async () => load(count, offers))();
	}, [count, load, offers]);

	const post = useCallback(
		async (binding: HTMLFormElement) => {
			const { data, error } = await form<OfferAdminDto[]>(
				`/2.0/offers`,
				binding,
				'POST'
			);
			const offer = data && data[0];
			if (!error && offer && admin) {
				setOffers([offer, ...offers]);
			}
			return { error, data: offer };
		},
		[form, offers, admin]
	);

	const postFormData = useCallback(
		async (binding: FormData) => {
			const { data, error } = await formFormData<OfferAdminDto[]>(
				`/2.0/offers`,
				binding,
				'POST'
			);
			const offer = data && data[0];
			if (!error && offer && admin) {
				setOffers([offer, ...offers]);
			}
			return { error, data: offer };
		},
		[form, offers, admin]
	);

	const put = useCallback(
		async (objectId: string, binding: HTMLFormElement) => {
			const { data, error } = await form<OfferAdminDto[]>(
				`/2.0/offers/${objectId}`,
				binding,
				'PUT'
			);

			const offer = data && data[0];
			if (!error && offer) {
				setOffers(
					(prevState) =>
						prevState.map((v) => (v.ObjectId === offer.ObjectId ? offer : v))
					// offers.map((v) => (v.ObjectId === offer.ObjectId ? offer : v))
				);
			}
			return { error, data: offer };
		},
		[form]
	);

	const putFormData = useCallback(
		async (objectId: string, binding: FormData) => {
			const { data, error } = await formFormData<OfferAdminDto[]>(
				`/2.0/offers/${objectId}`,
				binding,
				'PUT'
			);

			const offer = data && data[0];
			if (!error && offer) {
				setOffers(
					(prevState) =>
						prevState.map((v) => (v.ObjectId === offer.ObjectId ? offer : v))
					// offers.map((v) => (v.ObjectId === offer.ObjectId ? offer : v))
				);
			}
			return { error, data: offer };
		},
		[formFormData]
	);

	const saveSort = useCallback(async () => {
		await putApi('/2.0/admin/sort', sort);
	}, [putApi, sort]);

	const likeOffer = useCallback(
		async (objectId: string) => {
			const { data, error } = await patch<OfferDto>(
				`/2.0/offers/${objectId}/like`,
				{}
			);

			if (!error && data) {
				setOffers((prevState) =>
					prevState.map((v) => (v.ObjectId === data.ObjectId ? data : v))
				);
			}
			return { error, data };
		},
		[patch]
	);

	const unlikeOffer = useCallback(
		async (objectId: string) => {
			const { data, error } = await patch<OfferDto>(
				`/2.0/offers/${objectId}/unlike`,
				{}
			);

			if (!error && data) {
				setOffers((prevState) =>
					prevState.map((v) => (v.ObjectId === data.ObjectId ? data : v))
				);
			}
			return { error, data };
		},
		[patch]
	);

	const getOffer = useCallback(
		async (objectId: string) => {
			const { data, error } = await get<OfferAdminDto>(
				`/2.0/offers/${objectId}`
			);
			if (error || !data) throw new Error(error?.Message);
			return data;
		},
		[get]
	);

	const today = new Date();
	today.setHours(0, 0, 0, 0);
	const finalOffers = offers.filter(
		(o) =>
			show.every((filter) => {
				switch (filter) {
					case 'Online':
						return o.IsOnline;
					case 'Offline':
						return !o.IsOnline;
					case 'Not Expired':
						return (
							!o.EndDate || new Date(o.EndDate).getTime() >= today.getTime()
						);
					case 'Expired':
						return (
							!o.EndDate || new Date(o.EndDate).getTime() < today.getTime()
						);
					default:
						return true;
				}
			}) ?? true
	);
	return (
		<OffersContext.Provider
			value={{
				loading,
				// current,
				offers: finalOffers,
				filters,
				setFilters,
				sort,
				show,
				setSort,
				setShow,
				hasMore,
				load: loadMore,
				count,
				post,
				postFormData,
				put,
				putFormData,
				get: getOffer,
				saveSort,
				likeOffer,
				unlikeOffer,
			}}
		>
			{children}
		</OffersContext.Provider>
	);
};

export const useOffers = (): Offers => useContext(OffersContext);
