import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState } from 'react';
import { useUsers } from 'src/@context/Users';
import mediaQueries from 'src/constants/mediaQueries';
import { useHistory } from 'react-router-dom';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import Logo from 'src/components/Logo';
import LoadingScreen from 'src/components/LoadingScreen';
import LigthEffect from 'src/components/LightEffect';
import SubscriptionExpiredScreen from 'src/components/SubscriptionExpiredScreen';
import PageLayout from 'src/components/PageLayout';
import ProfileMap from '../components/ProfilMap';
import ParseDate from '../utils/ParseDate';
import Header from '../components/Header';
import useMockUser from '../utils/useMockUser';
import fontStyles from '../constants/fontStyles';
import Button from '../components/Button';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			page: {
				height: '100%',
			},
			pageContent: {
				position: 'relative',
				color: theme.palette.primary.main,

				[mediaQueries.Mobile]: {
					position: 'relative',
					width: '100%',
					height: '100%',
				},
				[mediaQueries.AfterMobile]: {
					height: '100%',
					margin: '0 auto',
				},
				[mediaQueries.Tablet]: {
					width: '70vw',
				},
				[mediaQueries.AfterTablet]: {
					width: '50vw',
				},
			},
			profileHeader: {
				position: 'relative',
				height: '100%',
			},
			profileLogo: {
				[mediaQueries.Mobile]: {
					position: 'absolute',
					top: 30,
					zIndex: 2,
					width: '~"calc(100vw + 20px)',
					left: '-10px',
					right: '-10px',
					display: 'block',
				},
				[mediaQueries.Tablet]: {
					position: 'absolute',
					top: spacing.Gutter.Tablet,
					width: '70%',
					left: '50%',
					marginLeft: '-35%',
					display: 'block',
					zIndex: 2,
				},
				[mediaQueries.AfterMobile]: {
					position: 'absolute',
					top: spacing.Gutter.Desktop,
					width: '50%',
					left: '50%',
					marginLeft: '-25%',
					display: 'block',
					zIndex: 2,
				},
			},
			profileHeaderContent: {
				[mediaQueries.Mobile]: {
					position: 'relative',
					top: 110,
					zIndex: 1,
				},
				[mediaQueries.AfterMobile]: {
					position: 'relative',
					top: 90,
				},
			},
			profileFoto: {
				borderRadius: '100%',
				position: 'relative',
				overflow: 'hidden',
				margin: `0 auto ${spacing.Gutter.Mobile * 2}px auto`,
				zIndex: 1,

				'&:before': {
					content: '""',
					paddingBottom: '100%',
					display: 'block',
				},

				'& img': {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					objectFit: 'cover',
				},

				[mediaQueries.Mobile]: {
					width: '60%',
				},
				[mediaQueries.Tablet]: {
					width: '50%',
				},
				[mediaQueries.AfterTablet]: {
					width: '35%',
				},
			},
			profileInfo: {
				textAlign: 'center',
				padding: `0 ${spacing.Gutter.Mobile}px`,
			},
			profileName: {
				textAlign: 'center',
				[mediaQueries.AfterMobile]: {
					fontSize: rem(26, bodyFontSize.Mobile),
				},

				[mediaQueries.AfterMobile]: {
					fontSize: rem(30, bodyFontSize.Mobile),
				},
			},
			profileUsername: {
				textAlign: 'center',
				marginTop: 10,
				...fontStyles.largeText,
			},
			profileBirthdate: {
				textAlign: 'center',
				marginBottom: spacing.Gutter.Mobile,

				[mediaQueries.AfterMobile]: {
					fontSize: rem(26, bodyFontSize.Mobile),
					marginBottom: 12,
				},
			},
			profileExpirerWarnning: {
				padding: '10px 16px',
				borderRadius: spacing.BoderRadius.Small,
				...fontStyles.defaultText,
				zIndex: 2,
				textAlign: 'center',
				display: 'inline-block',

				backgroundColor: theme.palette.primary.main,
				color: 'black',
			},
			profileContent: {
				position: 'relative',

				[mediaQueries.Mobile]: {
					padding: `0 ${spacing.Gutter.Mobile}px ${
						spacing.Gutter.Mobile * 2
					}px ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `0 ${spacing.Gutter.Mobile * 2}px ${
						spacing.Gutter.Mobile * 2
					}px ${spacing.Gutter.Mobile * 2}px`,
					overflowX: 'hidden',
				},
			},
			mapText: {
				textAlign: 'center',
				fontSize: rem(17, bodyFontSize.Mobile),
				marginBottom: spacing.Gutter.Mobile / 2,
			},
			mapWrapper: {
				position: 'relative',
			},
			map: {
				height: '60vh',
				marginBottom: spacing.Gutter.Mobile / 2,
				zIndex: 1,
			},
			btn: {
				display: 'block',
				fontSize: rem(24, bodyFontSize.Mobile),
				backgroundColor: theme.palette.primary.main,
				color: 'black',
				borderRadius: spacing.BoderRadius.Small,
				padding: 10,
				textAlign: 'center',
				marginBottom: spacing.Gutter.Mobile / 2,

				'&[data-sign-out="true"]': {
					width: '100%',
					marginTop: spacing.Gutter.Mobile * 3,
				},
			},
		}),
	{ name: 'Profile' }
);

const ProfilePage: FC = () => {
	const cls = useStyles();
	const history = useHistory();
	const { current: _current, signOut, loading } = useUsers();
	const current = useMockUser({ actualUser: _current, mockEnabled: false });
	const [showLightEffect, setShowlightEffect] = useState(false);
	const [showSubscriptionExpired, setShowSubscriptionExpired] = useState(false);
	const [userBlocked, setUserBlocked] = useState(false);

	useEffect(() => {
		if (!current) {
			return;
		}
		setUserBlocked(current.Validation === 'Unvalid'); // ProfileValidation.Unvalid
		const timeLeft = current.TimeLeft;
		if (timeLeft <= 0) {
			setShowSubscriptionExpired(true);
			return;
		}

		setShowlightEffect(true);
	}, [current, history]);

	useEffect(() => {
		if (!current && !loading) {
			history.push('/');
		}
	}, [current, history, loading]);

	if (!current && loading) {
		return <LoadingScreen />;
	}

	return (
		<>
			<PageLayout background='default'>
				<Header variant='themeTransparent' />
				{current && (
					<article className={cls.page}>
						<div className={cls.pageContent}>
							<div className={cls.profileHeader}>
								<div className={cls.profileLogo}>
									<Logo />
								</div>
								<div className={cls.profileHeaderContent}>
									<figure className={cls.profileFoto}>
										<img
											src={`${
												process.env.REACT_APP_AUTH_URL
											}/2.0/account/avatar?id=${
												current.UserName
											}&${performance.now()}`}
											alt=''
										/>
									</figure>
									<div className={cls.profileInfo}>
										<div className={cls.profileName}>
											{`${current.FirstName} ${current.LastName}`}
										</div>
										<div className={cls.profileUsername}>
											{`Colourkey ID: ${current.UserName.trim()}`}
										</div>

										<div className={cls.profileUsername}>
											{/* {current.Birth && ParseDate(current.Birth)} */}
										</div>

										{current.SubscriptionEnd && (
											<div className={cls.profileExpirerWarnning}>
												{`Gültig bis: ${ParseDate(current.SubscriptionEnd)}`}
											</div>
										)}
									</div>
								</div>
								<button type='button' aria-label='scroll to content' />
								{showLightEffect && <LigthEffect />}
							</div>
							<div className={cls.profileContent}>
								<p className={cls.mapText}>Angebote in deiner Nähe</p>
								<div className={cls.mapWrapper}>
									<div className={cls.map}>
										<ProfileMap />
									</div>
								</div>
								<Button
									variant='formSubmitButtonInverted'
									as='link'
									to='/edit-profile'
									className={cls.btn}
								>
									Profil anpassen
								</Button>
								<Button
									variant='formSubmitButtonInverted'
									as='link'
									to='/faq'
									className={cls.btn}
								>
									Hilfe
								</Button>
								<Button
									variant='formSubmitButtonInverted'
									type='button'
									className={cls.btn}
									data-sign-out
									onClick={() => signOut()}
								>
									Sign Out
								</Button>
							</div>
						</div>
					</article>
				)}
			</PageLayout>
			{showSubscriptionExpired && current && (
				<SubscriptionExpiredScreen user={current} userBlocked={userBlocked} />
			)}
		</>
	);
};

export default ProfilePage;
