import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type SelectOption = {
	legend: string;
	value: string;
};

type InputSelectProps = {
	register: any;
	label?: string;
	error: FieldError | undefined;
};

const kantoOptions: SelectOption[] = [
	{ legend: 'Bitte wählen', value: '' },
	{ legend: 'AG', value: 'AG' },
	{ legend: 'AR', value: 'AR' },
	{ legend: 'AI', value: 'AI' },
	{ legend: 'BL', value: 'BL' },
	{ legend: 'BS', value: 'BS' },
	{ legend: 'BE', value: 'BE' },
	{ legend: 'FR', value: 'FR' },
	{ legend: 'GE', value: 'GE' },
	{ legend: 'GL', value: 'GL' },
	{ legend: 'GR', value: 'GR' },
	{ legend: 'JU', value: 'JU' },
	{ legend: 'LU', value: 'LU' },
	{ legend: 'NE', value: 'NE' },
	{ legend: 'NW', value: 'NW' },
	{ legend: 'OW', value: 'OW' },
	{ legend: 'SG', value: 'SG' },
	{ legend: 'SH', value: 'SH' },
	{ legend: 'SZ', value: 'SZ' },
	{ legend: 'SO', value: 'SO' },
	{ legend: 'TI', value: 'TI' },
	{ legend: 'TG', value: 'TG' },
	{ legend: 'UR', value: 'UR' },
	{ legend: 'VD', value: 'VD' },
	{ legend: 'VS', value: 'VS' },
	{ legend: 'ZH', value: 'ZH' },
	{ legend: 'ZG', value: 'ZG' },
	{ legend: 'ausserhalb der Schweiz', value: 'EU' },
];

const InputKantonDropdown: FC<InputSelectProps> = ({
	register,
	label,
	error,
	children,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<select className={formCls.inputSelect} {...register} {...rest}>
				{kantoOptions.map((o) => (
					<option value={o.value} key={o.value}>
						{o.legend}
					</option>
				))}
			</select>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputKantonDropdown;
