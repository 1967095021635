import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import spacing from 'src/constants/spacing';
import fontIcon from 'src/constants/fontIcon';
import PageLayout from 'src/components/PageLayout';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import mediaQueries from 'src/constants/mediaQueries';
import { useApp } from 'src/@context/App';
import Header from './Header';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 10,
				display: 'flex',
				flexDirection: 'column',
			},
			header: {
				flex: '0 0 auto',
			},
			closeBtn: {
				position: 'absolute',
				top: spacing.Gutter.Mobile,
				lineHeight: 1,
				fontSize: rem(35, bodyFontSize.Mobile),
				right: 0,

				'&:before': {
					...fontIcon.iconClose,
				},
			},
			content: {
				flex: '1 1 auto',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',

				[mediaQueries.Mobile]: {
					padding: `0 ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `0 ${spacing.Gutter.Desktop * 2}px`,
				},
			},
			messageContainer: {
				textAlign: 'center',
			},
			icon: {
				display: 'block',
				fontSize: '5em',
				// lineHeight: 1,
				marginBottom: 10,

				'[data-icon="error"]&:before': {
					...fontIcon.iconError,
				},
				'[data-icon="success"]&:before': {
					...fontIcon.iconSuccess,
				},

				// '&.icon-heart': {
				//     fontSize: '15em',
				//     marginBottom: 20,
				// },

				// '&.icon-sleeping-monkey': {
				//     fontSize: '9em',
				// }
			},
			message: {
				fontSize: rem(24, bodyFontSize.Mobile),
			},
			btns: {
				display: 'block',
				padding: spacing.Gutter.Desktop,
				textAlign: 'center',
				fontSize: rem(24, bodyFontSize.Mobile),
				border: 'none',
				borderRadius: spacing.BoderRadius.Big,
				marginBottom: spacing.Gutter.Desktop,
				backgroundColor: 'black',
				color: theme.palette.primary.main,
			},
		}),
	{
		name: 'Message-Screen',
	}
);

// <MessageProps>
const Message: FC = () => {
	const cls = useStyles();
	const [message, setMessage] = useState<MessageProps>();
	const { messageScreen, setMessageScreen } = useApp();

	useEffect(() => {
		if (!messageScreen) {
			setMessage(undefined);
		} else {
			setMessage(messageScreen);
		}
	}, [messageScreen]);

	if (!message) {
		return null;
	}
	const { type, icon, message: text } = message;
	return (
		<div className={cls.root} data-type={type.toLowerCase()}>
			<PageLayout>
				<div className={cls.header}>
					<Header />
				</div>
				<div className={cls.content}>
					<div className={cls.messageContainer}>
						<div className={cls.icon} data-icon={icon || type.toLowerCase()} />
						<div className={cls.message}>
							{text || 'We have to pass the error message'}
						</div>
					</div>
					{type === 'Error' && (
						<button
							type='button'
							onClick={() => setMessageScreen(undefined)}
							className={cls.btns}
						>
							Nochmals versuchen
						</button>
					)}
				</div>
			</PageLayout>
		</div>
	);
};

export default Message;
