import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useUsers } from 'src/@context/Users';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import fontStyles from '../constants/fontStyles';
import Button from '../components/Button';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
			},
			sectionTitle: {
				position: 'relative',
				textAlign: 'center',
				marginBottom: 5,
			},
			content: {
				flex: '1 1 auto',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',

				[mediaQueries.Mobile]: {
					padding: `0 ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `0 ${spacing.Gutter.Desktop * 2}px`,
				},
			},
			messageContainer: {
				textAlign: 'center',
			},
			icon: {
				display: 'block',
				fontSize: '5em',
				marginBottom: 10,
				'&:before': { ...fontIcon.iconSleepingMonkey },
			},
			message: {
				...fontStyles.largeText,
			},
		}),
	{ name: 'PaymentPendingPage' }
);

const PaymentPending: FC = () => {
	const cls = useStyles();
	const { current } = useUsers();

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<div className={cls.content}>
					<div className={cls.messageContainer}>
						<div className={cls.icon} />
						<div className={cls.message}>
							Danke! Wir haben dir ein E-Mail mit den Zahlungsinformationen
							geschickt.
							<br />
							Sobald deine Zahlung ankommt, wird dein Konto freigeschaltet und
							du erhältst eine Nachricht per E-Mail.
							<br />
							Falls du das Angebot sofort nutzen möchtest, kannst du auch per
							PayPal, PostFinance, Kreditkarte oder TWINT bezahlen.
						</div>
					</div>
					{current && (
						<Button as='link' variant='formSubmitButton' to='/profil'>
							Profil anzeigen
						</Button>
					)}
				</div>
			</div>
		</PageLayout>
	);
};

export default PaymentPending;
