/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import Image from './Image';
import useStyles from './AdvertisementCard.styles';

const AdvertisementCard: FC<AdvertisementDto> = ({ children, ...card }) => {
	const { Link: url, ImageCard, FileToLink } = card;
	const cls = useStyles();

	const isExternal = url.startsWith('http://') || url.startsWith('https://');
	let link: ReactNode;

	if (!FileToLink) {
		if (isExternal) {
			link = (
				<a href={url} className={cls.link} target='_blank' rel='noreferrer' />
			);
		} else {
			link = <Link to={url} className={cls.link} />;
		}
	} else {
		link = (
			<a
				href={FileToLink.Src}
				className={cls.link}
				target='_blank'
				rel='noreferrer'
			/>
		);
	}

	return (
		<li className={cls.cardItem}>
			<figure className={cls.cardItemFigure}>
				{/.gif$/.test(ImageCard.Src) ? (
					<img
						src={ImageCard.Src}
						alt={ImageCard.Legend}
						className={cls.cardItemImage}
					/>
				) : (
					<Image
						pictureRef={ImageCard}
						className={cls.cardItemImage}
						sizes='(max-width: 767px) 100vw, 70vw'
					/>
				)}
			</figure>

			{link}

			{children}
		</li>
	);
};
export default React.memo(AdvertisementCard);
