import React, { FC, useCallback, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import usePlacesAutocomplete from '../../utils/usePlacesAutocomplete';
import FormStyles from './FormStyles';

type InputAddressAutocompleteProps = {
	register: any;
	label?: string;
	readonly?: boolean;
	error: FieldError | undefined;
	id?: string;
	onSelectAddress: (place: google.maps.places.PlaceResult) => void;
};

const InputAddressAutocomplete: FC<InputAddressAutocompleteProps> = ({
	register,
	label,
	error,
	readonly,
	children,
	id: idFromProps,
	onSelectAddress,
	...rest
}) => {
	const formCls = FormStyles();

	const containerRef = useRef<HTMLDivElement | null>(null);
	const [open, setOpen] = useState(false);
	const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

	const { placePredictions, fetchPlacePredictions, getPlaceDetails } =
		usePlacesAutocomplete();

	const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			setOpen(true);
			fetchPlacePredictions(e.target.value);
			setActiveSuggestionIndex(-1);
		},
		[fetchPlacePredictions]
	);

	const onSelectPlacePrediction = useCallback(
		(placePrediction: google.maps.places.AutocompletePrediction) => {
			containerRef.current?.querySelector('input')?.focus();
			getPlaceDetails(placePrediction.place_id).then((placeDetails) => {
				if (placeDetails) {
					onSelectAddress(placeDetails);
				}
				setOpen(false);
				setActiveSuggestionIndex(-1);
			});
		},
		[getPlaceDetails, onSelectAddress]
	);

	const onKeyDown = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(
		(e) => {
			if (e.key === 'ArrowDown') {
				e.preventDefault();
				setActiveSuggestionIndex(
					(index) => (index + 1) % placePredictions.length
				);
			}
			if (e.key === 'ArrowUp') {
				e.preventDefault();
				setActiveSuggestionIndex((index) =>
					index - 1 < 0 ? placePredictions.length - 1 : index - 1
				);
			}
			if (e.key === 'Enter') {
				e.preventDefault();
				const placePrediction = placePredictions[activeSuggestionIndex];
				if (placePrediction) {
					onSelectPlacePrediction(placePrediction);
				}
			}
		},
		[activeSuggestionIndex, onSelectPlacePrediction, placePredictions]
	);

	const onFocus = useCallback(() => {
		setOpen(true);
	}, []);

	const onBlur = useCallback(() => {
		const container = containerRef.current;
		if (!container) {
			return;
		}
		setTimeout(() => {
			if (!container.contains(document.activeElement)) {
				setOpen(false);
			}
		});
	}, []);

	const popupVisible = open && placePredictions.length > 0;
	const id = idFromProps || 'input-address-autocomplete';
	const popupId = `${id}-popup`;
	const activeSuggestionId =
		activeSuggestionIndex > -1
			? `${id}-suggestion-${activeSuggestionIndex}`
			: undefined;

	return (
		<div
			ref={containerRef}
			className={formCls.inputAddressAutocompleteContainer}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<label className={formCls.inputContainer} data-error={!!error}>
				{label && <div className={formCls.label}>{label}</div>}
				<input
					type='text'
					className={formCls.inputText}
					{...register}
					readOnly={readonly}
					onChange={onChange}
					role='combobox'
					aria-autocomplete='list'
					aria-controls={popupId}
					aria-expanded={popupVisible}
					aria-activedescendant={activeSuggestionId}
					onKeyDown={onKeyDown}
					{...rest}
				/>
				{error && (
					// TODO: translate
					<div className={formCls.error}>
						{error.message || 'problem as occorded'}
					</div>
				)}
				{children}
			</label>
			{popupVisible && (
				<ul
					className={formCls.inputAddressAutocompleteResultsContainer}
					role='listbox'
					aria-label='Adressen'
					tabIndex={0}
				>
					{placePredictions.map((prediction, i) => {
						const onClick = () => {
							onSelectPlacePrediction(prediction);
						};

						return (
							// eslint-disable-next-line jsx-a11y/click-events-have-key-events
							<li
								role='option'
								tabIndex={0}
								key={prediction.place_id}
								className={formCls.inputAddressAutocompleteResult}
								onClick={onClick}
								id={`${id}-suggestion-${i}`}
								aria-selected={i === activeSuggestionIndex}
								data-selected={i === activeSuggestionIndex}
							>
								{prediction.description}
							</li>
						);
					})}
				</ul>
			)}
		</div>
	);
};

export default InputAddressAutocomplete;
