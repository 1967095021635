/* eslint-disable no-restricted-syntax */
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useApi } from 'src/@context/Api';
import FormStyles from 'src/components/Form/FormStyles';
import InputText from './Form/InputText';
import LoadingScreen from './LoadingScreen';
import Button from './Button';

type TTokenTouch = {
	Id: string;
	Token: string;
};

const TouchRegisterForm: FC<{
	id: string;
	token: string;
	handleResult: (data: ApiResponse<TokenTouchResponse>) => void;
}> = ({ id, token, handleResult }) => {
	const formCls = FormStyles();
	const { get } = useApi();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<TTokenTouch>({
		defaultValues: { Id: id, Token: token },
	});

	const onSubmit = async ({ Id, Token }: TTokenTouch) => {
		setLoading(true);
		const { error, data } = await get<TokenTouchResponse>(
			`/2.0/account/token?id=${Id}&token=${Token}`
		);

		if (error) {
			handleResult({ error, data: undefined });
			setLoading(false);
			return;
		}

		handleResult({ error: undefined, data });
		setLoading(false);
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputText
				error={errors.Id}
				label='colourkey-ID'
				register={register('Id', { required: 'This is required' })}
			/>
			<InputText
				error={errors.Token}
				label='Aktivierungscode'
				register={register('Token', { required: 'This is required' })}
			/>
			<Button variant='formSubmitButton' type='submit'>
				Weiter
			</Button>
		</form>
	);
};

export default TouchRegisterForm;
