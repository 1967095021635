/* eslint-disable no-restricted-syntax */
import React, { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useApi } from 'src/@context/Api';
import { useUsers } from 'src/@context/Users';
import FormStyles from 'src/components/Form/FormStyles';
import InputDate from './Form/InputDate';
import InputEmail from './Form/InputEmail';
import InputFile from './Form/InputFile';
import InputPassword from './Form/InputPassword';
import InputSelect from './Form/InputSelect';
import InputText from './Form/InputText';
import { genderOptions, maxDate, minDate } from './Form/InputValues';
import LoadingScreen from './LoadingScreen';
import Button from './Button';

const RegisterForm: FC<{
	id: string;
	token: string;
	email: string;
	handleResult: (data: ApiResponse<UseraccountsDto>) => void;
}> = ({ id, token, email, handleResult }) => {
	const formCls = FormStyles();
	const { form } = useApi();
	const { signIn } = useUsers();
	const formRef = useRef<HTMLFormElement>(null);
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		getValues,
		formState: { errors },
	} = useForm<RegisterPostBinding & { PasswordConfirm: string }>({
		defaultValues: { Id: id, Token: token, Email: email },
	});

	const onSubmit = async (formData: RegisterPostBinding) => {
		const { current } = formRef;
		if (!current) {
			return;
		}

		setLoading(true);

		const { error, data } = await form<RegisterResponse>(
			`/2.0/account/register/post`,
			current
		);

		if (error) {
			handleResult({ error, data: undefined });
			setLoading(false);
			return;
		}

		if (data) {
			const { error: userError, data: user } = await signIn({
				Email: formData.Email,
				Password: formData.Password,
				Remember: true,
			});

			if (!userError) {
				handleResult({ error: userError, data: undefined });
				setLoading(false);
				return;
			}

			handleResult({ error: undefined, data: user });
			setLoading(false);
		}
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
			<div style={{ display: 'none' }}>
				<InputText
					error={errors.Id}
					label='colourkey-ID'
					register={register('Id', { required: 'This is required' })}
				/>
			</div>
			<div style={{ display: 'none' }}>
				<InputText
					error={errors.Token}
					label='colourkey-ID'
					register={register('Token', { required: 'This is required' })}
				/>
			</div>
			<InputFile
				error={errors.Picture}
				label='Picture'
				register={register('Picture', { required: 'This is required' })}
			/>
			<InputEmail
				error={errors.Email}
				label='Mailadresse'
				register={register('Email', { required: 'This is required' })}
			/>
			<InputSelect
				error={errors.Gender}
				label='Geschlecht'
				options={genderOptions}
				register={register('Gender', { required: 'This is required' })}
			/>
			<InputDate
				error={errors.BirthDate}
				label='Geburtsdatum'
				max={maxDate}
				min={minDate}
				register={register('BirthDate', { required: 'This is required' })}
			/>
			<InputPassword
				error={errors.Password}
				label='Passwort'
				register={register('Password', {
					required: 'This is required',
					minLength: 6,
				})}
			/>
			<InputPassword
				error={errors.PasswordConfirm}
				label='Passwort bestätigen'
				register={register('PasswordConfirm', {
					required: 'This is required',
					minLength: 6,
					validate: (value) =>
						value === getValues('Password') || 'Passwords needs to match',
				})}
			/>
			<Button variant='formSubmitButton' type='submit'>
				Weiter
			</Button>
		</form>
	);
};

export default RegisterForm;
