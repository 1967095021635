import type { ActionPerformed } from '@capacitor/push-notifications';
import React, { useContext } from 'react';

export type PushNotificationActionPerformedCallback = (
	notification: ActionPerformed
) => void;

export type PlatformMobile = {
	isActive: boolean;
	pushNotificationToken?: string;
	platform: 'IOS' | 'Web';
	onPushNotificationActionPerformed: (
		callback: PushNotificationActionPerformedCallback
	) => () => void;
};

const PlatformMobileContext = React.createContext<PlatformMobile>({
	isActive: false,
	platform: 'Web',
	onPushNotificationActionPerformed: () => () => {
		// noop
	},
});

export default PlatformMobileContext;

export const usePlatformMobile = (): PlatformMobile =>
	useContext(PlatformMobileContext);
