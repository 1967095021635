import React, { FC, useEffect, useState } from 'react';
import { createStyles, lighten, makeStyles } from '@material-ui/core/styles';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import mediaQueries from 'src/constants/mediaQueries';
import { Link } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import Logo from './Logo';
import ModalCloseButton from './ModalCloseButton';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 10,
				overflow: 'auto',
			},

			contentWrapper: {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100%',

				backgroundColor: theme.palette.background.default,
				color: theme.palette.primary.main,
				padding: `${spacing.Gutter.Mobile}px`,

				[mediaQueries.AfterMobile]: {
					padding: 45,
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
				},
			},
			content: {
				width: '100%',
				maxWidth: 640,
				borderRadius: 8,
				position: 'relative',

				display: 'flex',

				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',

				[mediaQueries.AfterMobile]: {
					width: '100%',
					border: `4px solid ${theme.palette.primary.main}`,
					paddingLeft: 104,
					paddingRight: 104,
					paddingBottom: 54,
				},
			},
			logo: {
				width: 322,
				display: 'block',
				margin: '0 auto 20px auto',
			},
			header: {
				textAlign: 'center',
				fontSize: '2em',
				[mediaQueries.AfterMobile]: {
					paddingTop: 54,
				},
			},
			text: {
				fontSize: rem(23, bodyFontSize.Mobile),
				letterSpacing: '0.15em',
				textTransform: 'uppercase',
				fontWeight: 'bold',
			},
			link: {
				width: '100%',
				padding: spacing.Gutter.Desktop,
				textAlign: 'center',
				fontSize: rem(30, bodyFontSize.Mobile),
				fontWeight: 'bold',
				letterSpacing: '0.2em',
				textTransform: 'uppercase',
				borderRadius: spacing.BoderRadius.Big,
				// margin: `0 ${spacing.Gutter.Desktop}px ${spacing.Gutter.Desktop}px ${spacing.Gutter.Desktop}px`,
				color: 'white',
				backgroundColor: theme.palette.primary.main,
				marginTop: 20,
				'& + &': {
					marginTop: 15,
				},
			},
			forgotLink: {
				textAlign: 'center',
				textDecoration: 'none',
				display: 'block',
				fontSize: rem(23, bodyFontSize.Mobile),
				marginTop: 20,
				'&:hover': {
					textDecoration: 'underline',
				},
			},
			homeLink: {
				marginTop: 15,
				textAlign: 'center',
				textDecoration: 'none',
				backgroundColor: theme.palette.primary.light,
				color: '#4E4E4E',
				padding: '10px 20px',
				borderRadius: 8,
				fontSize: rem(23, bodyFontSize.Mobile),
			},
			closeButton: {
				position: 'absolute',
				top: -45,
				right: -45,
				[mediaQueries.Mobile]: {
					display: 'none',
				},
			},
		}),
	{
		name: 'WelcomeScreen',
	}
);

const WelcomeScreen: FC = () => {
	const cls = useStyles();
	const { app } = useApp();
	const [showWelcome, setShowWelcome] = useState(false);

	useEffect(() => {
		const show = localStorage.getItem('FirstTime');
		if (show === undefined || show !== 'false') {
			document.body.setAttribute('data-welcome', 'true');
			setShowWelcome(true);
		}
	}, []);

	const handleCloseWelcomeScreen = () => {
		localStorage.setItem('FirstTime', 'false');
		document.body.removeAttribute('data-welcome');
		setShowWelcome(false);
	};

	if (!app?.SplashScreen?.ShowSplashScreen || !showWelcome) {
		document.body.removeAttribute('data-welcome');
		return null;
	}

	return (
		<article className={cls.root}>
			<div className={cls.contentWrapper}>
				<div className={cls.content}>
					<header className={cls.header}>
						<div className={cls.logo}>
							<Logo />
						</div>
						<div className={cls.text}>
							{app.SplashScreen.SplashScreenTitle ? (
								<div
									dangerouslySetInnerHTML={{
										__html: app.SplashScreen.SplashScreenTitle,
									}}
								/>
							) : (
								<p>Willkommen beim colourkey!</p>
							)}
						</div>
					</header>
					{app.SplashScreen.ShowSplashScreenButtonOne && (
						<Link
							to={app.SplashScreen.ShowSplashScreenButtonOneLink}
							onClick={handleCloseWelcomeScreen}
							className={cls.link}
						>
							{app.SplashScreen.ShowSplashScreenButtonOneText}
						</Link>
					)}
					{app.SplashScreen.ShowSplashScreenButtonTwo && (
						<Link
							to={app.SplashScreen.ShowSplashScreenButtonTwoLink}
							onClick={handleCloseWelcomeScreen}
							className={cls.link}
						>
							{app.SplashScreen.ShowSplashScreenButtonTwoText}
						</Link>
					)}
					<Link
						to='/passwort-zurucksetzen'
						onClick={handleCloseWelcomeScreen}
						className={cls.forgotLink}
					>
						Passwort vergessen?
					</Link>
					<Link
						to='/'
						onClick={handleCloseWelcomeScreen}
						className={cls.homeLink}
					>
						zur Homepage
					</Link>
					<ModalCloseButton
						className={cls.closeButton}
						onClick={handleCloseWelcomeScreen}
					/>
				</div>
			</div>
		</article>
	);
};

export default WelcomeScreen;
