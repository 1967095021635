import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import fontStyles from '../constants/fontStyles';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
			},
			sectionTitle: {
				position: 'relative',
				textAlign: 'center',
				marginBottom: 5,
			},
			content: {
				flex: '1 1 auto',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',

				[mediaQueries.Mobile]: {
					padding: `0 ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `0 ${spacing.Gutter.Desktop * 2}px`,
				},
			},
			messageContainer: {
				textAlign: 'center',
			},
			icon: {
				display: 'block',
				fontSize: '5em',
				marginBottom: 10,
				'&:before': { ...fontIcon.iconHeart },
			},
			message: {
				fontSize: rem(24, bodyFontSize.Mobile),
			},
			btns: {
				display: 'block',
				padding: spacing.Gutter.Desktop,
				textAlign: 'center',
				...fontStyles.largeText,
				border: 'none',
				borderRadius: spacing.BoderRadius.Big,
				marginBottom: spacing.Gutter.Desktop,
				backgroundColor: 'black',
				color: theme.palette.primary.main,
			},
		}),
	{ name: 'ThanksPage' }
);

const Fourteen: FC = () => {
	const cls = useStyles();
	const history = useHistory();
	// const { current } = useUsers();

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<div className={cls.content}>
					<div className={cls.messageContainer}>
						<div className={cls.icon} />
						<div className={cls.message}>
							Gratulation, für dich ist das erste Jahr gratis
						</div>
					</div>
					{/* {current && (
						<Link to='/profil' className={cls.btns}>
							Profil anzeigen
						</Link>
					)} */}
				</div>
			</div>
		</PageLayout>
	);
};

export default Fourteen;
