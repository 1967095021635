import { makeStyles, createStyles } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Flickity from 'react-flickity-component';
import { useAnnouncements } from 'src/@context/Announcements';
import { useOffers } from 'src/@context/Offers';
import Card from './Card';

const useStyles = makeStyles(
	() =>
		createStyles({
			root: {
				position: 'relative',
				height: 'calc(3/2 * 60vw)',
				zIndex: 1,
			},
			flickity: {
				transition: 'opacity .2s',

				'&, & .flickity-viewport, & .flickity-slider': {
					height: '100%',
				},

				'&:focus': {
					outline: 'none',
					border: 0,
				},
			},
			article: {
				width: '60vw',
				height: 'calc(90vw)',

				cursor: 'pointer',
				color: 'white',

				'&[data-active="true"]': {
					perspective: 800,
				},
				'&[data-active="false"]': {
					transform: 'scale(0.8)',
				},
			},
		}),
	{ name: 'MobileOfferSwipeList' }
);

const MemoizedOfferCard = React.memo(Card);

const MobileOfferSwipeList: FC = () => {
	const cls = useStyles();
	const { offers } = useOffers();
	const flickity = useRef<any>();

	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [actual, setActual] = useState<number>(currentIndex);

	const onChange = useCallback(
		(index) => {
			setCurrentIndex(index);
		},
		[setCurrentIndex]
	);

	const onScroll = useCallback((progress) => {
		const $flickity = flickity.current;
		setActual(progress * ($flickity.slides.length - 1));
	}, []);

	useEffect(() => {
		const $flickity = flickity.current;
		$flickity?.on('select', onChange);
		$flickity?.on('scroll', onScroll);
	}, [flickity, onChange, onScroll]);

	return (
		<div className={cls.root}>
			<Flickity
				className={cls.flickity}
				options={{
					setGallerySize: false,
					pageDots: false,
					prevNextButtons: false,
				}}
				// reloadOnUpdate={!swipeReady}
				// eslint-disable-next-line no-return-assign
				flickityRef={(f) => (flickity.current = f)}
				disableImagesLoaded // default false
			>
				{offers.map((offer, i) => (
					<article
						style={{
							transform: `scale(${1 - 0.2 * Math.abs(i - actual)})`,
						}}
						className={cls.article}
						key={offer.UID || i}
					>
						{Math.abs(i - currentIndex) <= 2 && (
							<MemoizedOfferCard item={offer} />
						)}
					</article>
				))}
			</Flickity>
		</div>
	);
};

export default MobileOfferSwipeList;
