import React, { FC } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import FormStyles from './FormStyles';

type InputFileProps = {
	register: any;
	label?: string;
	accept?: string;
	// name: string;
	error?: FieldError;
};

const InputFile: FC<InputFileProps> = ({
	register,
	label,
	accept,
	error,
	// name,
	children,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<input
				type='file'
				className={formCls.inputText}
				{...register}
				accept={accept || 'image/*'}
				{...rest}
			/>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputFile;
