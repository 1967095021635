import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OfferSection from './OfferSection';
import LargeIconButton, { LargeIconButtonGroup } from './LargeIconButton';
import { Facebook, Mail } from '../utils/Sharer';
import { usePlatformMobile } from '../@context/PlatformMobileContext';

const useStyles = makeStyles((theme) => createStyles({}), {
	name: 'OfferSectionShare',
});

const OfferSectionShare: FC<OfferDto | OfferAdminDto> = ({ ...offer }) => {
	const platformMobile = usePlatformMobile();
	const url = platformMobile.isActive
		? `https://colourkey.ch/angebot/${offer.UID}` // On mobile we don't want to share capacitor://localhost links
		: `${window.location.protocol}//${window.location.host}/angebot/${offer.UID}`;

	const onClickCopyLink = () => {
		navigator.clipboard
			.writeText(url)
			.then(() => {
				alert('Link kopiert!');
			})
			.catch((err) => {
				console.error(err);
				alert('Fehler: Link konnte nicht kopiert werden');
			});
	};

	const onClickWhatsApp = () => {
		if (offer.WhatsApp) {
			window.open(offer.WhatsApp, '_blank');
		}
	};

	const onClickInstagram = () => {
		if (offer.Instagram) {
			window.open(offer.Instagram, '_blank');
		}
	};

	return (
		<OfferSection title='Teilen & Folgen'>
			<div style={{ maxWidth: 326, margin: '0 auto' }}>
				<LargeIconButtonGroup>
					<LargeIconButton
						color='#3CA695'
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='126'
								height='126'
								fill='none'
								viewBox='0 0 126 126'
							>
								<path
									fill='#3CA695'
									d='M76.95 32H38a4 4 0 00-4 4v43.032a4 4 0 004 4h38.95a4 4 0 004-4V36a4 4 0 00-4-4z'
								/>
								<path
									stroke='#3CA695'
									strokeLinecap='round'
									strokeWidth='5'
									d='M76.95 34.5H38a1.5 1.5 0 00-1.5 1.5v43.032a1.5 1.5 0 001.5 1.5h38.95a1.5 1.5 0 001.5-1.5V36a1.5 1.5 0 00-1.5-1.5z'
								/>
								<path
									fill='#fff'
									d='M88.177 43.227h-38.95a4 4 0 00-4 4V90.26a4 4 0 004 4h38.95a4 4 0 004-4V47.227a4 4 0 00-4-4z'
								/>
								<path
									stroke='#3CA695'
									strokeLinecap='round'
									strokeWidth='5'
									d='M88.177 45.727h-38.95a1.5 1.5 0 00-1.5 1.5V90.26a1.5 1.5 0 001.5 1.5h38.95a1.5 1.5 0 001.5-1.5V47.227a1.5 1.5 0 00-1.5-1.5z'
								/>
							</svg>
						}
						onClick={onClickCopyLink}
					>
						Copy Link
					</LargeIconButton>
					<LargeIconButton
						color='#2C80BF'
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='126'
								height='126'
								fill='none'
								viewBox='0 0 126 126'
							>
								<path
									fill='#2C80BF'
									d='M33.358 91.905a7.541 7.541 0 005.65 2.31h24.146V71.424h-7.74v-9.853h7.74v-4.852a13.744 13.744 0 013.713-9.7 11.966 11.966 0 019.131-4.007h7.121v11.243h-6.423c-1.344 0-2.015.719-2.014 2.157v5.16h8.436v9.852h-8.436v22.79h11.92a7.79 7.79 0 005.724-2.309 7.625 7.625 0 002.324-5.62V38.778a7.373 7.373 0 00-2.324-5.54A7.934 7.934 0 0086.602 31H39.01a7.45 7.45 0 00-5.65 2.235 7.53 7.53 0 00-2.322 5.54v47.51a8.02 8.02 0 002.321 5.62z'
								/>
							</svg>
						}
						onClick={() => Facebook(url)}
					>
						Facebook
					</LargeIconButton>
					<LargeIconButton
						color='#E7675E'
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='127'
								height='126'
								fill='none'
								viewBox='0 0 127 126'
							>
								<path
									fill='#E7675E'
									d='M26.554 90.4h74.1V54.2l-36.8 17.5-37.3-17.6v36.3zm0-55.4v9.1l37.3 17.3 36.9-17.3V35h-74.2z'
								/>
							</svg>
						}
						onClick={() => Mail(url)}
					>
						Mail
					</LargeIconButton>
					{offer.Instagram && (
						<LargeIconButton
							onClick={onClickInstagram}
							color='#8065BF'
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='126'
									height='126'
									fill='none'
									viewBox='0 0 126 126'
								>
									<path
										fill='#8065BF'
										d='M50.017 88.315a13.6 13.6 0 01-5.77-1.179 9.365 9.365 0 01-3.542-2.36 10.484 10.484 0 01-2.36-3.542 17.734 17.734 0 01-1.182-5.773c-.129-3.409-.129-4.328-.129-12.724s0-9.441.129-12.721a13.611 13.611 0 011.182-5.771 9.57 9.57 0 012.241-3.67 6.129 6.129 0 013.528-2.5 17.878 17.878 0 015.9-1.179c3.412-.132 4.329-.132 12.724-.132s9.444 0 12.724.132a13.6 13.6 0 015.77 1.179 9.365 9.365 0 013.542 2.36 10.484 10.484 0 012.36 3.542 17.734 17.734 0 011.182 5.773c.129 3.409.129 4.329.129 12.721s0 9.444-.129 12.724a13.626 13.626 0 01-1.182 5.773 9.325 9.325 0 01-2.36 3.539 12.888 12.888 0 01-3.542 2.363 17.741 17.741 0 01-5.77 1.179c-3.412.132-4.329.132-12.724.132-8.526.393-9.575.393-12.724.129m-.388-57.048a26.162 26.162 0 00-7.739 1.438 18.462 18.462 0 00-5.64 3.54 13.663 13.663 0 00-3.542 5.64 22.581 22.581 0 00-1.443 7.743C31.132 52.9 31 54.084 31 62.608s0 9.706.262 12.986c.09 2.637.576 5.246 1.444 7.738.829 2.08 2.03 3.99 3.542 5.64a12.865 12.865 0 005.64 3.54 22.484 22.484 0 007.738 1.442c3.409.132 4.459.262 12.983.262 8.524 0 9.706 0 12.986-.262a26.18 26.18 0 007.738-1.442 18.462 18.462 0 005.64-3.54 12.88 12.88 0 003.542-5.64 22.617 22.617 0 001.444-7.738c.262-3.412.262-4.46.262-12.986s0-9.706-.262-12.986a26.245 26.245 0 00-1.444-7.738 18.487 18.487 0 00-3.542-5.64 12.865 12.865 0 00-5.64-3.54 22.5 22.5 0 00-7.739-1.442c-3.28-.133-4.458-.262-12.986-.262s-9.573.129-12.982.262zm26.1 14.426a3.803 3.803 0 007.606 0 3.702 3.702 0 00-3.673-3.8 3.922 3.922 0 00-3.933 3.8zm-29.251 16.92a16.2 16.2 0 1016.133-16.265 16.344 16.344 0 00-16.133 16.265zm5.773 0a10.522 10.522 0 0110.493-10.493A10.411 10.411 0 0173.1 62.608 10.524 10.524 0 0162.608 73.1a10.411 10.411 0 01-10.36-10.492z'
									/>
								</svg>
							}
						>
							Instagram
						</LargeIconButton>
					)}
					{offer.WhatsApp && (
						<LargeIconButton
							onClick={onClickWhatsApp}
							color='#A3C038'
							icon={
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='126'
									height='126'
									fill='none'
									viewBox='0 0 126 126'
								>
									<path
										fill='#A3C038'
										d='M77.29 67.262l.135.066c.522.175.98.503 1.314.94a6.066 6.066 0 01-.345 3.995 7.776 7.776 0 01-5.431 3.837l-.218.02c-.4.044-.801.068-1.203.071a19.92 19.92 0 01-7.4-1.892 28.558 28.558 0 01-12.247-10.645 5.313 5.313 0 00-.124-.18 13.99 13.99 0 01-3.042-7.704 7.629 7.629 0 012.824-6.49 10.506 10.506 0 013.646-.94c.17-.007.234-.01.266-.013l.073-.001c.869 0 1.494.527 1.968 1.66l.186.447c.522 1.255 1.876 4.512 1.965 4.697a2.045 2.045 0 01.058 1.924l-.098.198a4.721 4.721 0 01-.712 1.13c-.11.126-.22.258-.331.391l-.023.028c-.25.302-.509.613-.748.85-.099.088-.185.19-.256.303a.484.484 0 00.074.174 23.069 23.069 0 008.761 7.784c.207.09.88.37.924.37.043 0 .115-.075.169-.135.408-.463 1.738-2.012 2.172-2.664a1.738 1.738 0 011.478-.869c.35.013.695.087 1.02.218.707.254 4.389 2.059 5.146 2.43z'
									/>
									<path
										fill='#A3C038'
										fillRule='evenodd'
										d='M42.705 41.313a29.458 29.458 0 0120.83-8.549 29.456 29.456 0 0129.531 29.313 29.583 29.583 0 01-43.513 25.815L33.95 92.849a.78.78 0 01-.975-.995l5.073-14.953a29.096 29.096 0 01-4.05-14.824 29.458 29.458 0 018.707-20.764zm7.956 40.131a23.396 23.396 0 0012.873 3.836 23.393 23.393 0 10-23.399-23.203A22.889 22.889 0 0044.59 75.68a.782.782 0 01.108.713l-2.522 7.445 7.82-2.485a.782.782 0 01.666.091z'
										clipRule='evenodd'
									/>
								</svg>
							}
						>
							WhatsApp
						</LargeIconButton>
					)}
				</LargeIconButtonGroup>
			</div>
		</OfferSection>
	);
};

export default OfferSectionShare;
