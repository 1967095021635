import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import MenuItem from './MenuItem';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
	},

	dropdownToggler: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontSize: '1.8rem',
		padding: `0 ${spacing.Gutter.Mobile}px`,
		marginBottom: spacing.Gutter.Mobile,
		fontWeight: 700,
		[mediaQueries.AfterMobile]: {
			fontSize: rem(36, bodyFontSize.Desktop),
			marginBottom: 15,
		},
	},

	dropdownContent: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '0',
		overflow: 'hidden',
		transition: 'all .3s ease',
		willChange: 'height',

		[mediaQueries.Mobile]: {
			'&[data-open="true"]': {
				height: 'auto',
				maxHeight: '80vh',
			},
		},
		[mediaQueries.AfterMobile]: {
			'&[data-open="true"]': {
				height: 'auto',
				maxHeight: '60vh',
			},
		},
	},
}));

const DropdownMenu: FC<MenuDto & { isSubmenu?: boolean }> = ({
	Title,
	Submenus,
	isSubmenu,
}) => {
	const cls = useStyles();
	const [open, setOpen] = useState(false);

	const handleClick = () => setOpen(!open);

	return (
		<div className={cls.root}>
			<button
				className={cls.dropdownToggler}
				type='button'
				onClick={() => handleClick()}
			>
				{Title}
			</button>
			<div className={cls.dropdownContent} data-open={open}>
				{Submenus.map((sub) => {
					switch (sub.type) {
						case 'PanelPage':
							return <MenuItem menu={sub} isSubmenu key={sub.UID} />;

						case 'DropdownMenu':
							return (
								<DropdownMenu key={sub.UID} isSubmenu={isSubmenu} {...sub} />
							);

						default:
							return null;
					}
				})}
			</div>
		</div>
	);
};

export default DropdownMenu;
