import React, { FC, useRef, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useApp } from 'src/@context/App';
import FormStyles from 'src/components/Form/FormStyles';
import InputKantonDropdown from 'src/components/Form/InputKantonDropdown';
import InputIconDropdown from 'src/components/Form/InputIconDropdown';
import InputText from 'src/components/Form/InputText';
import InputSelect from 'src/components/Form/InputSelect';
import { countryOptions } from 'src/components/Form/InputValues';
import InputCheckbox from 'src/components/Form/InputCheckbox';
import InputDate from 'src/components/Form/InputDate';
import InputFile from 'src/components/Form/InputFile';
import { useOffers } from 'src/@context/Offers';
import useStyles from './OfferEdit.styles';
import InputEmail from './Form/InputEmail';
import InputCategories from './Form/InputCategories';
import LoadingScreen from './LoadingScreen';
import InputNumber from './Form/InputNumber';
import Textarea from './Form/Textarea';
import ModalPageLayout from './ModalPageLayout';
import IconButton from './IconButton';
import Image from './Image';
import { optionalUrl } from '../utils/validation';

const parseDefaultDate = (date: string) => {
	const defaultDate = new Date(date);
	const year = new Intl.DateTimeFormat('de', { year: 'numeric' }).format(
		defaultDate
	);
	const month = new Intl.DateTimeFormat('de', { month: 'numeric' }).format(
		defaultDate
	);
	const day = new Intl.DateTimeFormat('de', { day: '2-digit' }).format(
		defaultDate
	);

	return `${year}-${month}-${day}`;
};

const OfferEdit: FC<OfferAdminDto & { closeEdit: (v: boolean) => void }> = ({
	children,
	closeEdit,
	...offer
}) => {
	const cls = useStyles();
	const formCls = FormStyles();
	const form = useRef<HTMLFormElement>(null);
	const { app, setMessageScreen } = useApp();
	const { putFormData } = useOffers();
	const [loading, setLoading] = useState(false);

	const defaultStartDate = offer?.StartDate
		? parseDefaultDate(offer.StartDate)
		: '';
	const defaultEndDate = offer?.EndDate
		? parseDefaultDate(offer.EndDate)
		: '';

	const defaultValues = {
		Title: offer.Title || '',
		PlaceText: offer.PlaceText || '',
		Hashtags: offer.Hashtags || '',
		Text: offer.Text || '',
		Address: offer.Address || '',
		COAddress: offer.COAddress || '',
		ZipCode: offer.ZipCode || '',
		City: offer.City || '',
		Province: offer.Province || '',
		Country: offer.Country || '',
		PhoneNumber: offer.PhoneNumber || '',
		Website: offer.Website || '',
		Email: offer.Email || '',
		Instagram: offer.Instagram || '',
		Lat: offer.Location?.lat?.toString() || '',
		Lng: offer.Location?.lng?.toString() || '',
		Banner: offer.Banner || '',
		OfferSubCategories: offer.Categories || [],
		PartnerFirstName: offer.PartnerFirstName || '',
		PartnerLastName: offer.PartnerLastName || '',
		PartnerPhoneNumber: offer.PartnerPhoneNumber || '',
		PartnerEmail: offer.PartnerEmail || '',
		IsOnline: offer.IsOnline || false,
		IsGratis: offer.IsGratis || false,
		Plus18: offer.Plus18 || false,
		IsEndlessOffer: offer.IsEndlessOffer || false,
		IsSpecial: offer.IsSpecial || false,
		PaidPublicity: offer.PaidPublicity || false,
		AddToNewsletter: offer.AddToNewsletter || false,
		Icon: offer.Icon || '',
		FromAge: offer.FromAge || 0,
		ToAge: offer.ToAge || 0,
		StartDate: defaultStartDate,
		EndDate: defaultEndDate,
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		control,
		setValue,
	} = useForm<OfferPostBinding>({
		defaultValues,
	});

	const onSubmit = async (data: OfferPostBinding) => {
		const { current } = form;

		if (!current) {
			return;
		}

		const formData = new FormData();
		// const formDataTest = new FormData(current);
		// eslint-disable-next-line no-restricted-syntax
		for (const key in data) {
			if (key === 'Picture') {
				formData.append(key, data.Picture[0]);
			} else if (key === 'Logo') {
				formData.append(key, data.Logo[0]);
			} else {
				formData.append(key, data[key as keyof typeof data]);
			}
		}

		setLoading(true);
		const { data: success, error } = await putFormData(
			offer.ObjectId,
			formData
		);

		if (error) {
			// TODO: i18n
			setMessageScreen({
				message: error.Message || 'try again later',
				type: 'Error',
			});

			setLoading(false);
			closeEdit(false);
			return;
		}

		if (success) {
			setMessageScreen({
				message: 'saved !',
				type: 'Success',
			});

			setLoading(false);
			closeEdit(false);
		}
	};

	// TODO: Fix loading background color
	if (loading || !app || !app.Config) {
		return <LoadingScreen />;
	}

	return (
		<ModalPageLayout
			showCloseButton={false}
			spaceForHeader={false}
			fullWidth
			className={cls.pageLayout}
			header={
				<>
					<IconButton
						icon='iconClose'
						type='button'
						aria-label='Close button'
						onClick={() => closeEdit(false)}
					/>
				</>
			}
		>
			<Image
				pictureRef={offer.PreviewPicture}
				sizes='(max-width: 767px) 100vw,(max-width: 1024px) 70vw, 50vw'
				className={cls.bgImage}
				alt={offer.PreviewPicture?.Legend || ''}
			/>
			{app && app.Config && (
				<article className={cls.root}>
					<div className={cls.panelsContainer}>
						<form
							className={cls.form}
							onSubmit={handleSubmit(onSubmit)}
							ref={form}
						>
							<div className={formCls.section}>
								<InputFile
									error={errors.Picture}
									label='Picture'
									register={register('Picture')}
								/>
								{offer.PreviewPicture?.Src && (
									<a
										rel='noreferrer'
										target='_blank'
										href={offer.PreviewPicture?.Src}
										className={formCls.submit}
									>
										Preview image
									</a>
								)}
								<InputText
									error={errors.Title}
									label='Titel'
									register={register('Title', { required: 'Bitte ausfüllen' })}
								/>
								<Textarea
									control={control}
									label='Beschreibung Angebot'
									name='PlaceText'
									initialValue={defaultValues.PlaceText}
									setFieldValue={setValue}
									rules={{ required: 'Bitte ausfüllen' }}
								/>
								<InputText
									error={errors.Hashtags}
									label='#Hashtags (Bsp.: #hashtag1 #hashtag2 …)'
									register={register('Hashtags', {
										required: false,
										pattern: {
											value:
												/^#(?![0-9_]+\b)([A-Za-z0-9_])+(\s+#(?![0-9_]+)([A-Za-z0-9])+)*$/,
											message: 'Bsp.: #hashtag1 #hashtag2 …',
										},
									})}
								/>
								<Textarea
									control={control}
									label='Aktions-Text'
									name='Text'
									initialValue={defaultValues.Text}
									setFieldValue={setValue}
									rules={{ required: 'Bitte ausfüllen' }}
								/>
							</div>
							<div className={formCls.section}>
								<InputText
									error={errors.Address}
									label='Adresse'
									register={register('Address', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputText
									error={errors.COAddress}
									label='c/o Adresse'
									register={register('COAddress')}
								/>
								<InputText
									error={errors.ZipCode}
									label='Postleitzahl'
									register={register('ZipCode', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputText
									error={errors.City}
									label='Ort'
									register={register('City', { required: 'Bitte ausfüllen' })}
								/>
								<InputKantonDropdown
									error={errors.Province}
									label='Kanton'
									register={register('Province', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputSelect
									error={errors.Country}
									label='Land'
									options={countryOptions}
									register={register('Country', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputText
									error={errors.PhoneNumber}
									label='Telefon'
									register={register('PhoneNumber', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputText
									error={errors.Website}
									label='Website'
									register={register('Website', {
										validate: optionalUrl,
									})}
								/>
								<InputEmail
									error={errors.Email}
									label='E-Mail'
									register={register('Email', { required: 'Bitte ausfüllen' })}
								/>
								<InputText
									error={errors.Instagram}
									label='Instagram (Bsp.: https://www.instagram.com/colourkey)'
									register={register('Instagram', {
										validate: optionalUrl,
									})}
								/>
								<InputText
									error={errors.Lat}
									label='Breitengrad (für Pin auf Karte), optional'
									register={register('Lat', {
										required: 'Bitte ausfüllen',
										pattern: {
											value: /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/,
											message: 'Entered value does not match longotude format',
										},
									})}
								/>
								<InputText
									error={errors.Lng}
									label='Längengrad (für Pin auf Karte), optional'
									register={register('Lng', {
										required: 'Bitte ausfüllen',
										pattern: {
											value:
												/(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)/,
											message: 'Entered value does not match longotude format',
										},
									})}
								/>
								<InputText
									error={errors.Banner}
									label='Störer (keine Filter Relevanz, wird aber mit "gratis" checkbox überschrieben)'
									register={register('Banner')}
								/>
							</div>
							{app.Config?.Categories && (
								<div className={formCls.section}>
									<InputCategories
										error={errors.OfferSubCategories as FieldError | undefined}
										label='RUBRIKEN'
										value={offer.Categories}
										register={register('OfferSubCategories', {
											required: 'Bitte ausfüllen',
										})}
									/>
								</div>
							)}
							<div className={formCls.section}>
								<InputText
									error={errors.PartnerFirstName}
									label='Kontaktperson Vorname'
									register={register('PartnerFirstName', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputText
									error={errors.PartnerLastName}
									label='Kontaktperson Name'
									register={register('PartnerLastName', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputText
									error={errors.PartnerPhoneNumber}
									label='Kontaktperson Telefonnummer'
									register={register('PartnerPhoneNumber', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputEmail
									error={errors.PartnerEmail}
									label='Kontaktperson E-mail'
									register={register('PartnerEmail', {
										required: 'Bitte ausfüllen',
									})}
								/>
								<InputFile
									accept='.ai,.eps'
									error={errors.Logo}
									label='Logo'
									register={register('Logo')}
								/>
								{offer.LogoFile?.Src && (
									<a
										rel='noreferrer'
										target='_blank'
										href={offer.LogoFile?.Src}
										className={formCls.submit}
									>
										Preview logo file
									</a>
								)}
							</div>
							<h2 className={cls.title}>Admin fields</h2>
							<div className={formCls.section}>
								<InputCheckbox
									id='offer-register-is-online'
									label='Approved'
									register={register('IsOnline')}
									error={errors.IsOnline}
									defaultChecked={defaultValues.IsOnline}
								/>
								<InputCheckbox
									id='offer-register-gratis'
									label='Gratis (wird per Gratis Filter gefiltert und überschreibt Störer mit "gratis")'
									register={register('IsGratis')}
									error={errors.IsGratis}
									defaultChecked={defaultValues.IsGratis}
								/>
								<InputCheckbox
									id='offer-register-plus-18'
									label='+18 (wird per +18 Filter gefiltert)'
									register={register('Plus18')}
									error={errors.Plus18}
									defaultChecked={defaultValues.Plus18}
								/>
								<InputCheckbox
									id='offer-register-endless-offer'
									label='Endless Offer'
									register={register('IsEndlessOffer')}
									error={errors.IsEndlessOffer}
									defaultChecked={defaultValues.IsEndlessOffer}
								/>
								<InputCheckbox
									id='offer-register-is-special-offer'
									label='Special (wird per Special Filter gefiltert)'
									register={register('IsSpecial')}
									error={errors.IsSpecial}
									defaultChecked={defaultValues.IsSpecial}
								/>
								<InputCheckbox
									id='offer-register-pay-pub'
									label='Ich interessiere mich für Inserate-Schaltungen'
									register={register('PaidPublicity')}
									error={errors.PaidPublicity}
									defaultChecked={defaultValues.PaidPublicity}
								/>
								<InputCheckbox
									id='add-ot-newsletter'
									label='News per E-Mail erhalten'
									register={register('AddToNewsletter')}
									error={errors.AddToNewsletter}
									defaultChecked={defaultValues.AddToNewsletter}
								/>
								<InputIconDropdown
									error={errors.Icon}
									label='Icon'
									register={register('Icon')}
								/>
								<InputNumber
									error={errors.FromAge}
									label='Alter von (wird für STUcard benutzt)'
									register={register('FromAge')}
								/>
								<InputNumber
									error={errors.ToAge}
									label='Alter bis (wird für STUcard benutzt)'
									register={register('ToAge')}
								/>
								<InputDate
									error={errors.StartDate}
									label='Start date'
									register={register('StartDate')}
								/>
								<InputDate
									error={errors.EndDate}
									label='End date'
									register={register('EndDate')}
								/>
							</div>
							<button
								type='submit'
								disabled={!isDirty}
								className={formCls.submit}
							>
								Weiter
							</button>
						</form>
					</div>
				</article>
			)}
			{children}
		</ModalPageLayout>
	);
};

export default OfferEdit;
