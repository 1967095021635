import React, { FC } from 'react';
import OfferCard from './OfferCard';
import AdvertisementCard from './AdvertisementCard';

const Card: FC<{ item: OfferDto }> = ({ item }) => {
  const { type } = item;

  if (type === 'Advertisement') {
    // eslint-disable-next-line no-case-declarations
    const ad = (item as unknown) as AdvertisementDto;
    return <AdvertisementCard {...ad} />;
  }

  return <OfferCard {...item} />;
};

export default Card;
