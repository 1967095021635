import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import MenuItem, { StaticMenu } from './MenuItem';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';
import { useUsers } from '../@context/Users';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
	},

	dropdownToggler: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontSize: '1.8rem',
		padding: `0 ${spacing.Gutter.Mobile}px`,
		marginBottom: spacing.Gutter.Mobile,
		fontWeight: 700,
		[mediaQueries.AfterMobile]: {
			fontSize: rem(36, bodyFontSize.Desktop),
			marginBottom: 15,
		},
	},

	dropdownContent: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '0',
		overflow: 'hidden',
		transition: 'all .3s ease',
		willChange: 'height',

		[mediaQueries.Mobile]: {
			'&[data-open="true"]': {
				height: 'auto',
				maxHeight: '80vh',
			},
		},
		[mediaQueries.AfterMobile]: {
			'&[data-open="true"]': {
				height: 'auto',
				maxHeight: '60vh',
			},
		},
	},
}));

const MemberMenu: FC = () => {
	const cls = useStyles();
	const [open, setOpen] = useState(false);
	const { current } = useUsers();

	const handleClick = () => setOpen(!open);

	return (
		<div className={cls.root}>
			<button
				className={cls.dropdownToggler}
				type='button'
				onClick={() => handleClick()}
			>
				Mitglied
			</button>
			<div className={cls.dropdownContent} data-open={open}>
				{current ? (
					<MenuItem
						menu={
							{
								type: 'static',
								url: '/profil',
								title: 'Mein Profil',
							} as StaticMenu
						}
						isSubmenu
					/>
				) : (
					<MenuItem
						menu={
							{
								type: 'static',
								url: '/login',
								title: 'Einloggen',
							} as StaticMenu
						}
						isSubmenu
					/>
				)}
				<MenuItem
					menu={
						{
							type: 'static',
							url: '/neuanmeldung',
							title: 'Mitglied werden',
						} as StaticMenu
					}
					isSubmenu
				/>
				<MenuItem
					menu={
						{
							type: 'static',
							url: '/geschenk',
							title: 'Mitgliedschaft verschenken',
						} as StaticMenu
					}
					isSubmenu
				/>
			</div>
		</div>
	);
};

export default MemberMenu;
