/* eslint-disable jsx-a11y/label-has-associated-control */
import { makeStyles, createStyles } from '@material-ui/core';
import React, { FC } from 'react';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';

const useStyles = makeStyles((theme) =>
	createStyles({
		inputRadio: {
			display: 'none',
		},
		legend: {
			display: 'block',
			textAlign: 'center',
			border: '1px solid black',
			marginBottom: spacing.Gutter.Mobile / 2,
			borderRadius: 8,
			padding: '15px 5px',
			fontSize: rem(27, bodyFontSize.Desktop),
			cursor: 'pointer',

			'$inputRadio:checked + &': {
				backgroundColor: 'black',
				color: theme.palette.primary.main,
			},

			// &.icon {
			//     font-size: 26em/unit(@font-general-desktop);
			// }

			'$inputRadio:[data-unavailable="true"] + &': {
				opacity: '.5',
			},
		},
	})
);

type InputCheckboxProps = {
	register: any;
	label: string;
	id: string;
	value: string;
};

const InputRadio: FC<InputCheckboxProps> = ({
	register,
	label,
	id,
	value,
	children,
	...rest
}) => {
	const cls = useStyles();

	return (
		<>
			<input
				className={cls.inputRadio}
				type='radio'
				id={id}
				value={value}
				{...register}
				{...rest}
			/>
			<label
				htmlFor={id}
				className={cls.legend}
				dangerouslySetInnerHTML={{ __html: label }}
			/>
			{children}
		</>
	);
};

export default InputRadio;
