import React, { FC, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import spacing from 'src/constants/spacing';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import Logo from './Logo';
import MenuToggler from './MenuToggler';
import { useUsers } from '../@context/Users';
import IconButton, { IconButtonProps } from './IconButton';
import { useApp } from '../@context/App';
import mediaQueries from '../constants/mediaQueries';

function getScrollParent(node?: HTMLElement | null): HTMLElement | null {
	if (node == null) {
		return null;
	}

	if (node.scrollHeight > node.clientHeight) {
		const { overflowY } = window.getComputedStyle(node);
		if (overflowY !== 'visible' && overflowY !== 'hidden') {
			return node;
		}
	}

	return getScrollParent(node.parentNode as HTMLElement);
}

const useStyles = makeStyles(() => ({
	header: {
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 4,
		padding:
			'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',

		display: 'flex',
		flexShrink: 0,
		height: spacing.HeaderHeight,
		pointerEvents: 'none',
		'& > *': {
			pointerEvents: 'auto',
		},
		transition: 'transform 300ms',
	},
	headerScrolledAway: {
		transform: 'translateY(calc(-100% - env(safe-area-inset-top)))',
		[mediaQueries.AfterMobile]: {
			transform: 'none',
		},
	},
	nav: {
		marginLeft: 'auto',
		paddingTop: 14,
		paddingRight: 14,
		gap: 7,
		display: 'flex',
	},
	logo: {
		position: 'absolute',
		width: 200,
		left: 0,
		top: 0,
		marginTop: 'env(safe-area-inset-top)',
		zIndex: 1,
		'& svg': {
			width: 'auto',
			height: 70,
			[mediaQueries.AfterMobile]: {
				height: 86,
			},
			marginLeft: '-5px',
			marginTop: '-5px',
		},
	},
	logoBlack: {
		'& svg path': {
			fill: 'black',
		},
	},
	profileImage: {
		width: 60,
		height: 60,
		borderRadius: '50%',
		objectFit: 'cover',
	},
}));

const Header: FC<{
	className?: string;
	variant?: IconButtonProps['variant'];
	showDefaultNav?: boolean;
	showCloseButton?: boolean;
	children?: React.ReactNode;
	scrollContainer?: HTMLElement | null;
}> = ({
	variant = 'whiteTransparent',
	showDefaultNav = true,
	showCloseButton = false,
	children,
	scrollContainer,
	className,
}) => {
	const { toggleMenu } = useApp();
	const history = useHistory();
	const cls = useStyles();
	const { current } = useUsers();
	const containerRef = useRef<HTMLElement>(null);
	const [scrolledAway, setScrolledAway] = useState(false);
	const [scrollParent, setScrollParent] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (scrollContainer) {
			setScrollParent(scrollContainer);
			return;
		}

		setScrollParent(getScrollParent(containerRef.current));

		const onResize = () => {
			setScrollParent(getScrollParent(containerRef.current));
		};

		window.addEventListener('resize', onResize);

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener('resize', onResize);
		};
	}, [scrollContainer]);

	useEffect(() => {
		if (!scrollParent) {
			return;
		}

		setScrolledAway(false);
		let lastScroll = scrollParent.scrollTop;

		const onScroll = () => {
			if (
				scrollParent.scrollTop > lastScroll &&
				scrollParent.scrollTop > spacing.HeaderHeight / 2
			) {
				// We're scrolling down
				setScrolledAway(true);
			} else {
				// We're scrolling up
				setScrolledAway(false);
			}

			lastScroll = scrollParent.scrollTop;
		};

		scrollParent.addEventListener('scroll', onScroll);

		// eslint-disable-next-line consistent-return
		return () => {
			scrollParent.removeEventListener('scroll', onScroll);
		};
	}, [scrollParent]);

	return (
		<header
			ref={containerRef}
			className={classNames(
				cls.header,
				scrolledAway && cls.headerScrolledAway,
				className
			)}
		>
			<div
				className={classNames(
					cls.logo,
					variant === 'whiteTransparent' && cls.logoBlack
				)}
			>
				<Logo hasHomeLink />
			</div>
			<div className={cls.nav}>
				{children}
				{showDefaultNav && (
					<>
						{current ? (
							<IconButton
								onClick={() => {
									toggleMenu(false);
								}}
								variant={variant}
								as='link'
								to='/profil'
								data-logged
							>
								<img
									className={cls.profileImage}
									src={`${
										process.env.REACT_APP_AUTH_URL
									}/2.0/account/avatar?id=${
										current.UserName
									}&${performance.now()}`}
									alt=''
								/>
							</IconButton>
						) : (
							<IconButton
								onClick={() => {
									toggleMenu(false);
								}}
								variant={variant}
								as='link'
								to='/login'
								icon='iconProfile'
							/>
						)}
						<MenuToggler variant={variant} className='' />
					</>
				)}
				{showCloseButton && (
					<IconButton
						icon='iconClose'
						type='button'
						aria-label='Close button'
						onClick={() => history.push('/')}
					/>
				)}
			</div>
		</header>
	);
};

export default Header;
