import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type SelectOption = {
	legend: string;
	value: string;
};

type InputSelectProps = {
	register: any;
	label?: string;
	error: FieldError | undefined;
};

const iconOptions: SelectOption[] = [
	{ legend: 'Keine', value: '' },
	{ legend: 'Musik', value: 'Music' },
	{ legend: 'Sport', value: 'Sport' },
	{ legend: 'Museum', value: 'Museum' },
	{ legend: 'Theater', value: 'Theater' },
	{ legend: 'Shopping', value: 'Shopping' },
	{ legend: 'Diverses', value: 'Others' },
];

const InputIconDropdown: FC<InputSelectProps> = ({
	register,
	label,
	error,
	children,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<select className={formCls.inputSelect} {...register} {...rest}>
				{iconOptions.map((o) => (
					<option value={o.value} key={o.value}>
						{o.legend}
					</option>
				))}
			</select>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputIconDropdown;
