import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { bodyFontSize } from 'src/constants/stylesheet';

import { rem } from 'src/utils/Units';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				display: 'block',
				backgroundColor: 'white',
				borderRadius: 5,
				padding: 40,
				marginTop: 30,
			},
			title: {
				fontWeight: 'bold',
				fontSize: rem(32, bodyFontSize.Mobile),
				letterSpacing: '0.01em',
				lineHeight: 0.866,
				marginBottom: rem(20, bodyFontSize.Mobile),
			},
			body: {
				fontSize: rem(20, bodyFontSize.Mobile),
				color: 'black',
			},
		}),
	{
		name: 'OfferSection',
	}
);

interface OfferSectionProps {
	title: string;
	children: React.ReactNode;
}
const OfferSection: FC<OfferSectionProps> = ({ title, children }) => {
	const cls = useStyles();

	return (
		<div className={cls.root}>
			<h3 className={cls.title}>{title}</h3>
			<div className={cls.body}>{children}</div>
		</div>
	);
};

export default OfferSection;
