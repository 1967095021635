import { makeStyles, createStyles, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize, warnningAnimation } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import { useApp } from 'src/@context/App';
import { useOffers } from 'src/@context/Offers';
import Map from './Map';
import MobileOfferList from './MobileOfferList';
import Filters from './Filters';
import MobileOfferSwipeList from './MobileOfferSwipeList';
import Footer from './Footer';
import IconButton from './IconButton';
import Header from './Header';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				height: '100%',
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
				paddingTop: spacing.HeaderHeight,
			},
			logo: {
				position: 'relative',
				width: 200,

				'& svg': {
					width: 200,
					height: 75,
					marginLeft: '-5px',
					marginTop: '-5px',
				},
				'& svg path': {
					fill: theme.palette.primary.main,
				},
			},
			nav: {
				position: 'relative',
				display: 'flex',
				margin: `${spacing.Gutter.Mobile * 1.5}px ${spacing.Gutter.Mobile}px ${
					spacing.Gutter.Mobile / 2
				}px ${spacing.Gutter.Mobile}px`,
			},
			navBtn: {
				flex: '0 0 25%',
				textAlign: 'center',
				fontSize: rem(25, bodyFontSize.Mobile),

				'&:before': {
					color: theme.palette.primary.main,
				},
			},
			btnSlider: {
				'&:before': {
					...fontIcon.iconCard,
				},
				'&$active:before': {
					...fontIcon.iconCardActive,
				},
			},
			btnList: {
				'&:before': {
					...fontIcon.iconList,
				},
				'&$active:before': {
					...fontIcon.iconListActive,
				},
			},
			active: {},
			btnMap: {
				'&:before': {
					...fontIcon.iconMap,
				},
			},
			btnFilters: {
				position: 'relative',
				'&:before': {
					...fontIcon.iconFilters,
				},
				'&[data-offers-count]:after': {
					content: 'attr(data-offers-count)',
					fontSize: 10,
					letterSpacing: '-0.8px',
					borderRadius: '100%',
					color: 'black',
					backgroundColor: theme.palette.primary.main,
					position: 'absolute',
					width: 21,
					height: 21,
					boxSizing: 'border-box',
					textAlign: 'center',
					lineHeight: '21px',

					top: '-10px',
					right: '50%',
					marginRight: '-21px',
				},
			},
			contentSlider: {
				overflow: 'hidden',
				padding: `${spacing.Gutter.Mobile}px 0 ${
					spacing.Gutter.Mobile * 2
				}px 0`,
			},
			contentList: {
				padding: `${spacing.Gutter.Mobile * 2}px ${spacing.Gutter.Mobile}px ${
					spacing.Gutter.Mobile
				}px ${spacing.Gutter.Mobile}px`,
			},
			mapContainer: {
				position: 'fixed',
				top: 0,
				left: 'env(safe-area-inset-left, 0)',
				bottom: 0,
				right: 'env(safe-area-inset-right, 0)',
				zIndex: 5,

				'& $logo': {
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 2,
				},
			},
			filtersContainer: {
				position: 'fixed',
				top: 'env(safe-area-inset-top, 0)',
				left: 'env(safe-area-inset-left, 0)',
				bottom: 'env(safe-area-inset-bottom, 0)',
				right: 'env(safe-area-inset-right, 0)',
				zIndex: 5,
				backgroundColor: 'white',

				'& $logo': {
					position: 'absolute',
					top: 0,
					left: 0,
					zIndex: 2,
				},
			},
			closeBtn: {},
			warningBanner: {
				[mediaQueries.Mobile]: {
					flex: '1 1 auto',
					display: 'flex',
					alignItems: 'center',
					position: 'relative',
					padding: `${spacing.Gutter.Mobile / 2}px 0`,
					overflow: 'hidden',
					backgroundColor: theme.palette.primary.main,
				},
				[mediaQueries.AfterMobile]: {
					display: 'none',
				},
			},
			warningText: {
				position: 'relative',
				flex: '0 0 auto',
				transform: 'translate3d(0, 0, 0)',
				// animation: '$warnningAnimation 30s infinite linear',
				animationName: '$warnningAnimation',
				animationTimingFunction: 'linear',
				animationIterationCount: 'infinite',
				fontSize: '1.2rem',

				'& span': {
					display: 'inline-block',
					marginRight: 20,
				},
			},
			'@keyframes warnningAnimation': warnningAnimation,
		}),
	{ name: 'HomeMobile' }
);

const OffersMap: FC = () => {
	const { offers, loading } = useOffers();
	return loading ? null : <Map offers={offers} allowMarkerClick />;
};

const HomeMobile: FC<{ scrollContainer?: HTMLElement | null }> = ({
	scrollContainer,
}) => {
	const cls = useStyles();
	const theme = useTheme();
	const { app } = useApp();
	const { count } = useOffers();
	const homeView = localStorage.getItem('homeView');
	const defaultView =
		// eslint-disable-next-line no-nested-ternary
		homeView === 'list' ? 'list' : homeView === 'slider' ? 'slider' : 'list';
	const [activeView, setActiveView] = useState<'slider' | 'list'>(defaultView);
	const [showMap, setShowMap] = useState(false);
	const [showFilters, setShowFilters] = useState(false);

	const bgImage = app && app?.Config && app?.Config?.BackgroundImage;
	// background-image: url("../../media/examples/lizard.png");

	return (
		<div
			className={cls.root}
			style={
				bgImage
					? { backgroundImage: `url(${app?.Config.BackgroundImage.Src})` }
					: undefined
			}
		>
			<Header scrollContainer={scrollContainer} variant='themeTransparent' />
			{app && app.Config.WarningActive && (
				<div className={cls.warningBanner}>
					{app.Config.WarningText && (
						<>
							<div
								style={{
									animationDuration: `${app.Config?.WarningTextTime}s` || '60s',
								}}
								className={cls.warningText}
							>
								<span>{app.Config.WarningText}</span>
								<span>{app.Config.WarningText}</span>
								<span>{app.Config.WarningText}</span>
							</div>
							<div
								style={{
									animationDuration: `${app.Config?.WarningTextTime}s` || '60s',
								}}
								className={cls.warningText}
							>
								<span>{app.Config.WarningText}</span>
								<span>{app.Config.WarningText}</span>
								<span>{app.Config.WarningText}</span>
							</div>
						</>
					)}
				</div>
			)}
			<nav className={cls.nav}>
				<button
					type='button'
					aria-label='slider view'
					onClick={() => setActiveView('slider')}
					className={classNames(
						cls.navBtn,
						cls.btnSlider,
						activeView === 'slider' && cls.active
					)}
				/>
				<button
					type='button'
					aria-label='list view'
					onClick={() => setActiveView('list')}
					className={classNames(
						cls.navBtn,
						cls.btnList,
						activeView === 'list' && cls.active
					)}
				/>
				<button
					type='button'
					aria-label='map view'
					onClick={() => setShowMap(true)}
					className={classNames(cls.navBtn, cls.btnMap)}
				/>
				<button
					type='button'
					aria-label='filters'
					onClick={() => setShowFilters(true)}
					data-offers-count={count}
					className={classNames(cls.navBtn, cls.btnFilters)}
				/>
			</nav>
			{activeView === 'slider' && (
				<section className={cls.contentSlider}>
					<MobileOfferSwipeList />
				</section>
			)}
			{activeView === 'list' && <MobileOfferList />}
			{showMap && (
				<div className={cls.mapContainer}>
					<Header
						showCloseButton={false}
						showDefaultNav={false}
						variant='themeTransparent'
					>
						<IconButton
							icon='iconClose'
							type='button'
							onClick={() => setShowMap(false)}
							className={cls.closeBtn}
							aria-label='close map view'
						/>
					</Header>
					<OffersMap />
				</div>
			)}
			{showFilters && (
				<div className={cls.filtersContainer}>
					<Header
						showCloseButton={false}
						showDefaultNav={false}
						variant='themeTransparent'
					>
						<IconButton
							icon='iconClose'
							type='button'
							onClick={() => setShowFilters(false)}
							className={cls.closeBtn}
							aria-label='hide filters'
						/>
					</Header>
					<Filters />
				</div>
			)}
			<Footer />
		</div>
	);
};

export default HomeMobile;
