import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useUsers } from 'src/@context/Users';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				minHeight: '100%',
				display: 'flex',
				flexDirection: 'column',
			},
			sectionTitle: {
				position: 'relative',
				textAlign: 'center',
				marginBottom: 5,
			},
			closeBtn: {
				position: 'absolute',
				top: spacing.Gutter.Mobile,
				lineHeight: 1,
				fontSize: rem(35, bodyFontSize.Mobile),
				right: 0,

				'&:before': {
					...fontIcon.iconClose,
				},
			},
			content: {
				flex: '1 1 auto',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',

				[mediaQueries.Mobile]: {
					padding: `0 ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `0 ${spacing.Gutter.Desktop * 2}px`,
				},
			},
			messageContainer: {
				textAlign: 'center',
			},
			icon: {
				display: 'block',
				fontSize: '5em',
				marginBottom: 10,
				'&:before': { ...fontIcon.iconSuccess },
			},
			message: {
				fontSize: rem(24, bodyFontSize.Mobile),
			},
			btns: {
				display: 'block',
				padding: spacing.Gutter.Desktop,
				textAlign: 'center',
				fontSize: rem(24, bodyFontSize.Mobile),
				border: 'none',
				borderRadius: spacing.BoderRadius.Big,
				marginBottom: spacing.Gutter.Desktop,
				backgroundColor: 'black',
				color: theme.palette.primary.main,
			},
		}),
	{ name: 'ThanksPage' }
);

const Thanks: FC = () => {
	const cls = useStyles();
	const history = useHistory();
	const { current } = useUsers();

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<div className={cls.content}>
					<div className={cls.messageContainer}>
						<div className={cls.icon} />
						<div className={cls.message}>
							Cool, Danke! Willkommen in deinem Profil.
						</div>
					</div>
					{current && (
						<Link to='/profil' className={cls.btns}>
							Profil anzeigen
						</Link>
					)}
				</div>
			</div>
		</PageLayout>
	);
};

export default Thanks;
