import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import ModalPageLayout from './ModalPageLayout';
import fontStyles from '../constants/fontStyles';
import Button from './Button';
import GlobeIcon from './MonkeySvgs/GlobeIcon';
import { useUsers } from '../@context/Users';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				display: 'flex',
				flexDirection: 'column',

				backgroundColor: 'rgba(0, 0, 0, 0.8)',
			},
			header: {
				flex: '0 0 auto',
			},
			centerContainer: {},
			content: {
				flex: '1 1 auto',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',

				[mediaQueries.Mobile]: {
					padding: `0 ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `0 ${spacing.Gutter.Desktop * 2}px`,
				},
			},
			messageContainer: {
				textAlign: 'center',
				'& svg': {
					width: 200,
					height: 200,
				},
			},
			svgColor: {
				fill: theme.palette.primary.main,
			},
			message: {
				...fontStyles.largeText,
				color: theme.palette.primary.main,
				marginTop: 10,
			},
			submitButton: {
				marginTop: spacing.Gutter.Mobile,
			},
		}),
	{ name: 'SubscriptionExpiredScreen' }
);

const SubscriptionExpiredScreen: FC<{
	user: UseraccountsDto;
	userBlocked: boolean;
}> = ({ user, userBlocked }) => {
	const cls = useStyles();
	const { signOut } = useUsers();
	const [message, setMessage] = useState('');
	const [canSubscribe, setCanSubscribe] = useState(false);

	useEffect(() => {
		if (userBlocked) {
			return;
		}

		switch (user.EpaymentType) {
			case 'PayPal': // TEPaymentProvider.PayPal: **not working**
			case 'PostFinance': // TEPaymentProvider.PostFinance: **not working**
			case 'None': // TEPaymentProvider.None: **not working**
				setMessage(
					'Mitgliedschaft noch nicht aktiv. Wir warten auf Bestätigung des Zahlungsanbieters.'
				);
				setCanSubscribe(true);
				break;

			default:
				if (user.CalculatedAge >= 26) {
					setMessage('Sorry, du bist jetzt leider zu alt für den colourkey.');
					setCanSubscribe(true);
					break;
				}
				setMessage(
					'Sorry, deine Mitgliedschaft ist abgelaufen oder noch nicht aktiv.'
				);
				setCanSubscribe(true);
				break;
		}
	}, [user, userBlocked]);

	return (
		<ModalPageLayout fullWidth={false} className={cls.root}>
			<div className={cls.centerContainer}>
				<div className={cls.content}>
					{!userBlocked && (
						<>
							<div className={cls.messageContainer}>
								<GlobeIcon className={cls.svgColor} />
								<div className={cls.message}>{message}</div>
							</div>
							{canSubscribe && (
								<Button
									as='link'
									to={`/pay/${user.UserName.trim()}`}
									variant='formSubmitButtonInverted'
									className={cls.submitButton}
								>
									Mitgliedschaft bezahlen
								</Button>
							)}
						</>
					)}
					{userBlocked && (
						<div className={cls.messageContainer}>
							<GlobeIcon className={cls.svgColor} />
							<div className={cls.message}>
								<p>Dein Online-Profil wurde vorübergehend gesperrt.</p>
								<p>
									Bitte setze dich mit uns via
									<a href='mailto:info@colourkey.ch'>info@colourkey.ch</a>
									in Kontakt.
								</p>
								<p>Danke!</p>
							</div>
						</div>
					)}
					<Button
						variant='formSubmitButtonInverted'
						type='button'
						data-sign-out
						onClick={() => signOut()}
					>
						Sign Out
					</Button>
				</div>
			</div>
		</ModalPageLayout>
	);
};

export default SubscriptionExpiredScreen;
