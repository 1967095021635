import React, { FC } from 'react';
import { useTheme } from '@material-ui/core';
import IconButton, { IconButtonProps } from './IconButton';

const ModalCloseButton: FC<IconButtonProps> = ({ className, ...rest }) => {
	const theme = useTheme();

	return (
		<IconButton className={className} {...rest}>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='90'
				height='90'
				fill='none'
				viewBox='0 0 90 90'
			>
				<circle
					cx='45'
					cy='45'
					r='43'
					fill='#fff'
					stroke={theme.palette.primary.main}
					strokeWidth='4'
				/>
				<path
					fill={theme.palette.primary.main}
					d='M65.685 27.46a2.01 2.01 0 10-2.782-2.9L44.62 42.1 26.402 24.567a2.01 2.01 0 00-2.787 2.895l18.102 17.422-18.1 17.365a2.01 2.01 0 002.783 2.9l18.215-17.476L63.545 65.89a2.01 2.01 0 102.787-2.895L47.518 44.889l18.167-17.43z'
				/>
			</svg>
		</IconButton>
	);
};

export default ModalCloseButton;
