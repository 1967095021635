import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC } from 'react';
import MenuToggler from 'src/components/MenuToggler';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import { useForm } from 'react-hook-form';
import Header from 'src/components/Header';
import FormStyles from 'src/components/Form/FormStyles';
import InputText from 'src/components/Form/InputText';
import InputPassword from 'src/components/Form/InputPassword';
import { useUsers } from 'src/@context/Users';
import { Link, useHistory } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { useApp } from 'src/@context/App';
import IconButton from '../components/IconButton';
import Button from '../components/Button';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100%',
		},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
		forgotLink: {
			textDecoration: 'underline',
		},
	})
);

const SignIn: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const history = useHistory();
	const { setMessageScreen } = useApp();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<SignInBinding>();
	const { signIn, loading } = useUsers();

	const onSubmit = async (data: SignInBinding) => {
		const { error, data: user } = await signIn({ ...data, Remember: true });

		if (error) {
			setMessageScreen({
				type: 'Error',
				message: error.Message || 'error while signin, try again',
			});
		}

		if (user) {
			history.push(`/`);
		}
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<article className={formCls.modal}>
					<h4 className={classNames(formCls.title, formCls.centered)}>Login</h4>
					<form onSubmit={handleSubmit(onSubmit)}>
						<InputText
							error={errors.Email}
							label='E-mail'
							register={register('Email', { required: true })}
						/>
						<InputPassword
							error={errors.Password}
							label='Passwort'
							register={register('Password', { required: true })}
						/>
						<Button variant='formSubmitButton' type='submit'>
							Weiter
						</Button>
					</form>
					<div className={formCls.subFormLinks}>
						<Link to='/passwort-wiederherstellen'>Passwort vergessen</Link>
					</div>
				</article>
			</div>
		</PageLayout>
	);
};

export default SignIn;
