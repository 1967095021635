/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import fontStyles from '../constants/fontStyles';
import FormStyles from './Form/FormStyles';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			variantDefault: {
				display: 'inline-flex',
				alignItems: 'center',
				borderRadius: 8,
				backgroundColor: 'white',
				border: '3px solid #d1d1d1',
				height: 56,
				paddingLeft: 14,
				paddingRight: 14,
				...fontStyles.buttonText,
				'&:disabled': {
					color: '#D1D1D1',
				},
			},
			variantFormSubmitButtonInverted: {
				backgroundColor: theme.palette.primary.main,
				color: 'black',
			},
		}),
	{
		name: 'Button',
	}
);

type ButtonBaseProps = {
	variant?: 'default' | 'formSubmitButton' | 'formSubmitButtonInverted';
	children: React.ReactNode;
};

type ButtonAsButtonProps = ButtonBaseProps &
	Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonBaseProps> & {
		as?: 'button';
	};

type ButtonAsLinkProps = ButtonBaseProps &
	Omit<LinkProps, keyof ButtonBaseProps> & {
		as: 'link';
	};

type ButtonProps = ButtonAsButtonProps | ButtonAsLinkProps;

const Button: FC<ButtonProps> = (commonProps) => {
	const cls = useStyles();
	const { children, variant = 'default', className, ...props } = commonProps;
	const formCls = FormStyles();

	const finalClassName = classNames(
		variant === 'default' && cls.variantDefault,
		variant === 'formSubmitButton' && formCls.submit,
		variant === 'formSubmitButtonInverted' && [
			formCls.submit,
			cls.variantFormSubmitButtonInverted,
		],
		className
	);

	if (props.as === 'link') {
		const { ...rest } = props;

		return (
			<Link type='button' className={finalClassName} {...rest}>
				{children}
			</Link>
		);
	}

	const { ...rest } = props;

	return (
		<button type='button' className={finalClassName} {...rest}>
			{children}
		</button>
	);
};

export default Button;
