import React, { FC, useCallback, useRef, useState } from 'react';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import { useOffers } from 'src/@context/Offers';
import { useApp } from 'src/@context/App';
import classNames from 'classnames';
import { FieldError, useForm } from 'react-hook-form';
import FormStyles from 'src/components/Form/FormStyles';
import InputFile from 'src/components/Form/InputFile';
import InputKantonDropdown from 'src/components/Form/InputKantonDropdown';
import InputSelect from 'src/components/Form/InputSelect';
import InputText from 'src/components/Form/InputText';
import { countryOptions } from 'src/components/Form/InputValues';
import InputCategories from 'src/components/Form/InputCategories';
import InputCheckbox from 'src/components/Form/InputCheckbox';
import InputEmail from 'src/components/Form/InputEmail';
import LoadingScreen from 'src/components/LoadingScreen';
import Textarea from '../components/Form/Textarea';
import InputAddressAutocomplete from '../components/Form/InputAddressAutocomplete';
import googlePlaceToAddress from '../utils/googlePlaceToAddress';
import Button from '../components/Button';
import { optionalUrl } from '../utils/validation';

export type OfferCreateDto = Partial<
	OfferPostBinding & {
		AcceptTerms: boolean;
	}
>;

const OfferCreatePage: FC = () => {
	const formCls = FormStyles();
	const form = useRef<HTMLFormElement>(null);
	const { app } = useApp();
	const { setMessageScreen } = useApp();
	const { post, postFormData, loading } = useOffers();
	const [posting, setPosting] = useState(false);

	const defaultValues: OfferCreateDto = {
		Logo: '',
		Picture: '',
		Title: '',
		PlaceText: '',
		Hashtags: '',
		Text: '',
		CompanyName: '',
		Address: '',
		COAddress: '',
		ZipCode: '',
		City: '',
		Province: '',
		Country: '',
		PhoneNumber: '',
		Website: '',
		Email: '',
		Instagram: '',
		OfferSubCategories: [],
		PartnerFirstName: '',
		PartnerLastName: '',
		PartnerPhoneNumber: '',
		PartnerEmail: '',
		Lat: '',
		Lng: '',
		PaidPublicity: false,
		AddToNewsletter: true,
		AcceptTerms: false,
		IsSustainabilityQuestionnaireFilled: false,
	};

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<OfferCreateDto>({
		defaultValues,
	});

	const onSubmitTest = (data: OfferCreateDto) => {
		const { current } = form;

		if (!current) {
			return;
		}

		const formData = new FormData();
		// eslint-disable-next-line no-restricted-syntax
		for (const key in data) {
			if (key === 'Picture') {
				formData.append(key, data.Picture[0]);
			} else if (key === 'Logo') {
				formData.append(key, data.Logo[0]);
			} else {
				formData.append(key, data[key as keyof typeof data]);
			}
		}

		console.log([...formData.entries()]);
	};

	const onSubmit = async (data: OfferCreateDto) => {
		const { current } = form;

		if (!current) {
			return;
		}

		const formData = new FormData();
		// eslint-disable-next-line no-restricted-syntax
		for (const key in data) {
			if (key === 'Picture') {
				formData.append(key, data.Picture[0]);
			} else if (key === 'Logo') {
				formData.append(key, data.Logo[0]);
			} else {
				formData.append(key, data[key as keyof typeof data]);
			}
		}

		setPosting(true);
		const { data: success, error } = await postFormData(formData);

		if (error) {
			setMessageScreen({
				type: 'Error',
				message: error.Message || 'error while signin, try again',
			});

			setPosting(false);
			return;
		}

		if (success) {
			setMessageScreen({
				type: 'Success',
				message: 'Danke, dein Angebot wurde eingereicht.',
			});

			setPosting(false);
		}
	};

	const onSelectAddress = useCallback(
		(place: google.maps.places.PlaceResult) => {
			const { lat, lng, address, countryCode, province, city, zipCode } =
				googlePlaceToAddress(place);

			setValue('Lat', `${lat}` || '');
			setValue('Lng', `${lng}` || '');
			setValue('Address', address || '');
			setValue('Country', countryCode || '');
			setValue('Province', province || '');
			setValue('City', city || '');
			setValue('ZipCode', zipCode || '');
		},
		[setValue]
	);

	if (loading || posting) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<Header />

			{app && app.Config && (
				<article className={formCls.modal}>
					{app.Config.OfferRegistrationPageTitle && (
						<h2 className={classNames(formCls.title, formCls.centered)}>
							{app.Config.OfferRegistrationPageTitle}
						</h2>
					)}
					{app.Config.OfferRegistrationPageText && (
						<div
							className={classNames(formCls.littleText, formCls.centered)}
							// eslint-disable-next-line react/no-danger
							dangerouslySetInnerHTML={{
								__html: app.Config.OfferRegistrationPageText,
							}}
						/>
					)}
					<form onSubmit={handleSubmit(onSubmit)} ref={form}>
						<div className={formCls.section}>
							<InputFile
								error={errors.Picture}
								label='Picture'
								register={register('Picture', { required: 'Bitte ausfüllen' })}
							/>
							<InputText
								error={errors.Title}
								label='Titel'
								register={register('Title', { required: 'Bitte ausfüllen' })}
							/>
							<Textarea
								control={control}
								label='Beschreibung Angebot'
								name='PlaceText'
								initialValue=''
								setFieldValue={setValue}
								rules={{ required: 'Bitte ausfüllen' }}
							/>
							<InputText
								error={errors.Hashtags}
								label='#Hashtags (Bsp.: #hashtag1 #hashtag2 …)'
								register={register('Hashtags', {
									required: false,
									pattern: {
										value:
											/^#(?![0-9_]+\b)([A-Za-z0-9_])+(\s+#(?![0-9_]+)([A-Za-z0-9])+)*$/,
										message: 'Bsp.: #hashtag1 #hashtag2 …',
									},
								})}
							/>
							<Textarea
								control={control}
								label='Aktions-Text'
								name='Text'
								initialValue=''
								setFieldValue={setValue}
								rules={{ required: 'Bitte ausfüllen' }}
							/>
							<InputText
								error={errors.CompanyName}
								label='Firmenname'
								register={register('CompanyName')}
							/>
						</div>
						<div className={formCls.section}>
							<InputAddressAutocomplete
								error={errors.Address}
								label='Adresse'
								onSelectAddress={onSelectAddress}
								register={register('Address', { required: 'Bitte ausfüllen' })}
							/>
							<InputText
								error={errors.COAddress}
								label='c/o Adresse'
								register={register('COAddress')}
							/>
							<InputText
								error={errors.ZipCode}
								label='Postleitzahl'
								register={register('ZipCode', { required: 'Bitte ausfüllen' })}
							/>
							<InputText
								error={errors.City}
								label='Ort'
								register={register('City', { required: 'Bitte ausfüllen' })}
							/>
							<InputKantonDropdown
								error={errors.Province}
								label='Kanton'
								register={register('Province', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputSelect
								error={errors.Country}
								label='Land'
								options={countryOptions}
								register={register('Country', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputText
								error={errors.PhoneNumber}
								label='Telefon'
								register={register('PhoneNumber', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputText
								error={errors.Website}
								label='Website'
								register={register('Website', {
									validate: optionalUrl,
								})}
							/>
							<InputEmail
								error={errors.Email}
								label='E-Mail'
								register={register('Email', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputText
								error={errors.Instagram}
								label='Instagram (Bsp.: https://www.instagram.com/colourkey)'
								register={register('Instagram', {
									validate: optionalUrl,
								})}
							/>
							<InputText
								error={errors.Lat}
								label='Breitengrad (für Pin auf Karte), optional'
								register={register('Lat', {
									required: 'Bitte ausfüllen',
									pattern: {
										value: /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/,
										message: 'Entered value does not match longotude format',
									},
								})}
							/>
							<InputText
								error={errors.Lng}
								label='Längengrad (für Pin auf Karte), optional'
								register={register('Lng', {
									required: 'Bitte ausfüllen',
									pattern: {
										value:
											/(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)/,
										message: 'Entered value does not match longotude format',
									},
								})}
							/>
						</div>
						{app.Config && app.Config.Categories && (
							<div className={formCls.section}>
								<InputCategories
									error={errors.OfferSubCategories as FieldError | undefined}
									label='RUBRIKEN'
									register={register('OfferSubCategories', {
										required: 'Must choose at least one category',
									})}
								/>
							</div>
						)}
						<div className={formCls.section}>
							<InputText
								error={errors.PartnerFirstName}
								label='Kontaktperson Vorname'
								register={register('PartnerFirstName', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputText
								error={errors.PartnerLastName}
								label='Kontaktperson Name'
								register={register('PartnerLastName', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputText
								error={errors.PartnerPhoneNumber}
								label='Kontaktperson Telefonnummer'
								register={register('PartnerPhoneNumber', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputEmail
								error={errors.PartnerEmail}
								label='Kontaktperson E-mail'
								register={register('PartnerEmail', {
									required: 'Bitte ausfüllen',
								})}
							/>
							<InputFile
								accept='.ai,.eps'
								error={errors.Logo}
								label='Logo (.ai or .eps)'
								register={register('Logo')}
							/>
						</div>
						<div className={formCls.section}>
							<InputCheckbox
								id='sustainability-questionnaire-filled'
								register={register('IsSustainabilityQuestionnaireFilled', {
									required: 'Bitte ausfüllen',
								})}
								error={errors.IsSustainabilityQuestionnaireFilled}
								defaultChecked={
									defaultValues.IsSustainabilityQuestionnaireFilled
								}
							>
								<span
									dangerouslySetInnerHTML={{
										__html: `Ich habe diesen <a 
												style="text-decoration: underline;" href="https://docs.google.com/forms/d/1vUxXc05GQ0kjtY_oMmSC7AJ43JXxca0yruG7b09CcwE/edit" target="_blank" rel="noopener noreferrer">Fragebogen zur Nachhaltigkeit</a> unseres Unternehmens vollständig ausgefüllt.`,
									}}
								/>
							</InputCheckbox>
							<InputCheckbox
								id='add-ot-newsletter'
								label='Ich möchte News per E-Mail erhalten'
								register={register('AddToNewsletter')}
								error={errors.AddToNewsletter}
								defaultChecked={defaultValues.AddToNewsletter}
							/>
							<InputCheckbox
								id='paid-pub'
								label='Ich interessiere mich für Inserate-Schaltungen'
								register={register('PaidPublicity')}
								error={errors.PaidPublicity}
								defaultChecked={defaultValues.PaidPublicity}
							/>
							<InputCheckbox
								id='accept-terms'
								register={register('AcceptTerms', {
									required: 'Bitte ausfüllen',
								})}
								error={errors.AcceptTerms}
								defaultChecked={defaultValues.AcceptTerms}
							>
								<span
									dangerouslySetInnerHTML={{
										__html: `Ich erkläre mich mit der <a 
												style="text-decoration: underline;" href=${
													app?.Config?.PartnerAgreementPdf?.Src || ''
												} target="_blank">Partnervereinbarung</a> einverstanden und bestätige hiermit die Partnerschaft mit colourkey`,
									}}
								/>
							</InputCheckbox>
						</div>
						<div className={formCls.section}>
							Damit alle colourkey-Besitzer*innen von Ihren Angeboten
							profitieren können, schicken wir Ihnen unser Promomaterial. Bitte
							platzieren Sie dieses sinnig in Ihrem Geschäft.
						</div>
						<Button variant='formSubmitButton' type='submit'>
							Weiter
						</Button>
					</form>
				</article>
			)}
		</PageLayout>
	);
};

export default OfferCreatePage;
