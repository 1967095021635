import * as React from 'react';
import {
	useState,
	createContext,
	useCallback,
	useEffect,
	useContext,
} from 'react';
import { useApi } from './Api';

type Users = {
	current?: UseraccountsDto;
	admin: boolean;
	signIn: (bindings: SignInBinding) => Promise<ApiResponse<UseraccountsDto>>;
	signUp: (bindings: HTMLFormElement) => Promise<ApiResponse<UseraccountsDto>>;
	gift: (bindings: GiftBinding) => Promise<ApiResponse<{ UserName: string }>>;
	signOut: () => Promise<void>;
	loading: boolean;
};

export const UsersContext = createContext<Users>({} as never);

export const Users: React.FC = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [current, setCurrent] = useState<UseraccountsDto>();
	const [admin, setAdmin] = useState<boolean>(false);

	const { get, post, form } = useApi();
	const signIn = async (bindings: SignInBinding) => {
		setLoading(true);
		const { data, error } = await post<UseraccountsDto>(
			'/2.0/account/signin',
			bindings
		);

		setCurrent(data);
		setLoading(false);
		return { data, error };
	};

	const signUp = async (bindings: HTMLFormElement) => {
		setLoading(true);
		const { data, error } = await form<UseraccountsDto>(
			'/2.0/account/signup/post',
			bindings,
			'POST'
		);
		setCurrent(data);
		setLoading(false);
		return { data, error };
	};

	const gift = async (bindings: GiftBinding) => {
		setLoading(true);
		const { data, error } = await post<{ UserName: string }>(
			'/2.0/account/gift',
			bindings
		);

		setLoading(false);
		return { data, error };
	};

	const signOut = async () => {
		setLoading(true);
		await post('/2.0/account/signout', {});
		setLoading(false);
		setCurrent(undefined);
	};

	const me = useCallback(async () => {
		const { data, error } = await get<UseraccountsDto>('/2.0/account/me');
		setCurrent(data);
		return { data, error };
	}, [get]);

	useEffect(() => {
		setAdmin(current?.IsAdmin || false);
	}, [current]);

	useEffect(() => {
		me();
	}, [me]);

	return (
		<UsersContext.Provider
			value={{ admin, signIn, signUp, signOut, gift, current, loading }}
		>
			{children}
		</UsersContext.Provider>
	);
};

export const useUsers = () => useContext(UsersContext);
