import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import MenuToggler from 'src/components/MenuToggler';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import { useForm } from 'react-hook-form';
import Header from 'src/components/Header';
import FormStyles from 'src/components/Form/FormStyles';
import InputText from 'src/components/Form/InputText';
import { useHistory } from 'react-router-dom';
import spacing from 'src/constants/spacing';
import { useApi } from 'src/@context/Api';
import { useApp } from 'src/@context/App';
import LoadingScreen from 'src/components/LoadingScreen';
import IconButton from '../components/IconButton';
import Button from '../components/Button';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100%',
		},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
		forgotLink: {
			textDecoration: 'underline',
		},
		helpText: {
			margin: `${spacing.Gutter.Mobile / 2}px 0 ${spacing.Gutter.Mobile}px 0`,

			'& a': {
				textDecoration: 'underline',
			},
		},
	})
);

const ForgotPasswordPage: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const history = useHistory();
	const { post, loading } = useApi();
	const { setMessageScreen } = useApp();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgotBinding>();
	const onSubmit = async (data: ForgotBinding) => {
		const newData: ForgotBinding = {
			...data,
			RedirectUrl: `${location.origin}/passwort-zurucksetzen`,
		};

		const { data: success, error } = await post<
			ApiResponse<{ Message: string }>
		>('/2.0/account/forgot', newData);

		if (error) {
			setMessageScreen({
				type: 'Error',
				message: error?.Message || `${error.status} - Try again later`,
			});
			return;
		}

		setMessageScreen({
			type: 'Success',
			message: success?.data?.Message || 'Aye, aye! Email geschickt!',
		});
	};

	if (loading) {
		<LoadingScreen />;
	}

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<article className={formCls.modal}>
					<h4 className={classNames(formCls.title, formCls.centered)}>
						Passwort wiederherstellen
					</h4>
					<form onSubmit={handleSubmit(onSubmit)}>
						<InputText
							error={errors.Id}
							label='colourkey ID oder Mail'
							register={register('Id', { required: true })}
						/>
						<div className={cls.helpText}>
							Du kennst Deine colourkey ID oder Mailadresse nicht? Kein Problem!
							Sende uns einfach eine Email an{' '}
							<a href='mailto:ausweis@colourkey.ch'>ausweis@colourkey.ch</a> und
							wir helfen Dir weiter!
						</div>
						<Button variant='formSubmitButton' type='submit'>
							Wiederherstellen
						</Button>
					</form>
				</article>
			</div>
		</PageLayout>
	);
};

export default ForgotPasswordPage;
