import React, { useEffect } from 'react';
import { usePlatformMobile } from '../@context/PlatformMobileContext';

function PlausibleAnalytics() {
	const platformMobile = usePlatformMobile();
	useEffect(() => {
		const script = document.createElement('script');
		script.defer = true;
		script.dataset.eventPlatform = platformMobile.isActive ? 'mobile' : 'web';
		script.dataset.domain = 'colourkey.ch';
		script.src = platformMobile.isActive
			? 'https://plausible.io/js/script.local.pageview-props.js' // Allow tracking for localhost for mobile
			: 'https://plausible.io/js/script.pageview-props.js';
		document.body.appendChild(script);
	}, [platformMobile]);

	return null;
}

export default PlausibleAnalytics;
