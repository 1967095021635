export default {
	iconClose: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E802"',
	},
	iconArrowTop: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E87D"',
	},
	iconArrowLeft: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E800"',
	},
	iconArrowRight: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E806"',
	},
	iconFilters: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E805"',
	},
	iconMusic: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E812"',
	},
	iconMusicBold: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E811"',
	},
	iconSport: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E818"',
	},
	iconSportBold: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E817"',
	},
	iconMuseum: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E808"',
	},
	iconMuseumBold: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E807"',
	},
	iconTheater: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E831"',
	},
	iconTheaterBold: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E86B"',
	},
	iconShoppping: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E816"',
	},
	iconShoppingBold: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E815"',
	},
	iconOthers: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E804"',
	},
	iconOthersBold: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E803"',
	},
	iconResetFilters: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E819"',
	},
	iconProfile: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E813"',
	},
	iconFacebook: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E230"',
	},
	iconFooterFacebook: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E81A"',
	},
	iconFooterInstagram: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\F16D"',
	},
	iconFooterWhatsApp: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\F232"',
	},
	iconPostfinance: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E846"',
	},
	iconTwint: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E844"',
	},
	iconPaypal: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E845"',
	},
	iconError: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E880"',
	},
	iconSuccess: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E881"',
	},
	iconCard: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E801"',
	},
	iconCardActive: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E820"',
	},
	iconList: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E80B"',
	},
	iconListActive: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E821"',
	},
	iconMap: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E80D"',
	},
	iconSleepingMonkey: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E834"',
	},
	iconMonkeyAngry: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E82D"',
	},
	iconMonkeyBored: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E82E"',
	},
	iconHeart: {
		fontFamily: 'icons',
		lineHeight: 1,
		content: '"\\E833"',
	},
};
