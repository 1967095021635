/* eslint-disable no-restricted-syntax */
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormStyles from 'src/components/Form/FormStyles';
import Header from 'src/components/Header';
import MenuToggler from 'src/components/MenuToggler';
import PageLayout from 'src/components/PageLayout';
import useQuery from 'src/utils/useQuery';
import { useApp } from 'src/@context/App';
import TouchRegisterForm from '../components/TouchRegisterForm';
import RegisterForm from '../components/RegisterForm';
import IconButton from '../components/IconButton';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100%',
		},
	})
);

const Register: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const history = useHistory();
	const { setMessageScreen } = useApp();

	const query = useQuery();
	const id = query.get('id');
	const token = query.get('token');

	const [touchRegister, setTouchRegister] = useState<TokenTouchResponse>();

	// if (loading) {
	// 	return <LoadingScreen />;
	// }

	const hanldleError = (error: {
		Message?: string | undefined;
		status: number;
	}) =>
		setMessageScreen({
			type: 'Error',
			message: error?.Message || `${error.status} - Please try later`,
		});

	const handleTouch = (result: ApiResponse<TokenTouchResponse>) => {
		const { data, error } = result;

		if (error && !data) {
			hanldleError(error);
			return;
		}

		setTouchRegister(data);
	};

	const handleRegister = (result: ApiResponse<UseraccountsDto>) => {
		const { data, error } = result;

		if (error && !data) {
			hanldleError(error);
			return;
		}

		history.push(`/thanks/${data?.UserName}`);
	};

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<article className={formCls.modal}>
					{!touchRegister && (
						<TouchRegisterForm
							id={id || ''}
							token={token || ''}
							handleResult={(data) => handleTouch(data)}
						/>
					)}
					{touchRegister && (
						<RegisterForm
							id={id || ''}
							token={token || ''}
							email={touchRegister.Email}
							handleResult={(data) => handleRegister(data)}
						/>
					)}
				</article>
			</div>
		</PageLayout>
	);
};

export default Register;
