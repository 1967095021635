import React, { FC, useState } from 'react';
import { useApp } from '../@context/App';
import LargeIconButton from './LargeIconButton';

interface OfferSavePdfButtonProps {
	offer: OfferAdminDto;
}

const OfferSavePdfButton: FC<OfferSavePdfButtonProps> = ({ offer }) => {
	const [loading, setLoading] = useState(false);
	const { app } = useApp();

	const onClick = async () => {
		if (loading) {
			return;
		}

		setLoading(true);

		try {
			await import('../utils/SaveOfferPdf').then(({ default: SaveOfferPdf }) =>
				SaveOfferPdf({ app, offer })
			);
		} catch (err) {
			console.error(err);
			alert('An error occurred downloading the PDf.');
		}

		setLoading(false);
	};

	return (
		<LargeIconButton
			color='#2C80BF'
			icon={
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='126'
					height='126'
					fill='none'
					viewBox='0 0 126 126'
				>
					<path
						fill='#2C80BF'
						d='M64.952 81.254L87.39 58.88c.423-.542.67-1.2.708-1.887a2.08 2.08 0 00-.708-1.853 2.692 2.692 0 00-1.82-.708H73.712V35.967a2.854 2.854 0 00-.842-1.82 2.478 2.478 0 00-1.853-.809h-15.77a2.989 2.989 0 00-1.853.842 2.364 2.364 0 00-.842 1.786v18.4H40.69a2.421 2.421 0 00-1.82.775 2.63 2.63 0 00-.741 1.887 2.513 2.513 0 00.741 1.853l22.441 22.374a2.589 2.589 0 001.887.809 2.174 2.174 0 001.754-.81zm28.843 8.962a3.98 3.98 0 01-1.146 2.763 3.9 3.9 0 01-2.7 1.146h-54.25a3.909 3.909 0 010-7.817h54.25a3.9 3.9 0 012.7 1.146 3.978 3.978 0 011.146 2.762z'
					/>
				</svg>
			}
			type='button'
			onClick={onClick}
		>
			{loading ? 'Downloading...' : 'PDF'}
		</LargeIconButton>
	);
};

export default OfferSavePdfButton;
