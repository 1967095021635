import { createMuiTheme } from '@material-ui/core/styles';

const YellowTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#FAB42C',
			light: '#F9DFAF',
			contrastText: '#000',
		},
		text: {
			primary: '#FAB42C',
		},
		error: {
			main: '#fff',
		},
	},
});

export default YellowTheme;
