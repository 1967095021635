import { useEffect, useState } from 'react';

function usePortalElement() {
	const [portalElement, setPortalElement] = useState<HTMLDivElement | null>(
		null
	);

	useEffect(() => {
		const portalElement = document.createElement('div');
		document.body.appendChild(portalElement);
		setPortalElement(portalElement);

		return () => {
			document.body.removeChild(portalElement);
		};
	}, []);

	return portalElement;
}

export default usePortalElement;
