import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'src/@context/App';
import IconButton, { IconButtonProps } from './IconButton';
import ModalCloseButton from './ModalCloseButton';

const useStyles = makeStyles(
	() => ({
		root: {
			position: 'absolute',
			top: 14,
			right: 0,
			zIndex: 2,
		},
	}),
	{ name: 'MenuToggler' }
);

type MenuTogglerProps = {
	variant?: IconButtonProps['variant'];
	className?: string;
};

const MenuToggler: FC<MenuTogglerProps> = ({ variant, className }) => {
	const cls = useStyles();
	const { toggleMenu, menuOpened } = useApp();

	return (
		<IconButton
			variant={variant}
			onClick={() => toggleMenu(!menuOpened)}
			type='button'
			className={className ?? cls.root}
			aria-label='Menu toggler'
			data-behavior='menu-open'
		>
			{menuOpened ? (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='90'
					height='90'
					fill='none'
					viewBox='0 0 90 90'
				>
					<path
						fill='currentColor'
						d='M65.685 27.46a2.01 2.01 0 10-2.782-2.9L44.62 42.1 26.402 24.567a2.01 2.01 0 00-2.787 2.895l18.102 17.422-18.1 17.365a2.01 2.01 0 002.783 2.9l18.215-17.476L63.545 65.89a2.01 2.01 0 102.787-2.895L47.518 44.889l18.167-17.43z'
					/>
				</svg>
			) : (
				<svg
					width={39}
					height={25}
					viewBox='0 0 39 25'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M1.5 1.5h36M1.5 12.5h36M1.5 23.5h36'
						stroke='currentColor'
						strokeWidth={3}
						strokeLinecap='round'
					/>
				</svg>
			)}
		</IconButton>
	);
};

export default MenuToggler;
