import { makeStyles, createStyles } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useApp } from 'src/@context/App';
import { useUsers } from 'src/@context/Users';
import FormStyles from './FormStyles';
import InputCheckbox from './InputCheckbox';

const useStyles = makeStyles(
	() =>
		createStyles({
			categorySection: {
				marginTop: 10,
				marginBottom: 10,
			},
			mainTitle: {
				textAlign: 'center',
			},
			categoryTitle: {
				marginBottom: 5,
			},
			subCategoriesContainer: {
				display: 'flex',
				flexWrap: 'wrap',
				marginTop: 5,
			},
			subCategory: {
				flex: `0 0 ${100 / 3}%`,
				'@media screen and (max-width: 1600px)': {
					flex: `0 0 ${100 / 2}%`,
				},
				'@media screen and (max-width: 600px)': {
					flex: `0 0 ${100}%`,
				},
			},
		}),
	{ name: 'InputCategories' }
);

type IMongoDBef = {
	CollectionName: string;
	DatabaseName: string;
	Id: string;
};
type InputCategoriesProps = {
	register: any;
	label?: string;
	error: FieldError | undefined;
	value?: IMongoDBef[];
};

const InputCategories: FC<InputCategoriesProps> = ({
	register,
	label,
	error,
	children,
	value,
}) => {
	const cls = useStyles();
	const formCls = FormStyles();
	const { app } = useApp();
	const [activeCats, setActiveCats] = useState<string[]>([]);
	const { admin } = useUsers();

	return (
		<div className={formCls.inputContainer}>
			{label && (
				<div className={classNames(formCls.label, cls.mainTitle)}>{label}</div>
			)}
			{app?.Config.Categories.map((c) => (
				<div key={`${c.UID}`} className={cls.categorySection}>
					{c.Name}
					{!!c.SubCategories.length && (
						<div className={cls.subCategoriesContainer}>
							{c.SubCategories.map((s) => (
								<div className={cls.subCategory} key={`${s.UID}`}>
									<InputCheckbox
										id={`${s.UID}`}
										register={register}
										disabled={
											admin
												? false
												: activeCats.length > 0 &&
												  !c.SubCategories.some((r) =>
														activeCats.includes(r.UID)
												  )
										}
										label={s.Name}
										value={s.UID}
										error={!!error}
										callback={() => {
											if (activeCats?.includes(s.UID)) {
												setActiveCats(activeCats.filter((r) => r !== s.UID));
											} else {
												setActiveCats([...activeCats, s.UID]);
											}
										}}
										defaultChecked={
											!!value?.find(({ Id }) => Id === s.ObjectId)
										}
									/>
								</div>
							))}
						</div>
					)}
				</div>
			))}
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</div>
	);
};

export default InputCategories;
