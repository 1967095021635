/* eslint-disable import/prefer-default-export */
export function optionalUrl(value: any): boolean | string {
	if (!value || typeof value !== 'string') {
		return true;
	}

	// Check if the value is a valid URL
	const isValidUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
	return isValidUrl || 'Bitte eine gültige URL eingeben';
}
