import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				display: 'block',
				backgroundColor: 'white',
				borderRadius: 5,
				minWidth: 110,
			},
			icon: {
				'& svg': {
					width: 'auto',
					height: 70,
				},
			},
			value: {
				fontWeight: 'bold',
				fontSize: rem(32, bodyFontSize.Mobile),
				letterSpacing: '0.01em',
				lineHeight: 0.866,
				marginTop: 4,
			},
			label: {
				marginTop: 4,
				fontSize: rem(16, bodyFontSize.Mobile),
				fontWeight: 'bold',
				letterSpacing: '0.01em',
				textTransform: 'uppercase',
			},
		}),
	{
		name: 'BigIconWithText',
	}
);

interface BigIconWithTextProps {
	icon: React.ReactNode;
	value: string;
	label: string;
	color?: string;
}
const BigIconWithText: FC<BigIconWithTextProps> = ({
	icon,
	value,
	label,
	color = 'black',
}) => {
	const cls = useStyles();

	return (
		<div className={cls.root}>
			<div className={cls.icon}>{icon}</div>
			<div className={cls.value}>{value}</div>
			<div className={cls.label} style={{ color }}>
				{label}
			</div>
		</div>
	);
};

export default BigIconWithText;
