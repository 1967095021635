import { createMuiTheme } from '@material-ui/core/styles';

const RedTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#E7675E',
			light: '#F8C8C7',
			contrastText: '#000',
		},
		text: {
			primary: '#E7675E',
		},
		error: {
			main: '#fff',
		},
	},
});

export default RedTheme;
