import { createMuiTheme } from '@material-ui/core/styles';

const MarineTheme = createMuiTheme({
	palette: {
		background: {
			default: '#fff',
		},
		primary: {
			main: '#3BA695',
			light: '#CFE9E6',
			contrastText: '#000',
		},
		text: {
			primary: '#3BA695',
		},
		error: {
			main: '#fff',
		},
	},
});

export default MarineTheme;
