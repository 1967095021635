import { createStyles, fade, makeStyles } from '@material-ui/core/styles';
import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import Offer from 'src/components/Offer';
import Flickity from 'react-flickity-component';
import classNames from 'classnames';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import { useOffers } from 'src/@context/Offers';
import { useHistory } from 'react-router-dom';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import IconButton from './IconButton';
import Header from './Header';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				position: 'fixed',
				inset: 0,
				height: '100%',
				overflow: 'hidden',
			},
			flickity: {
				transition: 'opacity .2s',

				'&, & .flickity-viewport, & .flickity-slider': {
					height: '100%',
				},

				'&:focus': {
					outline: 'none',
					border: 0,
				},
			},
			article: {
				width: '100%',
				height: '100%',
				overflow: 'hidden',
			},
			hidden: {
				opacity: 0,
				pointerEvents: 'none',
			},
			navBtn: {
				position: 'fixed',
				fontSize: rem(33, bodyFontSize.Desktop),
				fontWeight: 'bold',
				lineHeight: 1,
				color: 'black',
				display: 'none',
				[mediaQueries.AfterMobile]: {
					display: 'inline-flex',
				},
			},
			navBtnPrev: {
				left: theme.spacing(2),
				top: '50%',
				transform: 'translateY(-50%)',
			},
			navBtnNext: {
				right: theme.spacing(2),
				top: '50%',
				transform: 'translateY(-50%)',
			},
			navBtnClose: {
				top: theme.spacing(2),
				right: theme.spacing(2),
				marginTop: 'env(safe-area-inset-top, 0)',
			},
		}),
	{
		name: 'OfferSwipe',
	}
);
const MemoizedOffer = React.memo(Offer);

const OfferSwipe: FC<OfferDto | OfferAdminDto> = ({
	children,
	...offerDto
}) => {
	const history = useHistory();
	const cls = useStyles();
	const { offers: allOffers } = useOffers();
	const offers = useMemo(
		() => allOffers.filter(({ ObjectId }) => ObjectId),
		[allOffers]
	);
	const initialIndex = useRef(
		offers.findIndex(({ ObjectId }) => offerDto.ObjectId === ObjectId)
	);

	const [currentIndex, setCurrentIndex] = useState<number>(
		initialIndex.current
	);
	const showSwipe = initialIndex.current >= 0;
	const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>(
		null
	);

	const toShow = useRef(showSwipe ? offers : [offerDto]);
	const flickity = useRef<any>();

	useEffect(() => {
		if (!showSwipe) {
			return;
		}

		disablePageScroll();

		// eslint-disable-next-line consistent-return
		return () => {
			enablePageScroll();
		};
	}, [showSwipe]);

	useEffect(() => {
		if (
			!toShow.current ||
			JSON.stringify(toShow.current) === JSON.stringify(offers)
		) {
			return;
		}

		toShow.current = showSwipe ? offers : [offerDto];
	}, [offerDto, offers, showSwipe, toShow]);

	const onChange = useCallback(
		(index) => {
			setCurrentIndex(index);
		},
		[setCurrentIndex]
	);

	useEffect(() => {
		const $flickity = flickity.current;
		$flickity?.on('select', onChange);
		return () => {
			$flickity?.off('select');
		};
	}, [flickity, onChange]);

	const next = useCallback(() => {
		flickity.current?.next();
	}, [flickity]);

	const previous = useCallback(() => {
		flickity.current?.previous();
	}, [flickity]);

	return (
		<section className={cls.root}>
			<Flickity
				className={classNames(cls.flickity)}
				options={{
					initialIndex: initialIndex.current,
					setGallerySize: false,
					pageDots: false,
					prevNextButtons: false,
					dragThreshold: 10,
				}}
				// reloadOnUpdate={!swipeReady}
				// eslint-disable-next-line no-return-assign
				flickityRef={(f) => (flickity.current = f)}
				disableImagesLoaded // default false
			>
				{toShow.current.map((offer, i) => (
					<article className={cls.article} key={offer.ObjectId}>
						{Math.abs(i - currentIndex) <= 1 && (
							<MemoizedOffer
								isCarousel={showSwipe}
								isActive={showSwipe ? i === currentIndex : true}
								setScrollContainer={
									i === currentIndex ? setScrollContainer : undefined
								}
								{...offer}
							/>
						)}
					</article>
				))}
			</Flickity>
			{showSwipe && (
				<>
					<IconButton
						className={classNames(
							cls.navBtn,
							cls.navBtnPrev,
							currentIndex === 0 && cls.hidden
						)}
						type='button'
						aria-label='Button previous offer'
						onClick={previous}
						icon='iconArrowLeft'
					/>
					<IconButton
						className={classNames(
							cls.navBtn,
							cls.navBtnNext,
							currentIndex === offers.length - 1 && cls.hidden
						)}
						type='button'
						aria-label='Button next offer'
						onClick={next}
						icon='iconArrowRight'
					/>
				</>
			)}
			<Header
				showCloseButton
				showDefaultNav={false}
				variant='themeTransparent'
				scrollContainer={scrollContainer}
			/>
			{children}
		</section>
	);
};

export default OfferSwipe;
