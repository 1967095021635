import React, { FC } from 'react';
import OfferSection from './OfferSection';

const OfferSectionCampaign: FC<OfferDto | OfferAdminDto> = (offer) => (
	<OfferSection title='Aktion'>
		<div
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: offer.Text?.replace(/\n/g, '<br />') || '',
			}}
		/>
	</OfferSection>
);

export default OfferSectionCampaign;
