import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import FormStyles from './FormStyles';

type TTextAreaProps = {
	label: string;
	name: string;
	control: any;
	initialValue: string;
	setFieldValue: any;
	rules?: any;
};

const Textarea: FC<TTextAreaProps> = ({
	label,
	control,
	name,
	rules,
	initialValue,
	setFieldValue,
	children,
}) => {
	const formCls = FormStyles();
	const {
		field,
		fieldState: { error },
	} = useController({ control, name, rules });

	return (
		// eslint-disable-next-line jsx-a11y/label-has-for
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <span className={formCls.label}>{label}</span>}
			<Editor
				tinymceScriptSrc={`${process.env.PUBLIC_URL}/assets/tinymce/tinymce.min.js`}
				initialValue={initialValue ?? ''}
				textareaName={field.name}
				init={{
					height: 500,
					menubar: false,
					toolbar:
						'undo redo | formatselect | ' +
						'bold italic underline | alignleft aligncenter ' +
						'alignright alignjustify | ' +
						'removeformat',
				}}
				onEditorChange={(content) => {
					setFieldValue(field.name, content);
				}}
			/>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default Textarea;
