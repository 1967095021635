/* eslint-disable react/no-array-index-key */
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import MenuToggler from 'src/components/MenuToggler';
import PageLayout from 'src/components/PageLayout';
import PanelImages from 'src/components/PanelImages';
import PanelText from 'src/components/PanelText';
import PanelTitle from 'src/components/PanelTitle';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			paddingBottom: 100,
		},
		panelsContainer: {
			position: 'relative',
			minHeight: '100%',

			[mediaQueries.Mobile]: {
				padding: `0 ${spacing.Gutter.Mobile}px ${spacing.Gutter.Mobile * 2}px ${
					spacing.Gutter.Mobile
				}px`,
			},
			[mediaQueries.AfterMobile]: {
				padding: `0 ${spacing.Gutter.Desktop * 2}px`,
			},
		},
		footer: {
			[mediaQueries.AfterMobile]: {
				display: 'none',
			},
		},
	})
);

const PanelPage: FC<{ page: PanelPageDto | SecondaryPageDto }> = ({ page }) => {
	const cls = useStyles();

	return (
		<PageLayout>
			<article className={cls.root}>
				<Header />
				<div className={cls.panelsContainer}>
					{page.Panels.map((panel, index) => {
						switch (panel.type) {
							case 'PanelTitle':
								return (
									<PanelTitle
										title={(panel as PanelTitleDto).Title}
										key={`${panel.type}-${index}`}
									/>
								);

							case 'PanelText':
								return (
									<PanelText
										text={(panel as PanelTextDto).Text}
										key={`${panel.type}-${index}`}
									/>
								);

							case 'PanelImages':
								return (
									<PanelImages
										panel={panel as PanelImagesDto}
										key={`${panel.type}-${index}`}
									/>
								);

							default:
								return null;
						}
					})}
				</div>
			</article>
			<div className={cls.footer}>
				<Footer />
			</div>
		</PageLayout>
	);
};

export default PanelPage;
