/* eslint-disable import/no-extraneous-dependencies */
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';
import {
	GoogleMap,
	Marker,
	MarkerClusterer,
	useJsApiLoader,
} from '@react-google-maps/api';
import {
	Clusterer,
	ClustererOptions,
} from '@react-google-maps/marker-clusterer';
import { useHistory } from 'react-router-dom';
import mapProfileLibraries from 'src/constants/googlemapsLibraries';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
		},
	})
);

const options: google.maps.MapOptions = {
	styles: [
		{
			featureType: 'all',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'all',
			stylers: [
				{
					color: '#f2f2f2',
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#686868',
				},
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'all',
			stylers: [
				{
					color: '#f2f2f2',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.park',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'poi.park',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'road',
			elementType: 'all',
			stylers: [
				{
					saturation: -100,
				},
				{
					lightness: 45,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'all',
			stylers: [
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.fill',
			stylers: [
				{
					lightness: -22,
				},
				{
					visibility: 'on',
				},
				{
					color: '#b4b4b4',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					saturation: -51,
				},
				{
					lightness: 11,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text',
			stylers: [
				{
					saturation: 3,
				},
				{
					lightness: -56,
				},
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text.fill',
			stylers: [
				{
					lightness: -52,
				},
				{
					color: '#9094a0',
				},
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					weight: 6.13,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'labels.icon',
			stylers: [
				{
					weight: 1.24,
				},
				{
					saturation: -100,
				},
				{
					lightness: -10,
				},
				{
					gamma: 0.94,
				},
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on',
				},
				{
					color: '#b4b4b4',
				},
				{
					weight: 5.4,
				},
				{
					lightness: 7,
				},
			],
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'labels.text',
			stylers: [
				{
					visibility: 'simplified',
				},
				{
					color: '#231f1f',
				},
			],
		},
		{
			featureType: 'road.highway.controlled_access',
			elementType: 'labels.text.fill',
			stylers: [
				{
					visibility: 'simplified',
				},
				{
					color: '#595151',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					lightness: -16,
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on',
				},
				{
					color: '#d7d7d7',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text',
			stylers: [
				{
					color: '#282626',
				},
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.fill',
			stylers: [
				{
					saturation: -41,
				},
				{
					lightness: -41,
				},
				{
					color: '#2a4592',
				},
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					weight: 1.1,
				},
				{
					color: '#ffffff',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry.fill',
			stylers: [
				{
					lightness: -16,
				},
				{
					weight: 0.72,
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'labels.text.fill',
			stylers: [
				{
					lightness: -37,
				},
				{
					color: '#2a4592',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'off',
				},
				{
					color: '#eeed6a',
				},
			],
		},
		{
			featureType: 'transit.line',
			elementType: 'geometry.stroke',
			stylers: [
				{
					visibility: 'off',
				},
				{
					color: '#0a0808',
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'all',
			stylers: [
				{
					color: '#b7e4f4',
				},
				{
					visibility: 'on',
				},
			],
		},
	],
	disableDefaultUI: true,
	zoomControl: true,
	gestureHandling: 'cooperative',
};

const RenderMarker: FC<{
	offer: OfferDto;
	color: string;
	clusterer: Clusterer;
	handleClick?: () => void;
}> = ({ offer, color, clusterer, handleClick }) => {
	const { Location, Title } = offer;
	if (!Location || (Location.lat === 0 && Location.lng === 0)) {
		return null;
	}

	return (
		<Marker
			title={Title}
			position={{ lat: Location.lat, lng: Location.lng }}
			clusterer={clusterer}
			onClick={handleClick}
			icon={{
				path: 'M16.4,0.4L16.4,0.4c-6,0-10.9,3.7-10.9,10.9c0,7.1,10.9,20.9,10.9,20.9l0,0c0,0,10.9-13.8,10.9-20.9S22.4,0.4,16.4,0.4zM16.4,14.4c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S18.2,14.4,16.4,14.4z',
				fillColor: color,
				fillOpacity: 1.0,
				strokeWeight: 0,
				scale: 1,
				anchor: new google.maps.Point(23 / 2, 33),
			}}
		/>
	);
};

const MemoizedMarker = React.memo(RenderMarker);

const Map: FC<{ offers: OfferDto[]; allowMarkerClick: boolean }> = ({
	offers,
	allowMarkerClick,
}) => {
	const cls = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const clusterColor = theme?.palette?.primary?.main || 'red';

	const clustererOptions: ClustererOptions = {
		styles: [
			{
				url: `data:image/svg+xml,${encodeURIComponent(
					`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58.2 60.1" style="enable-background:new 0 0 58.2 60.1;" xml:space="preserve"><path fill="${clusterColor}" d="M29.1,60.1c0,0,29.1-17.1,29.1-34.7C58.2,7.6,43.1,0,29.1,0S0,7.6,0,25.5C0,41.9,29.1,60.1,29.1,60.1z"/></svg>`
				)}`,
				height: 58,
				width: 56,
				fontFamily: 'FormaDJRText',
				fontWeight: '400',
				textSize: 25,
			},
		],
	};

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
		libraries: mapProfileLibraries,
	});

	const defaultCenter = { lat: 47.559601, lng: 7.588576 }; // Basel
	const $center = offers[0]?.Location || defaultCenter;

	const offersToRender = useMemo(
		() => offers.filter((o) => o.Location?.lat || o.Location?.lng),
		[offers]
	);

	if (loadError) return <div>Error loading the map</div>;
	if (!isLoaded) return <div>Loading</div>;

	return (
		<div className={cls.root}>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={{ width: '100%', height: '100%' }}
					center={$center}
					zoom={10}
					options={options}
				>
					<MarkerClusterer options={clustererOptions}>
						{(clusterer) =>
							offersToRender.map((o) => (
								<MemoizedMarker
									key={o.ObjectId}
									offer={o}
									handleClick={
										allowMarkerClick
											? () => history.push(`/angebot/${o.UID}`)
											: undefined
									}
									color={theme.palette.primary.main}
									clusterer={clusterer}
								/>
							))
						}
					</MarkerClusterer>
				</GoogleMap>
			)}
		</div>
	);
};

export default Map;
