import * as React from 'react';
import { useContext, useEffect, useState, createContext, FC } from 'react';
import { useApi } from './Api';

export type IPage = IPageDto & { endpoint: string };

type App = {
	app?: AppDto;
	menuOpened: boolean;
	toggleMenu: (v: boolean) => void;
	messageScreen: MessageProps | undefined;
	setMessageScreen: (v: MessageProps | undefined) => void;
	// page?: IPage;
};
export const AppContext = createContext<App>({} as never);
export const App: FC = ({ children }) => {
	const { get } = useApi();
	const [menuOpened, setMenuOpened] = useState(false);
	const [messageScreen, setMessageScreen] = useState<MessageProps>();
	const [app, setApp] = useState<AppDto>();

	useEffect(() => {
		(async () => {
			const appElt = document.getElementById('app');
			const json = appElt?.innerHTML;
			let dto: AppDto | undefined;

			try {
				dto = (json && (JSON.parse(json) as AppDto)) || undefined;
			} catch (e) {
				// eslint-disable-next-line no-console
				console.info('malformed JSON on page');
			}

			if (!dto) {
				const {data} = await get<AppDto>(`/api/app`);
				dto = data;
			}
			setApp(dto);
		})();
	}, [setApp, get]);

	const value = {
		app,
		menuOpened,
		toggleMenu: setMenuOpened,
		messageScreen,
		setMessageScreen,
	};
	return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useApp = (): App => useContext(AppContext);
