import React, { FC } from 'react';
import classNames from 'classnames';
import { createStyles, lighten, makeStyles } from '@material-ui/core';
import spacing from '../constants/spacing';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';
import mediaQueries from '../constants/mediaQueries';

const filterHeight = 39 - 2 * 2; // Take border into account

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			filterTabButton: {
				position: 'relative',
				borderWidth: 2,
				borderStyle: 'solid',
				borderTopLeftRadius: 5,
				borderTopRightRadius: 5,
				borderColor: theme.palette.primary.main,
				textAlign: 'center',
				borderBottom: 0,
				margin: `0 ${spacing.Gutter.Mobile / 2}px`,
				height: filterHeight,
				lineHeight: `${filterHeight}px`,
				backgroundColor: 'transparent',
				color: 'black',
				textTransform: 'uppercase',
				fontWeight: 'bold',
				fontSize: rem(20, bodyFontSize.Desktop),
				letterSpacing: '0.2em',

				'&[data-active="true"]': {},

				[mediaQueries.Mobile]: {
					width: 'calc(48% - 30px)',
					'&[data-active="true"]': {
						backgroundColor: theme.palette.primary.main,
						color: 'black',
					},
				},
				[mediaQueries.AfterMobile]: {
					width: 150,
					'&[data-active="true"][data-opened="true"]': {
						backgroundColor: theme.palette.primary.main,
						color: 'black',
					},
					'&:hover, &[data-active="false"][data-opened="false"]:hover': {
						backgroundColor: lighten(theme.palette.primary.light, 0.5),
					},
				},

				'& .closeButtonOverlay': {
					display: 'none',
				},
			},
			showCloseButton: {
				[mediaQueries.AfterMobile]: {
					'& .closeButtonOverlay': {
						display: 'flex',
						backgroundColor: theme.palette.primary.main,
						position: 'absolute',
						inset: 0,
						alignItems: 'center',
						justifyContent: 'center',
					},
				},
			},
		}),
	{ name: 'FilterTabButton' }
);

export type FilterTabButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	opened: boolean;
	active: boolean;
};

const FilterTabButton: FC<FilterTabButtonProps> = ({
	opened,
	active,
	className,
	children,
	...rest
}) => {
	const cls = useStyles();

	return (
		<button
			type='button'
			className={classNames(
				cls.filterTabButton,
				{
					[cls.showCloseButton]: active && opened,
				},
				className
			)}
			data-opened={opened}
			data-active={active}
			{...rest}
		>
			{children}
			<span className='closeButtonOverlay'>
				<svg
					width={17}
					height={17}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					aria-label='Close'
				>
					<path
						d='M2.12 2.128l12.938 12.744M14.903 1.974L2.275 15.026'
						stroke='#000'
						strokeWidth={3}
						strokeLinecap='round'
					/>
				</svg>
			</span>
		</button>
	);
};

export default FilterTabButton;
