import { createStyles, makeStyles } from '@material-ui/core';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';

export default makeStyles(
	(theme) =>
		createStyles({
			bgImage: {
				backgroundColor: 'white',
				position: 'fixed',
				inset: 0,
				objectFit: 'cover',
				objectPosition: 'center',
				fontFamily: 'object-fit: cover;',
				pointerEvents: 'none',
				width: '100%',
				height: '50vh',
				[mediaQueries.AfterMobile]: {
					width: '100%',
					height: '100%',
				},
			},
			pageLayout: {
				paddingTop: '50vh',
			},
			root: {
				position: 'relative',
				backgroundColor: theme.palette.primary.main,
				'&[data-contact="true"]': {
					height: '100%',
					overflow: 'hidden',
				},

				[mediaQueries.AfterMobile]: {
					borderRadius: 8,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					width: 'max(690px, 50vw)',
					margin: 'auto',
				},
			},
			panelsContainer: {
				position: 'relative',
				minHeight: '100%',

				[mediaQueries.Mobile]: {
					padding: `${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: `${spacing.Gutter.Desktop * 2}px`,
				},
			},
			title: {
				marginTop: 40,
				marginBottom: 20,
				fontWeight: 'bold',
				fontSize: rem(32, bodyFontSize.Mobile),
				letterSpacing: '0.01em',
				lineHeight: 0.866,
			},
			form: {
				position: 'relative',
			},
		}),
	{ name: 'Offer-Edit' }
);
