import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useResponsive } from 'src/@context/Responsive';
import PageLayout from 'src/components/PageLayout';
import HomeDesktop from '../components/HomeDesktop';
import HomeMobile from '../components/HomeMobile';

const Home: FC = () => {
	const { steps } = useResponsive();
	const location = useLocation();
	const [showHome, setShowHome] = useState(location.pathname === '/');
	const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>(
		null
	);

	useEffect(() => {
		if (location.pathname === '/') {
			setShowHome(true);
		}
	}, [location]);

	if (!showHome) return null;
	return (
		<PageLayout
			setScrollContainer={setScrollContainer}
			spaceForHeader={false}
			fullWidth
			background='default'
		>
			{steps.includes('BeforeTablet') && (
				<HomeMobile scrollContainer={scrollContainer} />
			)}
			{steps.includes('AfterMobile') && (
				<HomeDesktop scrollContainer={scrollContainer} />
			)}
		</PageLayout>
	);
};

export default Home;
