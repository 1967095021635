import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { Link } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';
import fontStyles from '../constants/fontStyles';

const useStyles = makeStyles(
	(theme) => ({
		menuLink: {
			textAlign: 'center',
			color: theme.palette.primary.main,
			marginBottom: spacing.Gutter.Mobile,
			fontSize: '1.8rem',
			padding: `0 ${spacing.Gutter.Desktop}px`,
			fontWeight: 700,
			'&[data-submenu="true"]': {
				fontWeight: 600,
				fontSize: rem(20, bodyFontSize.Desktop),
			},
			[mediaQueries.AfterMobile]: {
				...fontStyles.title,
				marginBottom: 15,
				'&[data-submenu="true"]': {
					fontSize: rem(20, bodyFontSize.Desktop),
				},
			},
		},
	}),
	{ name: 'MenuItem' }
);

export type StaticMenu = {
	type: 'static';
	title: string;
	url: string;
};

type MenuItemProps = {
	menu: StaticMenu | MenuDto;
	isSubmenu?: boolean;
};

const MenuItem: FC<MenuItemProps> = ({ menu, isSubmenu }) => {
	const cls = useStyles();
	const { toggleMenu } = useApp();
	const { type } = menu;

	switch (type) {
		case 'static':
			// eslint-disable-next-line no-case-declarations
			const m = menu as StaticMenu;
			return (
				<Link
					to={`${m.url}`}
					className={cls.menuLink}
					data-submenu={isSubmenu}
					onClick={() => toggleMenu(false)}
				>
					{m.title}
				</Link>
			);

		case 'PanelPage':
			// eslint-disable-next-line no-case-declarations
			const p = menu as MenuDto;
			return (
				<Link
					to={`${p.Url}`}
					className={cls.menuLink}
					data-submenu={isSubmenu}
					onClick={() => toggleMenu(false)}
				>
					{p.Title}
				</Link>
			);

		default:
			return null;
	}

	// if (type === 'static') {
	// 	const m = props as StaticMenu;
	// 	return (
	// 		<Link
	// 			to={`${m.url}`}
	// 			className={cls.menuLink}
	// 			data-submenu={m.isSubmenu}
	// 			onClick={() => toggleMenu(false)}
	// 		>
	// 			{m.title}
	// 		</Link>
	// 	);
	// }

	// const { menu } = props as DynamicMenu;
	// return (
	// 	<Link
	// 		to={`${menu.Url}`}
	// 		className={cls.menuLink}
	// 		data-submenu={isSubmenu}
	// 		onClick={() => toggleMenu(false)}
	// 	>
	// 		{menu.Title}
	// 	</Link>
	// );
};

export default MenuItem;
