import { createMuiTheme } from '@material-ui/core/styles';

const BlueTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#2C80BF',
			light: '#BEE3EB',
			contrastText: '#000',
		},
		text: {
			primary: '#2C80BF',
		},
		error: {
			main: '#fff',
		},
	},
});

export default BlueTheme;
