import mediaQueries from './mediaQueries';
import fonts from './fonts';

export const bodyFontSize = {
	Mobile: 13,
	Tablet: 13,
	Laptop: 13,
	Desktop: 13,
};
export const warnningAnimation = {
	'0%': {
		transform: 'translateX(0)',
	},
	'100%': {
		transform: 'translateX(-100%)',
	},
};

export default {
	Text: {
		...fonts.FormaDJRMedium,
		lineHeight: '1.25',
		[mediaQueries.smallMobile]: {
			fontSize: bodyFontSize.Mobile,
		},
		[mediaQueries.Mobile]: {
			fontSize: bodyFontSize.Mobile,
		},
		[mediaQueries.Tablet]: {
			fontSize: bodyFontSize.Tablet,
		},
		[mediaQueries.Laptop]: {
			fontSize: bodyFontSize.Laptop,
		},
		[mediaQueries.Desktop]: {
			fontSize: bodyFontSize.Desktop,
		},
	},
	ObjectFitCover: {
		objectFit: 'cover',
		fontFamily: '"object-fit: cover;"',
	},
	ObjectFitContain: {
		objectFit: 'contain',
		fontFamily: '"object-fit: contain;"',
	},
};
