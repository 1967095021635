import classNames from 'classnames';
import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type InputCheckboxProps = React.ButtonHTMLAttributes<HTMLInputElement> & {
	register?: any;
	id: string;
	label?: React.ReactNode;
	error?: FieldError | boolean | undefined;
	callback?: () => void;
};

const InputCheckbox: FC<InputCheckboxProps> = ({
	register,
	label,
	error,
	id,
	children,
	callback,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		<div className={formCls.inputContainer} data-error={!!error}>
			<input
				type='checkbox'
				id={id}
				className={formCls.inputCheckbox}
				{...register}
				onChange={(e) => {
					register.onChange(e);
					if (callback) callback();
				}}
				{...rest}
			/>
			<label
				htmlFor={id}
				className={classNames(formCls.label, formCls.inputCheckboxLabel)}
			>
				<div className={formCls.inputCheckboxContent}>
					{label}
					{children}
				</div>
			</label>
			{error && typeof error !== 'boolean' && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
		</div>
	);
};

export default InputCheckbox;
