import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OfferSection from './OfferSection';
import {
	LargeEcologyScore,
	LargeEconomyScore,
	LargeSocialScore,
} from './LargeOfferScores';
import Button from './Button';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			scoresContainer: {
				paddingTop: 20,
				display: 'flex',
				gap: 40,
				flexWrap: 'wrap',
				justifyContent: 'center',
			},
			linkContainer: {
				marginTop: 40,
			},
		}),
	{
		name: 'OfferSectionSustainability',
	}
);

const OfferSectionSustainability: FC<OfferDto | OfferAdminDto> = (offer) => {
	const cls = useStyles();
	return (
		<OfferSection title='Nachhaltigkeit'>
			<div className={cls.scoresContainer}>
				<LargeSocialScore offer={offer} />
				<LargeEcologyScore offer={offer} />
				{offer.EconomyRating > 0 && <LargeEconomyScore offer={offer} />}
			</div>
			<div className={cls.linkContainer}>
				<Button as='link' to='/nachhaltig'>
					Zu den Kriterien
				</Button>
			</div>
		</OfferSection>
	);
};

export default OfferSectionSustainability;
