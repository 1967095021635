export const smallMobileSize = 320;
export const mobileSize = 840;
export const tabletSize = 1024;
export const desktopSize = 1420;

const mediaQueries = {
	smallMobile: `@media screen and (max-width: ${smallMobileSize}px)`,
	Landscape: `@media screen and  (orientation: landscape)`,
	Portrait: `@media screen and  (orientation: portrait)`,
	Mobile: `@media screen and (min-width: ${
		smallMobileSize + 1
	}px) and (max-width: ${mobileSize}px)`,
	AfterMobile: `@media screen and (min-width: ${mobileSize + 1}px)`,
	BeforeTablet: `@media screen and (max-width: ${mobileSize}px)`,
	Tablet: `@media screen and (min-width: ${
		mobileSize + 1
	}px) and (max-width: ${tabletSize}px)`,
	AfterTablet: `@media screen and (min-width: ${tabletSize + 1}px)`,
	Laptop: `@media screen and (min-width: ${tabletSize + 1}px) and (max-width: ${
		desktopSize - 1
	}px)`,
	Desktop: `@media screen and (min-width: ${desktopSize}px)`,
};

export default mediaQueries;
