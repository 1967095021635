import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import FormStyles from 'src/components/Form/FormStyles';
import InputKantonDropdown from 'src/components/Form/InputKantonDropdown';
import InputSelect from 'src/components/Form/InputSelect';
import InputText from 'src/components/Form/InputText';
import InputEmail from 'src/components/Form/InputEmail';
import Header from 'src/components/Header';
import MenuToggler from 'src/components/MenuToggler';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import { countryOptions } from 'src/components/Form/InputValues';
import { useUsers } from 'src/@context/Users';
import InputPassword from 'src/components/Form/InputPassword';
import { useApi } from 'src/@context/Api';
import { useApp } from 'src/@context/App';
import LoadingScreen from 'src/components/LoadingScreen';
import { useHistory } from 'react-router-dom';
import IconButton from '../components/IconButton';
import Button from '../components/Button';

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
	})
);

const EditProfilePage: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const { current } = useUsers();
	const { post, patch, loading } = useApi();
	const { setMessageScreen } = useApp();
	const history = useHistory();

	const defaultValues: ProfileFormData = {
		FirstName: current?.FirstName || '',
		LastName: current?.LastName || '',
		Email: current?.Email || '',
		Address: current?.Address || '',
		COAddress: current?.COAddress || '',
		ZipCode: current?.ZipCode || '',
		City: current?.City || '',
		Province: current?.Province || '',
		Country: current?.Country || '',
	};

	const {
		register: profileRegister,
		handleSubmit: profileHandleSubmit,
		formState: { errors: profileErrors },
	} = useForm<ProfileFormData>({
		defaultValues,
	});

	const profileSubmit = async (profileData: ProfileFormData) => {
		const { data, error: profileError } = await patch(
			`/2.0/account`,
			profileData
		);

		if (profileError && !data) {
			setMessageScreen({
				type: 'Error',
				message:
					`${profileError?.Message}` ||
					'Schick’ uns eine Email oder versuche es später noch einmal',
			});
			return;
		}

		setMessageScreen({ type: 'Success', message: 'Profil angepasst!' });
	};

	const {
		register: passwordRegister,
		handleSubmit: passwordHandleSubmit,
		formState: { errors: passwordErrors },
		getValues,
	} = useForm<
		Omit<ChangePasswordBinding, 'Id'> & { ConfirmNewPassword: string }
	>();

	const passwordSubmit = async (
		passwordData: Omit<ChangePasswordBinding, 'Id'>
	) => {
		if (!current) return;
		const data: ChangePasswordBinding = {
			Id: current.Email,
			CurrentPassword: passwordData.CurrentPassword,
			NewPassword: passwordData.NewPassword,
		};

		const { data: success, error } = await post(
			`/2.0/account/changepassword`,
			data
		);

		if (error && !success) {
			setMessageScreen({
				type: 'Error',
				message:
					`${error?.Message}` ||
					'Schick’ uns eine Email oder versuche es später noch einmal',
			});
			return;
		}

		setMessageScreen({ type: 'Success', message: 'Passwort angepasst!' });
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<Header />
			<article className={formCls.modal}>
				<h2 className={classNames(formCls.title, formCls.centered)}>
					Profil anpassen
				</h2>
				<form onSubmit={profileHandleSubmit(profileSubmit)}>
					<div className={formCls.section}>
						<InputText
							error={profileErrors.FirstName}
							label='Name'
							readonly
							register={profileRegister('FirstName')}
						/>
						<InputText
							error={profileErrors.LastName}
							label='Nachname'
							readonly
							register={profileRegister('LastName')}
						/>
						<InputEmail
							error={profileErrors.Email}
							label='Mailadresse'
							readonly
							register={profileRegister('Email')}
						/>
					</div>
					<div className={formCls.section}>
						<InputText
							error={profileErrors.Address}
							label='Adresse'
							register={profileRegister('Address', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={profileErrors.COAddress}
							label='c/o Adresse'
							register={profileRegister('COAddress')}
						/>
						<InputText
							error={profileErrors.ZipCode}
							label='Postleitzahl'
							register={profileRegister('ZipCode', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={profileErrors.City}
							label='Ort'
							register={profileRegister('City', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputKantonDropdown
							error={profileErrors.Province}
							label='Kanton'
							register={profileRegister('Province')}
						/>
						<InputSelect
							error={profileErrors.Country}
							label='Land'
							options={countryOptions}
							register={profileRegister('Country')}
						/>
					</div>
					<Button variant='formSubmitButton' type='submit'>
						Weiter
					</Button>
				</form>
				<h2 className={classNames(formCls.title, formCls.centered)}>
					Passwort ändern
				</h2>
				<form onSubmit={passwordHandleSubmit(passwordSubmit)}>
					<div className={formCls.section}>
						<InputPassword
							error={passwordErrors.CurrentPassword}
							label='Jetziges Passwort'
							register={passwordRegister('CurrentPassword', {
								required: 'Bitte ausfüllen',
								minLength: 6,
							})}
						/>
						<InputPassword
							error={passwordErrors.NewPassword}
							label='Neues Passwort'
							register={passwordRegister('NewPassword', {
								required: 'Bitte ausfüllen',
								minLength: 6,
							})}
						/>
						<InputPassword
							error={passwordErrors.ConfirmNewPassword}
							label='Passwort bestätigen'
							register={passwordRegister('ConfirmNewPassword', {
								required: 'Bitte ausfüllen',
								minLength: 6,
								validate: (value) =>
									value === getValues('NewPassword') ||
									'Passwords needs to match',
							})}
						/>
					</div>
					<Button variant='formSubmitButton' type='submit'>
						Weiter
					</Button>
				</form>
			</article>
		</PageLayout>
	);
};

export default EditProfilePage;
