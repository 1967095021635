import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import FormStyles from 'src/components/Form/FormStyles';
import InputText from 'src/components/Form/InputText';
import Header from 'src/components/Header';
import MenuToggler from 'src/components/MenuToggler';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import { useApi } from 'src/@context/Api';
import { useApp } from 'src/@context/App';
import LoadingScreen from 'src/components/LoadingScreen';
import { Link } from 'react-router-dom';
import IconButton from '../components/IconButton';
import InputCheckbox from '../components/Form/InputCheckbox';
import InputTextarea from '../components/Form/InputTextarea';
import Button from '../components/Button';

type SendPushNotificationFormData = {
	Title: string;
	Body: string;
	Action: string;
	IsTest: boolean;
};

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
	})
);

const SendPushNotificationPage: FC = () => {
	const formCls = FormStyles();
	const { post, loading } = useApi();
	const { setMessageScreen } = useApp();

	const defaultValues: SendPushNotificationFormData = {
		Title: '',
		Body: '',
		Action: '',
		IsTest: false,
	};

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, isSubmitting },
		reset,
	} = useForm<SendPushNotificationFormData>({
		defaultValues,
	});

	const pushNotificationSubmit = async (
		formData: SendPushNotificationFormData
	) => {
		const { error } = await post(`/2.0/push-notification/send`, formData);

		if (error != null) {
			setMessageScreen({
				type: 'Error',
				message:
					error?.Message ??
					'Schick’ uns eine Email oder versuche es später noch einmal',
			});

			return;
		}

		setMessageScreen({
			type: 'Info',
			message: 'Deine Benachrichtigung wird gesendet!',
		});

		reset();
	};

	const action = watch('Action');

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<Header />
			<article className={formCls.modal}>
				<h2 className={classNames(formCls.title, formCls.centered)}>
					Push-Benachrichtigung Senden
				</h2>
				<form onSubmit={handleSubmit(pushNotificationSubmit)}>
					<div className={formCls.section}>
						<InputText
							error={errors.Title}
							label='Titel'
							register={register('Title', { required: true })}
						/>
						<div>
							<InputText
								error={errors.Action}
								label='Interner Link'
								register={register('Action', {
									required: false,
									pattern: /^\/.*$/,
								})}
								placeholder='z.B. /anbieterwerden'
							/>
							{action && (
								<Link
									style={{
										marginBottom: '1em',
										display: 'inline-block',
										textDecoration: 'underline',
									}}
									target='_blank'
									to={action}
								>
									Link testen
								</Link>
							)}
						</div>
						<InputTextarea
							error={errors.Body}
							label='Nachricht'
							register={register('Body', { required: true })}
						/>
						<InputCheckbox
							id='IsTest'
							error={errors.IsTest}
							label='Nur an Testnutzer senden'
							register={register('IsTest')}
						/>
					</div>
					<Button
						variant='formSubmitButton'
						disabled={isSubmitting}
						type='submit'
					>
						Senden
					</Button>
				</form>
			</article>
		</PageLayout>
	);
};

export default SendPushNotificationPage;
