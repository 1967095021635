import React, { FC } from 'react';
import useStyles from './Filters.styles';

type FilterDropdownProps = {
	label: string;
	values: { value: string; name: string }[];
	value: string;
	onSelect: (value: string) => void;
};
const FilterDropdown: FC<FilterDropdownProps> = ({
	label,
	values,
	value: current,
	onSelect,
}) => {
	const cls = useStyles();
	return (
		<div className={cls.dropdown}>
			<div
				className={cls.dropdownInner}
				data-behavior='rational-filter-dropdown'
				data-value='1'
			>
				<span className={cls.dropdownLegend}>{label}</span>
				<select
					className={cls.dropdpwnSelect}
					onChange={(e) => onSelect(e.target.value)}
					value={current}
				>
					{values?.map(({ value, name }) => (
						<option key={value} value={value}>
							{name}
						</option>
					))}
				</select>
			</div>
		</div>
	);
};
export default FilterDropdown;
