/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useEffect, useRef, useState } from 'react';
import mapProfileLibraries from '../constants/googlemapsLibraries';

const map = document.createElement('div'); // PlacesService requires a div element to initialize

function usePlacesAutocomplete() {
	// prettier-ignore
	const [placePredictions, setPlacePredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);

	const { isLoaded } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
		libraries: mapProfileLibraries,
	});

	// prettier-ignore
	const autocompleteServiceRef = useRef<google.maps.places.AutocompleteService | undefined>();
	// prettier-ignore
	const placesServiceRef = useRef<google.maps.places.PlacesService | undefined>();
	useEffect(() => {
		if (isLoaded) {
			// prettier-ignore
			autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
			// prettier-ignore
			placesServiceRef.current = new google.maps.places.PlacesService(map);
		}
	}, [isLoaded]);

	const fetchPlacePredictions = useCallback((input: string) => {
		if (!input) {
			setPlacePredictions([]);
			return;
		}

		const service = autocompleteServiceRef.current;
		if (service) {
			service.getPlacePredictions(
				{ input, componentRestrictions: { country: ['ch', 'fr', 'de'] } },
				(predictions) => setPlacePredictions(predictions || [])
			);
		}
	}, []);

	const getPlaceDetails = useCallback(
		(placeId: string): Promise<google.maps.places.PlaceResult | null> =>
			new Promise((resolve) => {
				const service = placesServiceRef.current;
				if (!service) {
					resolve(null);
					return;
				}

				service.getDetails(
					{ placeId, fields: ['address_components', 'geometry'] },
					(placeResult) => {
						resolve(placeResult || null);
					}
				);
			}),
		[]
	);

	return {
		placePredictions,
		fetchPlacePredictions,
		getPlaceDetails,
	};
}

export default usePlacesAutocomplete;
