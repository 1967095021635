import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import fontStyles from '../constants/fontStyles';

const useStyles = makeStyles(() =>
	createStyles({
		text: {
			...fontStyles.defaultText,
			marginBottom: spacing.Gutter.Desktop * 2,

			'& a': {
				textDecoration: 'underline',
			},
		},

		[mediaQueries.AfterMobile]: {
			maxWidth: 700,
			margin: `0 auto  ${spacing.Gutter.Desktop * 3}px auto`,
		},
	})
);

const PanelText: FC<{ text: string }> = ({ text }) => {
	const cls = useStyles();
	return (
		<div className={cls.text} dangerouslySetInnerHTML={{ __html: text }} />
	);
};

export default PanelText;
