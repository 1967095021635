import React from 'react';
import {
	createStyles,
	makeStyles,
	ThemeProvider,
} from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Menu from 'src/components/Menu';
import Message from 'src/components/Message';
// import LoadingScreen from 'src/components/LoadingScreen';
import { App as AppContext } from './@context/App';
import { Api } from './@context/Api';
import Pages from './Pages';
import stylesheet from './constants/stylesheet';
import BlueTheme from './Themes/Blue';
import YellowTheme from './Themes/Yellow';
import GreenTheme from './Themes/Green';
import RedTheme from './Themes/Red';
import MarineTheme from './Themes/Marine';
import { ResponsiveProvider } from './@context/Responsive';
import mediaQueries from './constants/mediaQueries';
import Layout from './components/Layout';
import { Users } from './@context/Users';
import { Offers } from './@context/Offers';
import Thanks from './Pages/Thanks';
import WelcomeScreen from './components/WelcomeScreen';
import EditProfilePage from './Pages/EditProfilePage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import Fourteen from './Pages/Fourteen';
import Gift from './Pages/Gift';
import Home from './Pages/Home';
import OfferCreatePage from './Pages/OfferCreatePage';
import Pay from './Pages/Pay';
import PaymentPending from './Pages/PaymentPending';
import ProfilePage from './Pages/ProfilePage';
import Register from './Pages/Register';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import SignIn from './Pages/SignIn';
import SignUp from './Pages/SignUp';
import { Announcements as AnnouncementsContext } from './@context/Announcements';
import IsTouch from './utils/IsTouch';
import { PushNotificationsProvider } from './@context/PushNotificationsContext';
import SendPushNotificationPage from './Pages/SendPushNotificationPage';
import PlausibleAnalytics from './components/PlausibleAnalytics';
import NewsletterPage from './Pages/NewsletterPage';

const useStyles = makeStyles(() =>
	createStyles({
		'@global': {
			'html, body': {
				position: 'relative',
				width: '100%',
				height: '100%',
				fontSmoothing: 'antialiased',
				...stylesheet.Text,
			},
			a: {
				color: 'inherit',
				textDecoration: 'none',
			},
			li: {
				display: 'block',
			},
			hr: {
				border: 'none',
				margin: '0 auto',
				height: 2,
				maxWidth: 600,

				'&:before': {
					content: '""',
					backgroundImage:
						'repeating-linear-gradient(to right, black 0%, black 70%, transparent 70%, transparent 100%)',
					backgroundSize: '12px 1px',
					display: 'block',
					width: '100%',
					height: '100%',
				},
			},
			'#root': {
				position: 'relative',
				width: '100%',
				height: '100%',
			},
		},
		app: {
			position: 'relative',
			width: '100%',
			height: '100%',

			'[data-welcome="true"] &': {
				overflow: 'hidden',
			},
		},
	})
);

const App = (): JSX.Element => {
	const cls = useStyles();
	const themes = [BlueTheme, YellowTheme, RedTheme, MarineTheme, GreenTheme];
	const theme = themes[Math.floor(Math.random() * themes.length)];

	const render = () => <Pages />;
	return (
		<BrowserRouter>
			<Api>
				<AnnouncementsContext>
					<AppContext>
						<PlausibleAnalytics />
						<Users>
							<PushNotificationsProvider>
								<Offers>
									<ResponsiveProvider mediaQueries={mediaQueries}>
										<ThemeProvider theme={theme}>
											<div
												className={cls.app}
												// data-welcome={showWelcome}
												data-istouch={IsTouch()}
											>
												<Layout>
													<WelcomeScreen />
													<Message />
													<Home />
													<Switch>
														<Route path='/login'>
															<SignIn />
														</Route>
														<Redirect from='/signup' to='/neuanmeldung' />
														<Redirect from='/anmeldung' to='/neuanmeldung' />
														<Route path='/neuanmeldung'>
															<SignUp />
														</Route>
														<Route path='/geschenk'>
															<Gift />
														</Route>
														<Route path='/anbieterwerden'>
															<OfferCreatePage />
														</Route>
														<Route path='/thanks/:id'>
															<Thanks />
														</Route>
														<Route path='/fourteen'>
															<Fourteen />
														</Route>
														<Route path='/pay/:id'>
															<Pay />
														</Route>
														<Route path='/paymentpending'>
															<PaymentPending />
														</Route>
														<Route path='/aktivieren'>
															<Register />
														</Route>
														<Route path='/profil'>
															<ProfilePage />
														</Route>
														<Route path='/edit-profile'>
															<EditProfilePage />
														</Route>
														<Route path='/passwort-zurucksetzen'>
															<ResetPasswordPage />
														</Route>
														<Route path='/passwort-wiederherstellen'>
															<ForgotPasswordPage />
														</Route>
														<Route path='/offer-registration'>
															<EditProfilePage />
														</Route>
														<Route path='/send-push-notification'>
															<SendPushNotificationPage />
														</Route>
														<Route path='/newsletter-archiv'>
															<NewsletterPage />
														</Route>
														<Route render={render} />
													</Switch>
													<Menu />
												</Layout>
											</div>
										</ThemeProvider>
									</ResponsiveProvider>
								</Offers>
							</PushNotificationsProvider>
						</Users>
					</AppContext>
				</AnnouncementsContext>
			</Api>
		</BrowserRouter>
	);
};

export default App;
