import React from 'react';
import 'src/bubble.css';

const LightEffect = () => {
	const bubles: JSX.Element[] = Array(100).fill(<div className='bubble' />);

	return <div className='bubble-content'>{bubles}</div>;
};

export default React.memo(LightEffect);
