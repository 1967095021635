import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type InputNumberProps = {
	register: any;
	label?: string;
	readonly?: boolean;
	error: FieldError | undefined;
};

const InputNumber: FC<InputNumberProps> = ({
	register,
	label,
	error,
	readonly,
	children,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<input
				type='number'
				className={formCls.inputText}
				readOnly={readonly}
				{...register}
				{...rest}
			/>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputNumber;
