export default {
	FormaDJRMedium: {
		fontFamily: 'FormaDJRText',
		fontWeight: 500,
		fontStretch: 'normal',
		fontStyle: 'normal',
	},
	FG: {
		fontFamily: 'FG',
	},
	Icons: {
		fontFamily: 'icons',
	},
};
