import React, { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { createPortal } from 'react-dom';
import PageLayout, { PageLayoutProps } from './PageLayout';
import usePortalElement from '../utils/usePortalElement';
import Header from './Header';

const useStyles = makeStyles(
	() => ({
		pageLayoutWrapper: {
			position: 'absolute',
			zIndex: 100,
			inset: 0,
		},
		header: {
			zIndex: 1,
		},
	}),
	{ name: 'ModalPageLayout' }
);

const ModalPageLayout: FC<{
	className?: string;
	fullWidth?: boolean;
	showCloseButton?: boolean;
	header?: React.ReactNode;
	background?: PageLayoutProps['background'];
	spaceForHeader?: PageLayoutProps['spaceForHeader'];
}> = ({
	children,
	fullWidth = true,
	showCloseButton = true,
	className,
	header,
	background = 'default',
	spaceForHeader = true,
}) => {
	const cls = useStyles();
	const portalElement = usePortalElement();
	const [scrollContainer, setScrollContainer] = useState<HTMLElement | null>(
		null
	);

	if (!portalElement) {
		return null;
	}

	return createPortal(
		<div className={cls.pageLayoutWrapper}>
			<Header
				scrollContainer={scrollContainer}
				variant='themeTransparent'
				showDefaultNav={false}
				showCloseButton={showCloseButton}
				className={cls.header}
			>
				{header}
			</Header>
			<PageLayout
				className={className}
				fullWidth={fullWidth}
				background={background}
				spaceForHeader={spaceForHeader}
				setScrollContainer={setScrollContainer}
			>
				{children}
			</PageLayout>
		</div>,
		portalElement
	);
};

export default ModalPageLayout;
