import { StyleRules } from '@material-ui/core';
import { rem } from '../utils/Units';
import { bodyFontSize } from './stylesheet';
import mediaQueries from './mediaQueries';

const fontStyles: StyleRules = {
	smallText: {
		fontSize: rem(14, bodyFontSize.Mobile),
		[mediaQueries.AfterMobile]: {
			fontSize: rem(16, bodyFontSize.Mobile),
		},
	},
	defaultText: {
		fontFamily: 'FormaDJRText',
		fontSize: rem(20, bodyFontSize.Mobile),
		color: 'inherit',
		letterSpacing: '0.01em',
		lineHeight: 1.3,
		fontWeight: 500,
	},
	largeText: {
		fontSize: rem(24, bodyFontSize.Mobile),
	},
	title: {
		fontWeight: 'bold',
		fontSize: rem(30, bodyFontSize.Mobile),
		letterSpacing: '0.01em',
		lineHeight: 1,
		[mediaQueries.AfterMobile]: {
			fontSize: rem(35, bodyFontSize.Mobile),
		},
	},
	buttonText: {
		fontWeight: 'bold',
		fontSize: rem(20, bodyFontSize.Mobile),
		letterSpacing: '0.01em',
		lineHeight: 1,
	},
};

export default fontStyles;
