import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.REACT_APP_PLATFORM === 'mobile') {
	// Conditionally require PlatformMobile only when we're building for the
	// mobile platform. Conditional requires aren't possible using import
	// statements.
	// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
	const { PlatformMobileProvider } = require('./@context/PlatformMobile');

	ReactDOM.render(
		<React.StrictMode>
			<PlatformMobileProvider>
				<App />
			</PlatformMobileProvider>
		</React.StrictMode>,
		document.getElementById('root')
	);
} else {
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById('root')
	);
}
