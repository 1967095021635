import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import fontIcon from 'src/constants/fontIcon';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import Image from './Image';

const useStyles = makeStyles(() =>
	createStyles({
		images: {
			marginBottom: spacing.Gutter.Desktop * 2,
		},
		imagesInner: {
			'[data-diaporama="true"] &': {
				position: 'relative',
				height: 0,
				paddingBottom: '66.94%',
			},

			'[data-diaporama="false"] &': {
				position: 'relative',
			},
		},
		figure: {
			'[data-diaporama="true"] &': {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				transition: 'opacity .2s ease',

				opacity: 0,
				willChange: 'opacity',
			},

			'[data-diaporama="false"] &': {
				position: 'relative',
			},

			'&[data-active="true"]': {
				opacity: 1,
			},
		},
		image: {
			'[data-diaporama="true"] &': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				objectFit: 'cover',
				fontFamily: 'object-fit: cover',
				objectPosition: 'center',
			},

			'[data-diaporama="false"] &': {
				width: '100%',
				maxHeight: '100%',
			},
		},
		panelBtn: {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			fontSize: rem(30, bodyFontSize.Desktop),
			zIndex: 1,
		},
		nextBtn: {
			right: 10,

			'&:before': {
				...fontIcon.iconArrowRight,
			},
		},
		previousBtn: {
			left: 10,

			'&:before': {
				...fontIcon.iconArrowLeft,
			},
		},
	})
);

const PanelImages: FC<{ panel: PanelImagesDto }> = ({ panel }) => {
	const cls = useStyles();
	const count = panel.Images.length;
	const [activeSlide, setActiveSlide] = useState(0);

	const handleNext = () => setActiveSlide((activeSlide + 1) % count);
	const handlePrevious = () => setActiveSlide((activeSlide - 1) % count);

	return (
		<div className={cls.images} data-diaporama={count > 1}>
			<div className={cls.imagesInner}>
				{panel.Images.map((image, i) => (
					<figure
						key={image.Src}
						className={cls.figure}
						data-active={i === activeSlide}
					>
						<Image
							pictureRef={image}
							className={cls.image}
							sizes='(max-width: 767px) 100vw, (max-width: 1024px) 70vw, 50vw'
						/>
						{/* <img src={images[i]} alt='' className={cls.image} /> */}
					</figure>
				))}
				{count > 1 && (
					<>
						<button
							type='button'
							aria-label='next silde'
							className={classNames(cls.panelBtn, cls.nextBtn)}
							onClick={() => handleNext()}
						/>
						<button
							type='button'
							aria-label='previous silde'
							className={classNames(cls.panelBtn, cls.previousBtn)}
							onClick={() => handlePrevious()}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default PanelImages;
