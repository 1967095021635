import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type SelectOption = {
	legend: string;
	value: string;
};

type InputSelectProps = {
	register: any;
	label?: string;
	error: FieldError | undefined;
	options: SelectOption[];
};

const InputSelect: FC<InputSelectProps> = ({
	register,
	label,
	error,
	options,
	children,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		<div className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<select className={formCls.inputSelect} {...register} {...rest}>
				{options.map((o) => (
					<option value={o.value} key={o.value}>
						{o.legend}
					</option>
				))}
			</select>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</div>
	);
};

export default InputSelect;
