import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	logo: {
		position: 'relative',
		width: '100%',
		'& svg path': {
			fill: theme.palette.primary.main,
		},
	},
	homelink: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
	},
}));

const Logo: FC<{ hasHomeLink?: boolean }> = ({ hasHomeLink }) => {
	const cls = useStyles();

	return (
		<figure
			className={cls.logo}
			// id='desktop-logo'
		>
			{hasHomeLink && <Link to='/' className={cls.homelink} />}
			<svg
				version='1.1'
				xmlns='http://www.w3.org/2000/svg'
				x='0px'
				y='0px'
				viewBox='0 0 250 100'
				enableBackground='new 0 0 250 100'
			>
				<path
					d='M243.1,22.3c-1.1-1.8-2-2.5-3.6-2.8c-1.6-0.3-2.7,0.3-3.6,1.7c-4.4,6.9-9.6,13.1-15.9,18.4c-0.9,0.8-1.9,1.5-3.2,2.4
	c0.1-0.7,0.1-0.9,0.2-1.1c1.4-3.4,2.9-6.8,4.3-10.2c0.6-1.4,1.4-2.7,1.5-4.2c0.2-1.9-1.8-3.9-3.8-4.4c-2.5-0.6-3.3-0.1-4.6,2.6
	c-0.2,0.3-0.3,0.7-0.5,1c-1.4,2.2-2.9,4.4-4.3,6.6c-0.6-3.5-2.1-5.9-5.2-6.8c-3.1-0.9-6.2-0.5-8.8,1.7c-5.2,4.3-8.5,10-11.4,15.9
	c-1.1,2.2-3.8,3.8-6.5,3.5c0.2-0.4,0.3-0.9,0.5-1.2c2.4-2.8,4.8-5.5,7.3-8.3c1.3-1.5,3.1-2.9,2.7-5c-0.2-1.2-1.3-2.7-2.3-3.1
	c-1.7-0.7-3.8-1.4-5.4,0.4c-1.7,1.8-3.4,3.5-5,5.3c-1.5,1.7-3,3.5-4.8,5.5c0-0.8-0.1-1.2,0-1.5c1.7-7.8,3.8-17.8,5.8-25.6
	c0.9-3.3-0.6-5.3-2.9-6.7c-2.1-1.3-4.2-0.3-4.6,2.1c-0.3,1.5-0.6,2.9-1,4.4c-1.8,8.3-4.1,18.8-6.1,27.1c-1.1,4.9-2.2,9.8-3.9,14.5
	c-1,2.7-1.6,4.7,0.1,7.2c1.6,2.4,4.9,2.8,6.4,0.3c0.8-1.3,1.5-2.8,2.3-4.1c0.9-1.6,1.7-3.1,4.1-1.6c0.4,0.3,1.2,0.4,2.1,0.3
	c1.2-0.1,4.8-0.8,9.9-2c1.2-0.3,2-0.3,3.2,0.6c1.4,1.2,3.3,2.3,5.1,2.4c4.1,0.2,7.5-1.7,10.4-4.4c2-1.9,4-3.7,6.2-5.8
	c1.7,4.9,4.7,6.8,9.7,5.6c-0.3,0.7-0.5,1.2-0.8,1.6c-5.3,9.1-2.4,4-7.7,13.1c-1.6,2.8-3,5.8-4.3,8.8c-1,2.3-0.2,3.6,2.2,3.9
	c2.8,0.4,4.2-1.1,5.5-3.2c4.2-7.2,4.2-7.1,7.7-13c4.7-7.8,6.2-9.8,10.9-17.6c3.6-5.8,7.2-11.6,10.7-17.4
	C242.8,26.9,244.6,24.7,243.1,22.3z M195.7,38.7c-0.2,0-0.4,0-0.4-0.2c0.4-1,1.9-2.8,2.9-4c0.2-0.2,1.4-0.6,1.9-0.4
	c0.3,0.2,0.1,0.8,0,0.9C199.1,36.4,197.4,37.9,195.7,38.7z M206.2,40.2c0,0-3.8,3.5-5.7,4.8c-0.5,0.3-1.3,0.4-1.3,0.2
	c-0.1-0.4,4.7-4.2,7.5-7.6C206.7,37.6,206.3,38.6,206.2,40.2z M160.2,34.4c-1.8,2.4-3.8,4.6-5.5,7.1c-3.3,4.8-6.6,9.6-9.8,14.4
	c-2.5,3.8-5,7.6-7.5,11.4c-1.8,2.7-4.6,2-6.2,0c-1.2-1.5-1.9-3.3-1.2-5.4c2.3-6.3,4.6-12.6,6.8-19c0.4-1.1,0.6-2.2,0.9-3.3
	c0.4-2,1.6-2.9,3.5-2.4c1.4,0.4,2.3,0.9,3.8,1.7c1.1,0.6-1.1,3.2-0.5,3.4c2-2.8,4.1-5.9,5.5-7.5c1.7-1.9,3.4-3.9,5.3-5.6
	c1.7-1.5,3.4-1.3,4.5-0.1C161.2,30.8,161.5,32.8,160.2,34.4z M69.2,50.4c-0.4-0.5-0.7-1.2-0.7-1.8c0.1-2.7-1.9-3.7-3.8-4.7
	C63.1,43,61.6,44,60.6,45c-3.4,3.4-7,6.8-9.9,10.7c-3.6,4.8-6.6,10.1-10,15.2c-0.3,0.4-1,0.8-1.3,0.7c-1.6-0.5-2.8,0.1-4.2,1
	c-6.1,3.9-12.2,7.7-18.3,11.5c-0.6,0.4-1.3,0.5-2,0.8c-0.1-0.1-0.2-0.3-0.3-0.4c4.8-6.6,10.4-12.6,16.6-17.8c3.2-2.7,6.7-5,10.1-7.5
	c2-1.5,2-3.9,0.2-5.5c-2.1-1.9-4.2-2.1-6.5-0.6c-1,0.6-2,1.3-2.9,2.1c-4.5,3.6-8.8,7.3-12.9,11.4c-0.8,0.8-1.6,1.7-2.4,2.5
	c-2,2.2-3.9,4.5-5.8,6.8c-2,2.6-3.9,5.5-5.3,8.5c-1.5,3.2,0.3,6,2.2,8.4c1.1,1.5,5.2,1.8,7.3,0.6c3.9-2.1,7.7-4.1,11.4-6.5
	c3.5-2.3,6.8-4.9,10.1-7.4c0.9-0.7,1.7-1.5,2.6-2.3c0.5,0.8,0.9,1.5,1.4,2.1c1.5,2.3,3.7,3.8,6.5,3.1c1.5-0.3,3-1.1,4.1-2.1
	c4.1-3.8,8.3-7.7,12-11.9c2.6-2.9,4.5-6.3,6.5-9.6C71.8,55.7,71.1,53,69.2,50.4z M58.5,62.8c-2.2,2.4-4.7,4.6-7,7
	c-0.2,0-0.5-0.1-0.6-0.5c1.9-2.6,3.6-5.4,5.6-7.9c0.3-0.3,1.3-0.3,2-0.2C58.6,61.7,58.8,62.4,58.5,62.8z M133.1,35.1
	c-1.4-1.3-3.2-1.9-5.1-1.3c-0.5,0.2-1.1,0.6-1.4,1c-3.1,4.8-6.8,13.2-9.8,18.1c-0.5,0.9-1.7,0.8-1.4-0.1c0.5-1.9,2.8-6.8,3.8-8.5
	c1.6-2.6,1.6-4.2-0.8-6.7c-2-2.1-4.8-1.6-6.1,1.1c-1.4,3-2.7,6.1-4.1,9.2c-0.2,0.5-0.5,1-0.7,1.6c-0.8-1.5-1.5-3-1.5-4.4
	c0.1-2.6-0.9-4.3-3.5-4.9c-2.2-0.5-4.1,0.2-5.8,1.3c-5.8,3.6-10.3,8.5-13.9,14.4c-1.4,2.3-2.6,4.6-4,6.9c-0.2-0.1-0.4-0.1-0.6-0.2
	c2-6.8,4-13.5,6.1-20.3c1.2-3.8,2.8-7.5,4.1-11.4c0.8-2.6,0-4.2-2.2-5.6c-2-1.2-4.8-0.6-5.4,1.2c-1.2,3.7-2.4,7.4-3.6,11.1
	c-3.9,12.2-6.8,24.6-9.3,37.2c-0.4,2.2-0.3,4.7,0.1,6.9c0.3,2.1,2.7,2.4,4.3,1.1c1.9-1.6,2.1-3.8,2.7-5.9c0.6-2.1,1.2-4.1,1.8-6.6
	c0.7,1.1,1.2,1.7,1.5,2.4c1.1,2.3,2.8,3.4,5.3,3.7c2.2,0.2,4.2-0.4,5.9-1.7c2.4-1.8,4.7-3.8,6.9-5.8c2.1-1.9,4-3.9,6.1-6.1
	c-0.3,2-0.7,3.8-0.8,5.6c-0.2,2.3,1.1,4.1,2.7,5.5c2,1.7,4.6,1.1,6.4-1.3c2.3-2.9,4.5-5.9,6.9-9c0.2,2.4,0.3,4.6,0.7,6.7
	c0.4,2,1.6,2.5,3.3,1.5c0.9-0.6,1.9-1.6,2.2-2.6c1.2-3.5,1.9-7.1,3.1-10.5c2.1-6.1,4.4-12.2,6.7-18.2
	C134.5,38.5,134.5,36.6,133.1,35.1z M95,58.8c-2.4,2.4-4.9,4.7-7.3,7.1C87,66,87,65.7,86.9,65.2c1.6-2.6,3.4-6.3,5.2-8.8
	c0.4-0.5,2.1-0.6,3.1,0C95.6,56.7,95.2,58.6,95,58.8z'
				/>
			</svg>
		</figure>
	);
};

export default Logo;
