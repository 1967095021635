export const Facebook = (url: string = location.href) => {
	const width = 400;
	const height = 300;
	// Allow for borders.
	const leftPosition = window.screen.width / 2 - (width / 2 + 10);
	// Allow for title and status bars.
	const topPosition = window.screen.height / 2 - (height / 2 + 50);

	const windowFeatures = `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"`;
	window.open(
		`http://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`,
		'_blank',
		windowFeatures
	);
	return undefined;
	// return void 0;
};

export const Twitter = () => {
	const width = 400;
	const height = 300;
	// Allow for borders.
	const leftPosition = window.screen.width / 2 - (width / 2 + 10);
	// Allow for title and status bars.
	const topPosition = window.screen.height / 2 - (height / 2 + 50);

	const windowFeatures = `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"`;
	// eslint-disable-next-line no-restricted-globals
	const u = location.href;
	const t = document.title;

	// tslint:disable-next-line:max-line-length
	window.open(
		`https://twitter.com/share?url=${encodeURIComponent(
			u
		)}&text=${encodeURIComponent(t)}`,
		'sharer',
		windowFeatures
	);
	return undefined;
	// return void 0;
};

export const Google = () => {
	const width = 400;
	const height = 300;
	// Allow for borders.
	const leftPosition = window.screen.width / 2 - (width / 2 + 10);
	// Allow for title and status bars.
	const topPosition = window.screen.height / 2 - (height / 2 + 50);

	const windowFeatures = `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"`;
	// eslint-disable-next-line no-restricted-globals
	const u = location.href;
	const t = document.title;

	// tslint:disable-next-line:max-line-length
	window.open(
		`https://plus.google.com/share?url=${encodeURIComponent(
			u
		)}&t=${encodeURIComponent(t)}`,
		'sharer',
		windowFeatures
	);
	return undefined;
	// return void 0;
};

export const Tumblr = () => {
	const width = 400;
	const height = 300;
	// Allow for borders.
	const leftPosition = window.screen.width / 2 - (width / 2 + 10);
	// Allow for title and status bars.
	const topPosition = window.screen.height / 2 - (height / 2 + 50);

	const windowFeatures = `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"`;
	// eslint-disable-next-line no-restricted-globals
	const u = location.href;
	const t = document.title;

	window.open(
		`https://www.tumblr.com/share/link?url=${encodeURIComponent(
			u
		)}&name=${encodeURIComponent(t)}`,
		'sharer',
		windowFeatures
	);
	return undefined;
	// return void 0;
};

export const Pinterest = (url: string = location.href) => {
	const width = 400;
	const height = 300;
	// Allow for borders.
	const leftPosition = window.screen.width / 2 - (width / 2 + 10);
	// Allow for title and status bars.
	const topPosition = window.screen.height / 2 - (height / 2 + 50);

	const windowFeatures = `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no"`;
	const t = document.title;

	window.open(
		`https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
			url
		)}&description=${encodeURIComponent(t)}`,
		'sharer',
		windowFeatures
	);
	return undefined;
	// return void 0;
};
export const Mail = (url: string = location.href) => {
	// eslint-disable-next-line no-restricted-globals
	window.location.href = `mailto:?body=${url}`;
};
