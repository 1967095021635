export const genderOptions = [
	{ legend: 'Herr', value: 'Male' },
	{ legend: 'Frau', value: 'Female' },
	{ legend: 'Andere', value: 'Other' },
];

export const countryOptions = [
	{ legend: 'Bitte wählen', value: '' },
	{ legend: 'CH', value: 'CH' },
	{ legend: 'DE', value: 'DE' },
	{ legend: 'FR', value: 'FR' },
];

const todayYears = new Date().getFullYear();
export const maxDate = `${todayYears - 14}-12-31`;
export const minDate = `${todayYears - 25}-01-01`;

export const commingFromOptions = [
	{ legend: 'Bitte wählen', value: '' },
	{ legend: 'Digitale Medien (FB, Insta)', value: '0001' },
	{ legend: 'Inserat/Plakate/Flyer', value: '0004' },
	{ legend: 'Freunde', value: '0003' },
	{ legend: 'Eltern', value: '0002' },
	{ legend: 'Sonstige', value: '0099' },
];
