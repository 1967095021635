import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormStyles from 'src/components/Form/FormStyles';
import InputText from 'src/components/Form/InputText';
import InputSelect from 'src/components/Form/InputSelect';
import InputDate from 'src/components/Form/InputDate';
import InputKantonDropdown from 'src/components/Form/InputKantonDropdown';
import InputFile from 'src/components/Form/InputFile';
import InputCheckbox from 'src/components/Form/InputCheckbox';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import spacing from 'src/constants/spacing';
import InputPassword from 'src/components/Form/InputPassword';
import {
	minDate,
	maxDate,
	genderOptions,
	commingFromOptions,
	countryOptions,
} from 'src/components/Form/InputValues';
import { useUsers } from 'src/@context/Users';
import InputEmail from 'src/components/Form/InputEmail';
import { Link, useHistory } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import LoadingScreen from 'src/components/LoadingScreen';
import Button from '../components/Button';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100%',
		},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
		link: {
			textDecoration: 'underline',
			'&:hover': {
				textDecoration: 'none',
			},
		},
		warnningText: {
			margin: `${spacing.Gutter.Desktop}px 0 ${spacing.Gutter.Desktop / 2}px 0`,
		},
	})
);

const SignUp: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const { signUp, loading } = useUsers();
	const { app } = useApp();
	const form = useRef<HTMLFormElement>(null);
	const history = useHistory();
	const { setMessageScreen } = useApp();

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<
		SignupBinding & {
			Picture: FileList;
			PasswordConfirm: string;
			AcceptPrivacyPolicy: string;
		}
	>();

	const onSubmit = async () => {
		const { current } = form;
		if (!current) return;

		const { error, data: user } = await signUp(current);

		if (error) {
			// TODO: translations
			setMessageScreen({
				type: 'Error',
				message: error.Message || 'error while signin, try again',
			});
		}

		if (user) {
			const timeleft = user.TimeLeft;
			// TODO: GA events
			if (timeleft <= 0) {
				history.push(`/pay/${user.UserName}`);
			} else if (user.CalculatedAge < 15) {
				history.push('/fourteen');
			} else {
				history.push(`/profil`);
			}
		}
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<article className={formCls.modal}>
					<h2 className={classNames(formCls.title, formCls.centered)}>
						{app?.Config?.SignupPageTitle}
					</h2>
					<div
						className={classNames(formCls.littleText, formCls.centered)}
						dangerouslySetInnerHTML={{
							__html: app?.Config?.SignupPageText || '',
						}}
					/>
					<form onSubmit={handleSubmit(onSubmit)} ref={form}>
						<div className={formCls.section}>
							<InputText
								error={errors.FirstName}
								label='Vorname'
								register={register('FirstName', {
									required: 'This is required',
								})}
							/>
							<InputText
								error={errors.LastName}
								label='Nachname'
								register={register('LastName', {
									required: 'This is required',
								})}
							/>
						</div>
						<div className={formCls.section}>
							<InputSelect
								error={errors.Gender}
								label='Geschlecht'
								options={genderOptions}
								register={register('Gender', { required: 'This is required' })}
							/>
							<InputDate
								error={errors.BirthDate}
								label='Geburtsdatum'
								max={maxDate}
								min={minDate}
								register={register('BirthDate', {
									required: 'This is required',
								})}
							/>
							<InputEmail
								error={errors.Email}
								label='Mailadresse'
								register={register('Email', { required: 'This is required' })}
							/>
						</div>
						<div className={formCls.section}>
							<InputText
								error={errors.Address}
								label='Adresse'
								register={register('Address', { required: 'This is required' })}
							/>
							<InputText
								error={errors.COAddress}
								label='c/o Adresse'
								register={register('COAddress')}
							/>
							<InputText
								error={errors.ZipCode}
								label='Postleitzahl'
								register={register('ZipCode', { required: 'This is required' })}
							/>
							<InputText
								error={errors.City}
								label='Ort'
								register={register('City', { required: 'This is required' })}
							/>
							<InputKantonDropdown
								error={errors.Province}
								label='Kanton'
								register={register('Province', {
									required: 'This is required',
								})}
							/>
							<InputSelect
								error={errors.Country}
								label='Land'
								options={countryOptions}
								register={register('Country', { required: 'This is required' })}
							/>
							<div className={cls.warnningText}>
								Für den Ausweis brauchen wir ein Bild von deinem Kopf auf dem
								man dich gut wiedererkennt. Das Bild kann nicht mehr verändert
								werden.
							</div>
							<InputFile
								error={errors.Picture}
								label='Picture'
								register={register('Picture', { required: 'This is required' })}
							/>
						</div>
						<div className={formCls.section}>
							<InputSelect
								error={errors.UserDefinedCode5}
								label='Wie bist du auf colourkey aufmerksam geworden?'
								options={commingFromOptions}
								register={register('UserDefinedCode5', {
									required: 'This is required',
								})}
							/>
							<InputCheckbox
								defaultChecked
								error={errors.AddToNewsletter}
								id='sign-up-add-to-newsletter'
								label='News und Specials per Email erhalten'
								register={register('AddToNewsletter')}
							/>
							<InputCheckbox
								error={errors.AcceptPrivacyPolicy}
								id='sign-up-accept-privacy-policy'
								label={
									<>
										Ich habe die{' '}
										<Link
											to='/datenschutz'
											className={cls.link}
											target='_blank'
										>
											Datenschutzerklärung
										</Link>{' '}
										gelesen, verstanden und akzeptiert
									</>
								}
								register={register('AcceptPrivacyPolicy', {
									required: 'Bitte akzeptieren',
								})}
							/>
						</div>
						<div className={formCls.section}>
							<InputPassword
								error={errors.Password}
								label='Passwort'
								register={register('Password', {
									required: 'This is required',
									minLength: 6,
								})}
							/>
							<InputPassword
								error={errors.PasswordConfirm}
								label='Passwort bestätigen'
								register={register('PasswordConfirm', {
									required: 'This is required',
									minLength: 6,
									validate: (value) =>
										value === getValues('Password') ||
										'Passwords needs to match',
								})}
							/>
						</div>
						<Button variant='formSubmitButton' type='submit'>
							Weiter
						</Button>
					</form>
				</article>
			</div>
		</PageLayout>
	);
};

export default SignUp;
