function googlePlaceToAddress(place: google.maps.places.PlaceResult) {
	const lat = place.geometry?.location.lat();
	const lng = place.geometry?.location.lng();
	const street = place.address_components?.find((component) =>
		component.types.includes('route')
	)?.long_name;
	const streetNumber = place.address_components?.find((component) =>
		component.types.includes('street_number')
	)?.long_name;
	const address = [street, streetNumber].filter((v) => !!v).join(' ');
	const countryCode = place.address_components?.find((component) =>
		component.types.includes('country')
	)?.short_name;
	const province =
		countryCode === 'CH'
			? place.address_components?.find((component) =>
					component.types.includes('administrative_area_level_1')
			  )?.short_name
			: 'andere';
	const city = place.address_components?.find((component) =>
		component.types.includes('locality')
	)?.long_name;
	const zipCode = place.address_components?.find((component) =>
		component.types.includes('postal_code')
	)?.long_name;

	return {
		lat,
		lng,
		address,
		countryCode,
		province,
		city,
		zipCode,
	};
}

export default googlePlaceToAddress;
