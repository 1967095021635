import React, { FC, useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type InputDateProps = {
	register: any;
	label?: string;
	max?: string;
	min?: string;
	error: FieldError | undefined;
};

const InputDate: FC<InputDateProps> = ({
	register,
	label,
	error,
	children,
}) => {
	const formCls = FormStyles();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<input type='date' className={formCls.inputText} {...register} />
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputDate;
