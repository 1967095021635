import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useApp } from 'src/@context/App';
import mediaQueries from 'src/constants/mediaQueries';
import { useUsers } from 'src/@context/Users';
import spacing from 'src/constants/spacing';
import { createPortal } from 'react-dom';
import DropdownMenu from './DropdownMenu';
import MenuItem, { StaticMenu } from './MenuItem';
import MemberMenu from './MemberMenu';
import PageLayout from './PageLayout';
import AdminMenu from './AdminMenu';
import usePortalElement from '../utils/usePortalElement';
import Header from './Header';

const useStyles = makeStyles(
	() => ({
		pageLayoutWrapper: {
			position: 'absolute',
			zIndex: 100,
			inset: 0,
		},
		header: {
			zIndex: 1,
		},
		root: {
			width: '100%',
			height: '100%',
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			[mediaQueries.Mobile]: {
				padding: `0 ${spacing.Gutter.Mobile}px`,
			},
		},
		content: {
			flex: '1 1 100%',
			overflow: 'auto',
			display: 'flex',
		},
		menuWrapper: {
			display: 'flex',
			flexDirection: 'column',
			margin: 'auto',
		},
	}),
	{ name: 'Menu' }
);

const Menu: FC = () => {
	const cls = useStyles();
	const { app, menuOpened } = useApp();
	const { admin } = useUsers();
	const portalElement = usePortalElement();

	if (!menuOpened || !portalElement) {
		return null;
	}

	return createPortal(
		<div className={cls.pageLayoutWrapper}>
			<Header variant='themeTransparent' className={cls.header} />
			<PageLayout fullWidth background='default'>
				<div className={cls.root}>
					<section className={cls.container}>
						<div className={cls.content}>
							<nav className={cls.menuWrapper}>
								<MenuItem
									menu={
										{
											type: 'static',
											url: '/',
											title: 'Angebote',
										} as StaticMenu
									}
								/>
								<MemberMenu />
								<MenuItem
									menu={
										{
											type: 'static',
											url: '/anbieterwerden',
											title: 'Anbieter werden',
										} as StaticMenu
									}
								/>
								{app &&
									app.Menus.map((menu) => {
										switch (menu.type) {
											case 'PanelPage':
												return <MenuItem menu={menu} key={menu.UID} />;

											case 'DropdownMenu':
												return <DropdownMenu {...menu} key={menu.UID} />;

											default:
												return null;
										}
									})}
								{admin && <AdminMenu />}
							</nav>
						</div>
					</section>
				</div>
			</PageLayout>
		</div>,
		portalElement
	);
};

export default Menu;
