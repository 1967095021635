import React, { FC, FormEvent, useCallback, useState } from 'react';
import { useOffers } from 'src/@context/Offers';
import { useApp } from 'src/@context/App';
import { useUsers } from 'src/@context/Users';
import useStyles from './Filters.styles';
import FiltersAdmin from './FiltersAdmin';
import FilterDropdown from './FilterDropdown';
import { FilterIcon } from './FilterIcons';
import FilterTabButton from './FilterTabButton';

type TFilter = 'rational' | 'search';

const Filters: FC = () => {
	const cls = useStyles();
	const { app } = useApp();
	const { admin } = useUsers();
	const { count, filters, setFilters } = useOffers();

	const [opened, setOpened] = useState(false);
	const [query, setQuery] = useState(filters.Query || '');
	const [filterType, setFilterType] = useState<TFilter>(
		filters.Query ? 'search' : 'rational'
	);

	const togglerFilters = useCallback(
		(filter: TFilter) => {
			if (opened && filter === filterType) {
				setOpened(false);
			} else {
				setFilterType(filter);
				setOpened(true);
			}
		},
		[filterType, opened]
	);

	const toggleCategory = useCallback(
		({ UID, SubCategories }: CategoryDto) => {
			const subCategories = SubCategories.map((s) => s.UID);
			const active =
				filters.Categories?.includes(UID) ||
				filters.SubCategories?.find((s) => subCategories.includes(s));
			if (active) {
				setFilters({
					...filters,
					Categories: filters.Categories?.filter((c) => c !== UID),
					SubCategories: filters.SubCategories?.filter(
						(s) => !subCategories?.includes(s)
					),
				});
			} else {
				setFilters({
					...filters,
					Categories: [...(filters.Categories || []), UID],
					SubCategories: filters.SubCategories?.filter(
						(s) => !subCategories?.includes(s)
					),
				});
			}
		},
		[filters, setFilters]
	);

	const toggleSubCategory = useCallback(
		(subCategoryId: string, { UID, SubCategories }: CategoryDto) => {
			const subCategories = SubCategories.map((s) => s.UID);
			const others =
				filters.SubCategories?.filter((s) => !subCategories?.includes(s)) || [];
			setFilters({
				...filters,
				Categories: filters.Categories?.filter((c) => c !== UID),
				SubCategories: [...others, subCategoryId],
			});
		},
		[filters, setFilters]
	);

	const submit = (e: FormEvent) => {
		e.preventDefault();
		setFilters({ Query: query });
	};

	const activeCategories = [
		...(filters.Categories || []),
		...(filters.SubCategories || []).map(
			(s) =>
				app?.Config.Categories.find((c) =>
					c.SubCategories.find(({ UID }) => s === UID)
				)?.UID
		),
	];

	return (
		<section
			className={cls.root}
			data-behavior='filters-container'
			data-open={opened}
		>
			<div className={cls.tabsContainer}>
				<FilterTabButton
					active={filterType === 'rational' || !filterType}
					opened={opened}
					onClick={() => togglerFilters('rational')}
				>
					Filter
				</FilterTabButton>
				<FilterTabButton
					active={filterType === 'search'}
					opened={opened}
					title='Search'
					className={cls.searchTab}
					onClick={() => togglerFilters('search')}
				>
					<svg
						width={28}
						height={28}
						viewBox='0 0 28 28'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						className={cls.searchIcon}
					>
						<path
							d='M17.863 18.754a8.617 8.617 0 100-17.233 8.617 8.617 0 000 17.233z'
							stroke='#000'
							strokeWidth={3}
						/>
						<path
							d='M2.15 25.85l9.124-9.124'
							stroke='#000'
							strokeWidth={3}
							strokeLinecap='round'
						/>
					</svg>
				</FilterTabButton>
			</div>
			<div
				className={cls.filtersContainer}
				data-behavior='filters-toggler-container'
				data-open={opened}
			>
				<div
					className={cls.filters}
					data-behavior='filters-toggler-container-inner'
				>
					{admin && <FiltersAdmin />}
					{filterType === 'rational' && (
						<div className={cls.filtersContent}>
							<section className={cls.filtersSection}>
								<div className={cls.mainCategories}>
									{app?.Config.Categories.map((cat, i) => (
										<button
											// eslint-disable-next-line react/no-array-index-key
											key={`${cat.Name}-${i}`}
											type='button'
											className={cls.filterCategoryBtn}
											data-icon={cat.Icon}
											data-active={activeCategories.includes(cat.UID)}
											onClick={() => toggleCategory(cat)}
										>
											<span className={cls.iconContainer}>
												<FilterIcon category={cat.Icon} />
											</span>
											<span className={cls.filterBtnLabel}>{cat.Name}</span>
										</button>
									))}
								</div>
								<div className={cls.dropdownsContainer}>
									{/* // TODO: in prod subcats only appear when main cat is selected */}
									{app?.Config.Categories.filter(
										({ SubCategories }) => SubCategories.length > 1
									).map((cat) => (
										<div
											className={cls.dropdownContent}
											// data-active={[cat.UID] || app?.Config.Categories}
											data-active={
												filters?.Categories?.includes(cat.UID) ||
												filters?.SubCategories?.find((s) =>
													cat.SubCategories.map((dto) => dto.UID).includes(s)
												) ||
												false
											}
											key={cat.UID}
										>
											<FilterDropdown
												label={cat.Name}
												values={[
													{ name: 'Alle', value: '' },
													...cat.SubCategories.map(
														({ Name: name, UID: value }) => ({
															value,
															name,
														})
													),
												]}
												value={
													cat.SubCategories.find(({ UID }) =>
														filters.SubCategories?.includes(UID)
													)?.UID || ''
												}
												onSelect={(subcatId) =>
													toggleSubCategory(subcatId, cat)
												}
											/>
										</div>
									))}
								</div>
							</section>
							<section className={cls.filtersSection}>
								<div className={cls.generalFiltersContainer}>
									<button
										type='button'
										className={cls.filterBtn}
										onClick={() =>
											setFilters({
												...filters,
												Sustainability: !filters.Sustainability,
											})
										}
										data-active={filters.Sustainability}
									>
										Nachhaltig
									</button>
									<button
										type='button'
										className={cls.filterBtn}
										onClick={() =>
											setFilters({ ...filters, Gratis: !filters.Gratis })
										}
										data-active={filters.Gratis}
									>
										Gratis
									</button>
									<button
										type='button'
										className={cls.filterBtn}
										onClick={() =>
											setFilters({ ...filters, Special: !filters.Special })
										}
										data-active={filters.Special}
									>
										Specials
									</button>
									<button
										type='button'
										className={cls.filterBtn}
										onClick={() =>
											setFilters({ ...filters, Plus18: !filters.Plus18 })
										}
										data-active={filters.Plus18}
									>
										+18
									</button>
									<button
										type='button'
										className={cls.filterBtn}
										onClick={() =>
											setFilters({ ...filters, Likes: !filters.Likes })
										}
										data-active={filters.Likes}
									>
										Likes
									</button>
								</div>
							</section>
						</div>
					)}

					{filterType === 'search' && (
						<div className={cls.filtersContent}>
							<form className={cls.formSearch} onSubmit={submit}>
								<input
									className={cls.searchInputText}
									onChange={(e) => setQuery(e.target.value)}
									value={query}
									type='text'
									name='search'
									// eslint-disable-next-line jsx-a11y/no-autofocus
									autoFocus
									placeholder='Suchtext eingeben'
								/>
								<button type='submit' className={cls.searchInputSubmit}>
									Suchen
								</button>
							</form>
						</div>
					)}
					<div className={cls.generalFiltersContainer}>
						<div className={cls.offersBtn}>{count} Angebote</div>
						<button
							type='button'
							aria-label='Remove filters'
							className={cls.resetOffersBtn}
							data-behavior='remove-filters'
							onClick={() =>
								setFilters({
									Categories: [],
									SubCategories: [],
									Gratis: false,
									Special: false,
									Plus18: false,
								})
							}
						/>
					</div>
				</div>
				<div className={cls.filtersTogglerContainer}>
					{app && app.Config.WarningActive && (
						<div className={cls.warningBanner}>
							{app.Config.WarningText && (
								<>
									<div
										style={{
											animationDuration:
												`${app.Config?.WarningTextTime}s` || '60s',
										}}
										className={cls.warningText}
									>
										<span>{app.Config.WarningText}</span>
										<span>{app.Config.WarningText}</span>
										<span>{app.Config.WarningText}</span>
									</div>
									<div
										style={{
											animationDuration:
												`${app.Config?.WarningTextTime}s` || '60s',
										}}
										className={cls.warningText}
									>
										<span>{app.Config.WarningText}</span>
										<span>{app.Config.WarningText}</span>
										<span>{app.Config.WarningText}</span>
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default Filters;
