import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormStyles from 'src/components/Form/FormStyles';
import InputDate from 'src/components/Form/InputDate';
import InputKantonDropdown from 'src/components/Form/InputKantonDropdown';
import InputSelect from 'src/components/Form/InputSelect';
import InputText from 'src/components/Form/InputText';
import InputEmail from 'src/components/Form/InputEmail';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import {
	minDate,
	maxDate,
	commingFromOptions,
	countryOptions,
} from 'src/components/Form/InputValues';
import { Link, useHistory } from 'react-router-dom';
import { useUsers } from 'src/@context/Users';
import { useApp } from 'src/@context/App';
import LoadingScreen from 'src/components/LoadingScreen';
import InputCheckbox from '../components/Form/InputCheckbox';

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
		link: {
			textDecoration: 'underline',
			'&:hover': {
				textDecoration: 'none',
			},
		},
	})
);

const Gift: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const history = useHistory();
	const { setMessageScreen } = useApp();
	const { gift, loading } = useUsers();
	const form = useRef<HTMLFormElement>(null);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<GiftBinding & { AcceptPrivacyPolicy: string }>();
	const onSubmit = async (data: GiftBinding) => {
		const { current } = form;
		if (!current) return;

		const { error, data: user } = await gift(data);

		if (error) {
			// TODO: translations
			setMessageScreen({
				type: 'Error',
				message: error.Message || 'error, try again',
			});
		}

		if (user) {
			history.push(`/pay/${user.UserName.trim()}`);
		}
	};

	if (loading) {
		<LoadingScreen />;
	}

	return (
		<PageLayout>
			<Header />
			<article className={formCls.modal}>
				<h2 className={classNames(formCls.title, formCls.centered)}>
					Mitglied werden
				</h2>
				<div className={classNames(formCls.littleText, formCls.centered)}>
					1 Jahr CHF 30.– / 2 Jahre CHF 50.– / 5 Jahre CHF 120.– <br />
					<br />
					Wer anderen eine Freude machen will, kann den colourkey verschenken.
					Bei Online-Bezahlung kann der colourkey-Zugang mit dem erhaltenen
					Benutzernamen und Token direkt weiterverschenkt und von der
					beschenkten Person selbst freigeschaltet werden. Wird die Rechnung per
					Einzahlungsschein beglichen, kann sich die beschenkte Person nach der
					Begleichung der Rechnung online einloggen.
				</div>
				<form onSubmit={handleSubmit(onSubmit)} ref={form}>
					<div className={formCls.section}>
						<InputText
							error={errors.NotificationFirstName}
							label='Vorname des Gönners'
							register={register('NotificationFirstName', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={errors.NotificationLastName}
							label='Nachname des Gönners'
							register={register('NotificationLastName', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputEmail
							error={errors.NotificationEmail}
							label='E-mail des Gönners'
							register={register('NotificationEmail', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={errors.FirstName}
							label='Vorname des Beschenkten'
							register={register('FirstName', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={errors.LastName}
							label='Nachname des Beschenkten'
							register={register('LastName', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={errors.Address}
							label='Adresse des Beschenkten'
							register={register('Address', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={errors.ZipCode}
							label='Postleitzahl des Beschenkten'
							register={register('ZipCode', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputText
							error={errors.City}
							label='Ort des Beschenkten'
							register={register('City', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputKantonDropdown
							label='Kanton des Beschenkten'
							error={errors.Province}
							register={register('Province', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputSelect
							error={errors.Country}
							label='Land'
							options={countryOptions}
							register={register('Country', {
								required: 'Bitte ausfüllen',
							})}
						/>
						<InputDate
							error={errors.BirthDate}
							label='Geburtsdatum'
							max={maxDate}
							min={minDate}
							register={register('BirthDate', {
								required: 'Bitte ausfüllen',
							})}
						/>
					</div>
					<div className={formCls.section}>
						<InputSelect
							error={errors.UserDefinedCode5}
							label='Wie bist du auf colourkey aufmerksam geworden?'
							options={commingFromOptions}
							register={register('UserDefinedCode5', {
								required: 'Bitte ausfüllen',
							})}
						/>
					</div>
					<div className={formCls.section}>
						<InputCheckbox
							error={errors.AcceptPrivacyPolicy}
							id='sign-up-accept-privacy-policy'
							label={
								<>
									Ich habe die{' '}
									<Link to='/datenschutz' className={cls.link} target='_blank'>
										Datenschutzerklärung
									</Link>{' '}
									gelesen, verstanden und akzeptiert
								</>
							}
							register={register('AcceptPrivacyPolicy', {
								required: 'Bitte akzeptieren',
							})}
						/>
					</div>
					<button type='submit' className={formCls.submit}>
						Weiter
					</button>
				</form>
			</article>
		</PageLayout>
	);
};

export default Gift;
