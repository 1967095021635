import { makeStyles, createStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';

export default makeStyles((theme) =>
	createStyles({
		cardItem: {
			position: 'relative',
			[mediaQueries.Mobile]: {
				height: '100%',
				width: '100%',
				borderRadius: 10,
				backgroundColor: 'black',
				overflow: 'hidden',
			},
			[mediaQueries.AfterMobile]: {
				borderRadius: 8,
				overflow: 'hidden',
			},
		},
		cardItemFigure: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		cardItemImage: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			OObjectFit: 'cover',
			objectFit: 'cover',
			fontFamily: 'object-fit: cover;',
			OObjectPosition: 'center',
			objectPosition: 'center',
		},
		link: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1,
		},
	})
);
