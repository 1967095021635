import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import Footer from './Footer';

const useStyles = makeStyles(
	() =>
		createStyles({
			root: {
				position: 'relative',
				width: '100%',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			},
			main: {
				backgroundColor: 'black',

				[mediaQueries.Mobile]: {
					height: '100%',
				},

				[mediaQueries.AfterMobile]: {
					overflow: 'hidden',
					flex: '1 1 100%',
					position: 'relative',
				},
			},
			footer: {
				[mediaQueries.Mobile]: {
					display: 'none',
				},
				[mediaQueries.AfterMobile]: {
					flex: `0 0 ${spacing.HeightDesktopFooter}`,
				},
			},
		}),
	{ name: 'Layout' }
);

const Layout: FC = ({ children }) => {
	const cls = useStyles();

	return (
		<div className={cls.root}>
			<main className={cls.main}>{children}</main>
			<div className={cls.footer}>
				<Footer />
			</div>
		</div>
	);
};

export default Layout;
