import { createMuiTheme } from '@material-ui/core/styles';

const GreenTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#A3C038',
			light: '#F0F1C1',
			contrastText: '#000',
		},
		text: {
			primary: '#A3C038',
		},
		error: {
			main: '#fff',
		},
	},
});

export default GreenTheme;
