export function getMaxSocialScore(offer: OfferDto) {
	switch (offer.Icon) {
		case 'Music':
			return 11;
		case 'Sport':
			return 10;
		case 'Museum':
			return 11;
		case 'Theater':
			return 11;
		case 'Shopping':
			return 12;
		default:
			return 10;
	}
}

export function getMaxEcologyScore(offer: OfferDto) {
	switch (offer.Icon) {
		case 'Shopping':
			return 13;
		default:
			return 4;
	}
}

/* eslint-disable import/prefer-default-export */
interface Score {
	show: boolean;
	count: number;
}
interface OfferScoreSummary {
	numLikes: number;
	socialScore: Score;
	ecologyScore: Score;
	economyScore: Score;
}

export function getMockScoresForOffer(offer: OfferDto): OfferScoreSummary {
	const socialScore = offer.SocialRating;
	const ecologyScore = offer.EcologyRating;
	const economyScore = offer.EconomyRating;

	return {
		numLikes: offer.LikesCount,
		socialScore: {
			count: socialScore,
			show: socialScore > 0,
		},
		ecologyScore: {
			count: ecologyScore,
			show: ecologyScore > 0,
		},
		economyScore: {
			count: economyScore,
			show: economyScore > 0,
		},
	};
}
