import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import { useForm } from 'react-hook-form';
import Header from 'src/components/Header';
import FormStyles from 'src/components/Form/FormStyles';
import InputPassword from 'src/components/Form/InputPassword';
import { useHistory } from 'react-router-dom';
import useQuery from 'src/utils/useQuery';
import { useApp } from 'src/@context/App';
import { useApi } from 'src/@context/Api';
import LoadingScreen from 'src/components/LoadingScreen';
import Button from '../components/Button';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100%',
		},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
		forgotLink: {
			textDecoration: 'underline',
		},
	})
);

const ResetPasswordPage: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const history = useHistory();
	const query = useQuery();
	const { post } = useApi();
	const [loading, setLoading] = useState(false);
	const { setMessageScreen } = useApp();

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm<{
		Password: string;
		PasswordConfirm: string;
	}>({
		defaultValues: {
			Password: '',
			PasswordConfirm: '',
		},
	});

	const onSubmit = async (data: {
		Password: string;
		PasswordConfirm: string;
	}) => {
		setLoading(true);
		const id = query.get('id');
		const token = query.get('code');

		if (!id || !token) {
			setLoading(false);
			setMessageScreen({
				type: 'Error',
				message: 'Not Id or Token, please try again later',
			});
			return;
		}

		const toSend = {
			...data,
			Id: id.replaceAll('+', ' '),
			Token: token,
		};

		const { data: success, error } = await post<
			ApiResponse<{ messeage: string }>
		>(`/2.0/account/reset`, toSend);

		if (error && !success) {
			setLoading(false);
			setMessageScreen({
				type: 'Error',
				message: error?.Message || 'Not Id or Token, please try again later',
			});

			return;
		}

		setLoading(false);
		setMessageScreen({
			type: 'Success',
			message: 'Passwort erfolgreich geändert.',
		});
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				<article className={formCls.modal}>
					<h4 className={classNames(formCls.title, formCls.centered)}>
						Passwort zurücksetzen
					</h4>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className={formCls.section}>
							<InputPassword
								error={errors.Password}
								label='Passwort'
								register={register('Password', {
									required: 'This is required',
									minLength: 6,
								})}
							/>
							<InputPassword
								error={errors.PasswordConfirm}
								label='Passwort bestätigen'
								register={register('PasswordConfirm', {
									required: 'This is required',
									minLength: 6,
									validate: (value) =>
										value === getValues('Password') ||
										'Passwords needs to match',
								})}
							/>
						</div>
						<Button variant='formSubmitButton' type='submit'>
							Weiter
						</Button>
					</form>
				</article>
			</div>
		</PageLayout>
	);
};

export default ResetPasswordPage;
