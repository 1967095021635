import { makeStyles, createStyles } from '@material-ui/core';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import fontStyles from '../../constants/fontStyles';

const FormStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			position: 'relative',
			overflowX: 'hidden',

			[mediaQueries.Mobile]: {
				// padding: `80px ${spacing.Gutter.Mobile}px 0 ${spacing.Gutter.Mobile}px`,
				padding: `0 ${spacing.Gutter.Mobile}px ${spacing.Gutter.Mobile * 2}px ${
					spacing.Gutter.Mobile
				}px`,
			},
			[mediaQueries.AfterMobile]: {
				// padding: `100px ${spacing.Gutter.Desktop * 2}px 0 ${
				// 	spacing.Gutter.Desktop * 2
				// }px`,
				padding: `0 ${spacing.Gutter.Desktop * 2}px`,
			},
		},
		navLink: {
			position: 'relative',
			top: 14,
			lineHeight: 1,

			'&$closeIcon': {
				fontSize: rem(35, bodyFontSize.Mobile),
				left: 0,
			},
			'&$menuIcon': {
				fontSize: '1.5rem',
				right: 0,
			},

			// [mediaQueries.Mobile]: {
			// 	'&$closeIcon': {
			// 		left: spacing.Gutter.Mobile,
			// 	},
			// 	'&$menuIcon': {
			// 		right: spacing.Gutter.Mobile,
			// 	},
			// },

			// [mediaQueries.AfterMobile]: {
			// 	'&$closeIcon': {
			// 		left: spacing.Gutter.Desktop * 2,
			// 	},
			// 	'&$menuIcon': {
			// 		right: spacing.Gutter.Desktop * 2,
			// 	},
			// },
		},
		closeIcon: {},
		menuIcon: {},
		hidden: {},
		title: {
			...fontStyles.title,
			textAlign: 'center',
			marginBottom: rem(20, bodyFontSize.Mobile),
		},
		littleText: {
			...fontStyles.defaultText,
			marginBottom: spacing.Gutter.Mobile * 2,
		},
		centered: {
			textAlign: 'center',
		},
		subFormLinks: {
			textAlign: 'center',
			...fontStyles.smallText,
			'& a': {
				textDecoration: 'underline',
				'&:hover': {
					textDecoration: 'none',
				},
			},
		},
		inputContainer: {
			display: 'block',

			[mediaQueries.Mobile]: {
				marginBottom: spacing.Gutter.Mobile / 2,
			},
			[mediaQueries.AfterMobile]: {
				marginBottom: spacing.Gutter.Desktop / 2,
			},
		},
		label: {
			...fontStyles.defaultText,
			display: 'block',
			marginBottom: spacing.Gutter.Mobile / 4,
			cursor: 'pointer',

			'&$hidden': {
				display: 'none',
			},
			'&[data-hidden="true"]': {
				display: 'none',
			},
			'[data-error="true"] &': {
				color: theme.palette.error.main,
			},

			'&$inputCheckboxLabel': {
				margin: 0,
			},
		},
		inputText: {
			...fontStyles.defaultText,
			backgroundColor: 'white',
			display: 'block',
			width: '100%',
			minHeight: spacing.InputMinHeight,
			padding: spacing.Gutter.Mobile / 2,
			color: 'black',
			border: '1px solid black',
			borderRadius: 8,
			outline: 'transparent',
			cursor: 'pointer',

			'[data-error="true"] &': {
				backgroundColor: 'transparent',
				borderColor: theme.palette.error.main,
				color: theme.palette.error.main,
			},
			'&::placeholder': {
				color: 'rgba(0, 0, 0, 0.25)',
			},
		},
		error: {
			marginTop: spacing.Gutter.Mobile / 4,
			color: theme.palette.error.main,
		},
		/** Use for form-level (not field-level) error messages */
		formError: {
			marginTop: spacing.Gutter.Mobile / 4,
			...fontStyles.defaultText,
			color: theme.palette.error.main,
		},
		submit: {
			color: theme.palette.text.primary,
			textAlign: 'center',
			flex: 1,
			height: 54,
			fontWeight: 'bold',
			fontSize: rem(20, bodyFontSize.Desktop),
			letterSpacing: '0.1em',
			textTransform: 'uppercase',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			marginBottom: spacing.Gutter.Mobile,
			borderRadius: spacing.BoderRadius.XL,
			padding: spacing.Gutter.Mobile / 2,
			backgroundColor: 'black',

			'&[data-active="false"]': {
				opacity: 0.5,
				cursor: 'default',
			},
			[mediaQueries.AfterMobile]: {
				flex: 'initial',
				minWidth: 250,
			},

			'&[data-disabled="true"], &:disabled': {
				opacity: 0.5,
				pointerEvents: 'none',
			},
		},
		section: {
			marginBottom: 30,
		},
		inputSelect: {
			...fontStyles.defaultText,
			width: '100%',
			border: '1px solid black',
			minHeight: spacing.InputMinHeight,
			padding: spacing.Gutter.Mobile / 2,
			borderRadius: spacing.BoderRadius.Small,
			display: 'block',
			outline: 'transparent',
			backgroundColor: 'white',

			'[data-error="true"] &': {
				backgroundColor: 'transparent',
				borderColor: theme.palette.error.main,
				color: theme.palette.error.main,
			},
		},
		inputCheckboxLabel: {
			display: 'block',
			position: 'relative',
			textAlign: 'left',

			paddingLeft: 45,
			lineHeight: '30px',

			minHeight: 30,

			'&:before': {
				content: '""',
				position: 'absolute',
				left: 0,
				top: 0,
				width: 30,
				height: 30,
				display: 'inline-block',
				border: '1px solid black',
				verticalAlign: 'middle',
				backgroundColor: 'white',
				marginRight: 15,
				borderRadius: 8,
			},

			'&:after': {
				display: 'inline',
				position: 'absolute',
				top: 12,
				left: 11,
				width: 8,
				height: 8,
				background: 'black',
				borderRadius: '100%',
			},

			'[data-error="true"] &:before': {
				borderColor: theme.palette.error.main,
				backgroundColor: 'transparent',
			},
		},
		inputCheckboxContent: {
			lineHeight: '1.4',
			paddingTop: 2,
		},
		inputCheckbox: {
			display: 'none',

			'&:checked + label:after': {
				content: '""',
			},

			'&:disabled + label': {
				opacity: 0.5,
				cursor: 'not-allowed',
			},
		},
		inputAddressAutocompleteContainer: {
			position: 'relative',
		},
		inputAddressAutocompleteResultsContainer: {
			position: 'absolute',
			top: '100%',
			left: 0,
			width: '100%',
			backgroundColor: theme.palette.primary.main,
			borderRadius: spacing.BoderRadius.Small,
			border: '1px solid black',
		},
		inputAddressAutocompleteResult: {
			padding: theme.spacing(1.5),
			cursor: 'pointer',

			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
			},

			'&[data-selected=true]': {
				backgroundColor: 'rgba(0, 0, 0, 0.1)',
			},
		},
	})
);

export default FormStyles;
