import React, { FC, useCallback, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import OfferSection from './OfferSection';
import { useUsers } from '../@context/Users';
import { useOffers } from '../@context/Offers';
import { LargeLikeScore } from './LargeOfferScores';
import Button from './Button';
import fontStyles from '../constants/fontStyles';
import { useApi } from '../@context/Api';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			likeButtonContainer: {
				marginTop: 10,

				'& p': {
					color: '#D1D1D1',
					marginTop: 14,
					...fontStyles.smallText,
				},
				'& a': {
					color: 'inherit',
					textDecoration: 'underline',
					'&:hover': {
						textDecoration: 'none',
					},
				},
			},
		}),
	{
		name: 'OfferSectionLikes',
	}
);

const OfferSectionLikes: FC<
	(OfferDto | OfferAdminDto) & { fetchFreshOffer: boolean }
> = ({ fetchFreshOffer, ...offer }) => {
	const { admin, current } = useUsers();
	const { likeOffer, unlikeOffer } = useOffers();
	const cls = useStyles();
	const { page: getPage } = useApi();
	const [freshOffer, setFreshOffer] = useState<OfferDto | null>(null);
	const [offerLiked, setOfferLiked] = useState(offer.HasUserLikedOffer);
	const [likes, setLikes] = useState(offer.LikesCount);

	const fetchPage = useCallback(async () => {
		setFreshOffer(null);
		const offerDto: OfferDto = await getPage(`/angebot/${offer.UID}`);
		setFreshOffer(offerDto);
		setOfferLiked(offerDto.HasUserLikedOffer);
		setLikes(offerDto.LikesCount);
	}, [getPage, offer.UID]);

	useEffect(() => {
		if (!fetchFreshOffer) {
			return;
		}

		fetchPage();
	}, [fetchPage, fetchFreshOffer]);

	const [likeLoading, setLikeLoading] = useState(false);

	const handleLikeClick = async (offerId: string) => {
		if (likeLoading) {
			return;
		}
		setLikeLoading(true);
		const { error, data } = await likeOffer(offerId);
		setLikeLoading(false);

		if (error || !data) {
			// eslint-disable-next-line no-alert
			alert('Es ist ein Fehler aufgetreten.');
			// eslint-disable-next-line no-console
			console.error(error);
			return;
		}

		setLikes(data.LikesCount);
		setOfferLiked(data.HasUserLikedOffer);
	};

	const handleUnlikeClick = async (offerId: string) => {
		if (likeLoading) {
			return;
		}
		setLikeLoading(true);
		const { error, data } = await unlikeOffer(offerId);
		setLikeLoading(false);

		if (error || !data) {
			// eslint-disable-next-line no-alert
			alert('Es ist ein Fehler aufgetreten.');
			// eslint-disable-next-line no-console
			console.error(error);
			return;
		}

		setLikes(data.LikesCount);
		setOfferLiked(data.HasUserLikedOffer);
	};

	return (
		<OfferSection title='Gefällt mir'>
			<LargeLikeScore numLikes={likes} />
			<div className={cls.likeButtonContainer}>
				<Button
					disabled={!current || !freshOffer || likeLoading || admin}
					onClick={() =>
						offerLiked
							? handleUnlikeClick(offer.ObjectId)
							: handleLikeClick(offer.ObjectId)
					}
					type='button'
				>
					{offerLiked ? 'Gefällt mir nicht mehr' : 'Gefällt mir'}
				</Button>
				<p>
					Um zu &lsquo;liken&rsquo; bitte <Link to='/login'>einloggen</Link>.
					Kein Login? Hier geht&apos;s zur{' '}
					<Link to='/neuanmeldung'>Anmeldung</Link>.
				</p>
			</div>
		</OfferSection>
	);
};

export default OfferSectionLikes;
