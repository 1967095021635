/* eslint-disable import/no-extraneous-dependencies */
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
	GoogleMap,
	Marker,
	MarkerClusterer,
	useJsApiLoader,
} from '@react-google-maps/api';
import {
	Clusterer,
	ClustererOptions,
} from '@react-google-maps/marker-clusterer';
import { useHistory } from 'react-router-dom';
// import mapStyles from '../utils/mapStyles';
import { useOffers } from '../@context/Offers';
import meImage from '../assets/images/mylocation.png';
import mapProfileLibraries from '../constants/googlemapsLibraries';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			width: '100%',
			height: '100%',
		},
	})
);

const options: google.maps.MapOptions = {
	styles: [
		{
			featureType: 'all',
			elementType: 'labels.text.fill',
			stylers: [
				{
					saturation: 36,
				},
				{
					color: '#000000',
				},
				{
					lightness: 40,
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.text.stroke',
			stylers: [
				{
					visibility: 'on',
				},
				{
					color: '#000000',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			featureType: 'all',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 17,
				},
				{
					weight: 1.2,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#8b9198',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#323336',
				},
			],
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#414954',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 21,
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#2e2f31',
				},
			],
		},
		{
			featureType: 'road',
			elementType: 'labels.text.fill',
			stylers: [
				{
					color: '#7a7c80',
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#242427',
				},
				{
					lightness: 17,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#202022',
				},
				{
					lightness: 29,
				},
				{
					weight: 0.2,
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 18,
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#393a3f',
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#202022',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#393a3f',
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#202022',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 19,
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#000000',
				},
				{
					lightness: 17,
				},
			],
		},
		{
			featureType: 'water',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#202124',
				},
			],
		},
	],
	disableDefaultUI: true,
	zoomControl: true,
	gestureHandling: 'cooperative',
};

const RenderMarker: FC<{
	offer: OfferDto;
	color: string;
	clusterer: Clusterer;
	handleClick?: () => void;
}> = ({ offer, color, clusterer, handleClick }) => {
	const { Location, Title } = offer;
	if (!Location || (Location.lat === 0 && Location.lng === 0)) {
		return null;
	}

	return (
		<Marker
			title={Title}
			position={{ lat: Location.lat, lng: Location.lng }}
			clusterer={clusterer}
			onClick={handleClick}
			icon={{
				path: 'M16.4,0.4L16.4,0.4c-6,0-10.9,3.7-10.9,10.9c0,7.1,10.9,20.9,10.9,20.9l0,0c0,0,10.9-13.8,10.9-20.9S22.4,0.4,16.4,0.4zM16.4,14.4c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3s3.3,1.5,3.3,3.3S18.2,14.4,16.4,14.4z',
				fillColor: color,
				fillOpacity: 1.0,
				strokeWeight: 0,
				scale: 1,
			}}
		/>
	);
};

const MemoizedMarker = React.memo(RenderMarker);

const ProfilMap = () => {
	const cls = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const { offers } = useOffers();
	const [googleMap, setGoogleMap] = useState<
		google.maps.Map<Element> | undefined
	>();
	const [ourPosition, setOurPosition] = useState<
		google.maps.LatLng | undefined
	>();

	const clusterColor = theme?.palette?.primary?.main || 'red';
	const clustererOptions: ClustererOptions = {
		styles: [
			{
				url: `data:image/svg+xml;charset=UTF-8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 58.2 60.1" style="enable-background:new 0 0 58.2 60.1;" xml:space="preserve">
		<path fill="${clusterColor.replace(
			'#',
			'%23'
		)}" d="M29.1,60.1c0,0,29.1-17.1,29.1-34.7C58.2,7.6,43.1,0,29.1,0S0,7.6,0,25.5C0,41.9,29.1,60.1,29.1,60.1z"/>
		</svg>
	 `,
				height: 58,
				width: 56,
				fontFamily: 'FormaDJRText',
				fontWeight: '400',
				textSize: 25,
			},
		],
	};

	const { isLoaded, loadError } = useJsApiLoader({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || '',
		libraries: mapProfileLibraries,
	});

	const offersToRender = useMemo(
		() => offers.filter((o) => o.Location?.lat || o.Location?.lng),
		[offers]
	);

	useEffect(() => {
		if (!window.google) {
			return;
		}

		if (!navigator.geolocation) {
			return;
		}
		const handleSuccess = (position: GeolocationPosition) => {
			const { latitude, longitude } = position.coords;
			setOurPosition(new window.google.maps.LatLng(latitude, longitude));
		};

		const handleError = (error: GeolocationPositionError) => {
			console.error('GeoLocalization error', error);
		};

		const watcher = navigator.geolocation.watchPosition(
			handleSuccess,
			handleError
		);

		// eslint-disable-next-line consistent-return
		return () => {
			console.count('clearWatch');
			navigator.geolocation.clearWatch(watcher);
		};
	}, []);

	useEffect(() => {
		if (!googleMap) {
			return;
		}

		const markerBounds = new window.google.maps.LatLngBounds();
		let closestOffer: { pos: google.maps.LatLng; distance: number } | undefined;

		offersToRender.forEach(({ Location }) => {
			const offerPosition = new window.google.maps.LatLng(
				Location.lat,
				Location.lng
			);
			markerBounds.extend(offerPosition);

			if (ourPosition) {
				const dist =
					window.google.maps.geometry.spherical.computeDistanceBetween(
						offerPosition,
						ourPosition
					);
				if (!closestOffer || closestOffer.distance > dist) {
					closestOffer = { pos: offerPosition, distance: dist };
				}
			}
		});

		if (ourPosition) {
			googleMap.setCenter(ourPosition);

			const newbounds = new window.google.maps.LatLngBounds();
			newbounds.extend(ourPosition);
			if (closestOffer) {
				newbounds.extend(closestOffer.pos);
			}
			googleMap.fitBounds(newbounds);
		} else {
			googleMap.fitBounds(markerBounds);
		}
	}, [googleMap, offersToRender, ourPosition]);

	const onLoad = useCallback((map: google.maps.Map<Element>) => {
		setGoogleMap(map);
	}, []);

	const onUnmount = useCallback(() => {
		setGoogleMap(undefined);
	}, []);

	if (loadError) return <div>Error loading the map</div>;
	if (!isLoaded) return <div>Loading</div>;

	return (
		<div className={cls.root}>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={{ width: '100%', height: '100%' }}
					zoom={10}
					onLoad={onLoad}
					onUnmount={onUnmount}
					options={options}
				>
					<MarkerClusterer options={clustererOptions}>
						{(clusterer) =>
							offersToRender.map((o) => (
								<MemoizedMarker
									key={o.ObjectId}
									offer={o}
									handleClick={() => history.push(`/angebot/${o.UID}`)}
									color={theme.palette.primary.main}
									clusterer={clusterer}
								/>
							))
						}
					</MarkerClusterer>
					{ourPosition && (
						<Marker title='Me' position={ourPosition} icon={meImage} />
					)}
				</GoogleMap>
			)}
		</div>
	);
};

export default ProfilMap;
