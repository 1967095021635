/* eslint-disable no-param-reassign */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormStyles from 'src/components/Form/FormStyles';
import Header from 'src/components/Header';
import PageLayout from 'src/components/PageLayout';
import fontStyles from '../constants/fontStyles';
import NewsletterSignupForm from '../components/NewsletterSignupForm';
import { useApi } from '../@context/Api';

const useStyles = makeStyles(() =>
	createStyles({
		root: {},
		subtitle: {
			marginTop: '4rem',
			marginBottom: '1rem',
			...fontStyles.largeText,
			fontWeight: 'bold',
		},
		subtitleFirst: {
			marginTop: 0,
		},
		archiveWrapper: {
			...fontStyles.defaultText,
			textAlign: 'center',
			marginTop: '6rem',
			marginBottom: '6rem',
		},
		archiveList: {
			textAlign: 'center',
			'& li + li': {
				marginTop: 20,
			},
			'& li a': {
				textDecoration: 'underline',
			},
		},
	})
);


type NewsletterTag = {
	Name: string;
	NumberOfCampaigns:  number;
}

interface NewsletterCampaign {
	Name: string;
	SentDate: string;
	Subject: string;
	WebVersionTextURL: string;
	WebVersionURL: string;
}

type NewsletterList = {
	tags: Array<NewsletterTag>,
	campaigns: Array<NewsletterCampaign>
}

const NewsletterPage: FC = () => {
	const formCls = FormStyles();
	const cls = useStyles();
	const archiveWrapperRef = useRef<HTMLDivElement>(null);
	const { get } = useApi();

	const [campaignsLoading, setCampaignsLoading] = useState(false);
	const [campaignsError, setCampaignsError] = useState<any>(null);
	const [campaigns, setCampaigns] = useState<NewsletterCampaign[]>([]);

	useEffect(() => {
		(async () => {
			setCampaignsError(null);
			setCampaignsLoading(true);
			const { error, data } = await get<NewsletterList>(
				`/2.0/campaigns/list`
			);

			setCampaignsLoading(false);

			if (error) {
				setCampaignsError(error);
			} else if (data) {
				setCampaigns(data.campaigns);
			}
		})();
		// 'get' from useApi is not safe to put into useEffect
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const campaignsGroupedByYear: Record<string, NewsletterCampaign[]> =
		useMemo(() => {
			if(!campaigns?.length) {
				return {};
			}

			return campaigns
				.slice()
				.sort(
					(c1, c2) =>
						new Date(c2.SentDate).valueOf() - new Date(c1.SentDate).valueOf()
				)
				.reduce((accu: Record<string, NewsletterCampaign[]>, campaign) => {
					const year = String(new Date(campaign.SentDate).getFullYear());
					accu[year] = accu[year] || [];
					accu[year].push(campaign);
					return accu;
				}, {});
		}, [campaigns]);

	const hasCampaigns = Object.entries(campaignsGroupedByYear).length > 0;

	return (
		<PageLayout>
			<Header />
			<article className={formCls.modal}>
				<h2 className={classNames(formCls.title, formCls.centered)}>
					Newsletter-Anmeldung
				</h2>
				<NewsletterSignupForm />

				<div className={cls.archiveWrapper}>
					<h2 className={classNames(formCls.title, formCls.centered)}>
						Newsletter-Archiv
					</h2>
					{campaignsError ? (
						<p className={formCls.formError}>
							{typeof campaignsError.message === 'string'
								? `Es ist ein Fehler aufgetreten: ${campaignsError.message}`
								: 'Es ist ein Fehler aufgetreten.'}
						</p>
					) : campaignsLoading ? (
						<p>Lade...</p>
					) : !hasCampaigns ? (
						<p>Keine Newsletter gefunden.</p>
					) : (
						<div>
							{Object.entries(campaignsGroupedByYear)
								.sort(([year1], [year2]) => Number(year2) - Number(year1))
								.map(([year, campaigns], i) => {
									return (
										<div key={year}>
											<h3
												className={classNames(
													cls.subtitle,
													i === 0 && cls.subtitleFirst
												)}
											>
												{year}
											</h3>
											<ul className={cls.archiveList}>
												{campaigns.map((campaign) => {
													return (
														<li key={campaign.WebVersionURL}>
															<a
																href={campaign.WebVersionURL}
																target='_blank'
																rel='noreferrer noopener'
															>
																{campaign.Name}
															</a>
														</li>
													);
												})}
											</ul>
										</div>
									);
								})}
						</div>
					)}
				</div>
			</article>
		</PageLayout>
	);
};

export default NewsletterPage;
