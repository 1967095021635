/* eslint-disable no-restricted-syntax */
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useApp } from 'src/@context/App';
import FormStyles from 'src/components/Form/FormStyles';
import InputCheckbox from 'src/components/Form/InputCheckbox';
import InputRadio from 'src/components/Form/InputRadio';
import Header from 'src/components/Header';
import LoadingScreen from 'src/components/LoadingScreen';
import PageLayout from 'src/components/PageLayout';
import fontIcon from 'src/constants/fontIcon';
import spacing from 'src/constants/spacing';
import Button from '../components/Button';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			minHeight: '100%',
		},
		sectionTitle: {
			position: 'relative',
			textAlign: 'center',
			marginBottom: 5,
		},
		closeBtn: {
			'&:before': {
				...fontIcon.iconClose,
			},
		},
		postfinance: {
			'&:before': {
				...fontIcon.iconPostfinance,
			},
		},
		paypal: {
			'&:before': {
				...fontIcon.iconPaypal,
			},
		},
		twint: {
			'&:before': {
				...fontIcon.iconTwint,
			},
		},
		link: {
			lineHeight: 1.4,
			textDecoration: 'underline',
			'&:hover': {
				textDecoration: 'none',
			},
		},
		helpText: {
			margin: `${spacing.Gutter.Desktop / 2}px 0 ${spacing.Gutter.Desktop}px 0`,

			'& a': {
				textDecoration: 'underline',
			},
		},
	})
);

type PayFromData = {
	subscriptionId: string;
	provider: string;
	ABG: boolean;
};

const isDev = process.env.NODE_ENV === 'development';

const Pay: FC = () => {
	const cls = useStyles();
	const formCls = FormStyles();
	const history = useHistory();
	const { id } = useParams<Record<string, string | undefined>>();
	const { setMessageScreen } = useApp();
	const [providersList, setProvidersList] = useState<ListResponse>();
	const [loading, setLoading] = useState(false);
	const origin = process.env.REACT_APP_AUTH_URL?.replace(/\/+$/g, '');

	const getProvidersList = useCallback(
		async (userId: string) => {
			const response = await fetch(`${origin}/2.0/payments/list/${userId}`);

			const payload = await response.json();
			if (!response.ok) {
				setMessageScreen({
					type: 'Error',
					message: payload.Message || 'error while signin, try again',
				});

				return null;
			}

			return setProvidersList(payload as ListResponse);
		},
		[origin, setMessageScreen]
	);

	useEffect(() => {
		if (!id) {
			return;
		}

		setLoading(true);
		getProvidersList(id).then(() => setLoading(false));
	}, [id, getProvidersList]);

	const {
		register,
		handleSubmit,
		formState: { isValid, errors },
	} = useForm<PayFromData>({
		mode: 'onChange',
		defaultValues: {
			subscriptionId: '',
			provider: '',
			ABG: false,
		},
	});

	const onSubmit = async (formData: PayFromData) => {
		if (loading) {
			return;
		}

		setLoading(true);
		const redirecturl = location.origin;
		const response = await fetch(
			`${origin}/2.0/payments/${formData.provider}/${id}/${
				formData.subscriptionId
			}?redirectUri=${encodeURIComponent(redirecturl)}`
		);

		const data = await response.json();
		if (!response.ok) {
			setMessageScreen({ message: data, type: 'Error' });
		}

		const { actions, method, param } = data as PaymentProviderResponse;

		const form = document.createElement('form');
		form.method = method;
		form.action = isDev ? actions.test : actions.production;

		Object.entries(param).forEach(([key, value]) => {
			const input = document.createElement('input');
			input.type = 'hidden';
			input.name = key;
			input.value = value;
			form.appendChild(input);
		});

		document.body.appendChild(form);
		setTimeout(() => {
			form.submit();
		}, 10);
	};

	if (loading) {
		return <LoadingScreen />;
	}

	return (
		<PageLayout>
			<div className={cls.root}>
				<Header />
				{providersList && (
					<article className={formCls.modal}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className={formCls.section}>
								<div className={cls.sectionTitle}>Mitgliedschaft</div>
								{providersList.years &&
									providersList.years.map((y) => {
										const yearLegend = `${y.legend} <span style={font-size=.6rem}>${y.amount}</span>`;
										return (
											<InputRadio
												key={y.subscriptionId}
												id={`pay-${y.subscriptionId}`}
												register={register('subscriptionId', {
													required: 'Bitte ausfüllen',
												})}
												value={`${y.subscriptionId}`}
												label={yearLegend}
											/>
										);
									})}
							</div>
							<div className={formCls.section}>
								<div className={cls.sectionTitle}>Zahlungsart</div>
								{providersList.providers &&
									providersList.providers.map((p) => {
										let lbl = '';

										switch (p.name) {
											case 'paypal':
												lbl = `<span class="${cls.paypal}"></span>`;
												break;
											case 'postfinance':
												lbl = `<span class="${cls.postfinance}"></span>`;
												break;
											case 'creditcard':
												lbl = p.legend;
												break;
											case 'twint':
												lbl = `<span class="${cls.twint}"></span>`;
												break;
											case 'transfer':
												lbl = p.legend;
												break;

											default:
												lbl = ``;
												break;
										}

										return (
											<InputRadio
												key={p.name}
												id={`pay-${p.name}`}
												register={register('provider', {
													required: 'Bitte ausfüllen',
												})}
												value={`${p.name}`}
												label={lbl}
											/>
										);
									})}
							</div>
							<div className={formCls.section}>
								<InputCheckbox
									id='pay-agb'
									label='Ich akzeptiere die '
									register={register('ABG', { required: 'Bitte ausfüllen' })}
									error={errors.ABG}
								>
									<Link
										to='/agb'
										className={cls.link}
										target='_blank'
										rel='noopener noreferrer'
									>
										AGB
									</Link>
								</InputCheckbox>
								<div className={cls.helpText}>
									Wenn&apos;s schnell gehen soll: schick uns Mo–Fr eine Kopie
									vom Zahlungsbeleg an:{' '}
									<a href='mailto:ausweis@colourkey.ch'>ausweis@colourkey.ch</a>
								</div>
							</div>

							<Button
								variant='formSubmitButton'
								type='submit'
								disabled={!isValid}
							>
								Weiter
							</Button>
						</form>
					</article>
				)}
			</div>
		</PageLayout>
	);
};

export default Pay;
