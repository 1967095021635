/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import * as React from 'react';
import { useContext, useState, createContext } from 'react';
import { Language } from 'src/utils/Languages';

type Api = {
	page: <TData extends unknown = IPageDto>(path: string) => Promise<TData>;
	get: <TData extends unknown>(path: string) => Promise<ApiResponse<TData>>;
	post: <TData extends unknown>(
		path: string,
		body: any
	) => Promise<ApiResponse<TData>>;
	put: <TData extends unknown>(
		path: string,
		body: any
	) => Promise<ApiResponse<TData>>;
	patch: <TData extends unknown>(
		path: string,
		body: any
	) => Promise<ApiResponse<TData>>;
	form: <TData extends unknown>(
		url: string,
		payload: HTMLFormElement,
		method?: 'PUT' | 'POST'
	) => Promise<ApiResponse<TData>>;
	formFormData: <TData extends unknown>(
		url: string,
		payload: FormData,
		method?: 'PUT' | 'POST'
	) => Promise<ApiResponse<TData>>;
	loading: boolean;
};

export const ApiContext = createContext<Api>({} as never);

export const Api: React.FC = ({ children }) => {
	const [loading, setLoading] = useState(false);

	const origin = process.env.REACT_APP_API_URL?.replace(/\/+$/g, '');

	const requestOptions: RequestInit = {
		method: 'GET',
		headers: {
			'Accept-Language': Language,
			'Content-Type': 'application/json',
		},
	};

	const fetchPage =
		(options: RequestInit = {}) =>
		async <TData extends unknown>(url: string): Promise<ApiResponse<TData>> => {
			const ops: RequestInit = { ...options, credentials: 'include' };

			const [path, query] = url.split('?');
			const response = await fetch(`${origin}${path}?${query || ''}`, {
				...requestOptions,
				...ops,
			});

			const payload = await response.text();
			setLoading(false);

			if (!response.ok) {
				let error = { status: 0, Message: '' };
				try {
					error = payload && JSON.parse(payload);
				} catch (e) {
					error.Message = e as string;
				}
				// error.status = response?.status;
				return { error };
			}
			return { data: payload && JSON.parse(payload) };
		};

	const page = async <TData extends unknown = IPageDto>(url: string) => {
		try {
			const { error, data } = await fetchPage({})<TData>(url);
			if (error) {
				return { type: error.status.toString() } as TData;
			}
			return data as TData;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(`Api: Error while loading: ${origin}${url}?json, ${e}`);
			return { type: 'unknown' } as TData;
		} finally {
			setLoading(false);
		}
	};

	const form = <TData extends unknown>(
		url: string,
		payload: HTMLFormElement,
		method: 'PUT' | 'POST' = 'POST'
	) =>
		fetchPage({
			method,
			body: new FormData(payload),
			headers: {
				// ...requestOptions.headers,
				// 'Content-Type': 'multipart/form-data'
				Accept: 'application/json',
			},
		})<TData>(url);

	const formFormData = <TData extends unknown>(
		url: string,
		payload: FormData,
		method: 'PUT' | 'POST' = 'POST'
	) =>
		fetchPage({
			method,
			body: payload,
			headers: {
				Accept: 'application/json',
			},
		})<TData>(url);

	const post = <TData extends unknown>(url: string, payload: any) =>
		fetchPage({
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(payload),
		})<TData>(url);

	const patch = <TData extends unknown>(url: string, payload: any) => {
		const arrPatch = [];

		for (const [key, value] of Object.entries(payload)) {
			arrPatch.push({ op: 'replace', path: `/${key}`, value });
		}

		return fetchPage({
			method: 'PATCH',
			body: JSON.stringify(arrPatch),
		})<TData>(url);
	};

	const put = <TData extends unknown>(url: string, payload: any) =>
		fetchPage({
			method: 'PUT',
			body: JSON.stringify(payload),
		})<TData>(url);

	return (
		<ApiContext.Provider
			value={{
				get: fetchPage(),
				page,
				post,
				patch,
				put,
				form,
				formFormData,
				loading,
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

export const useApi = (): Api => useContext(ApiContext);
