import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OfferSection from './OfferSection';
import Map from './Map';
import mediaQueries from '../constants/mediaQueries';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			map: {
				height: '60vh',
				marginTop: 20,
				[mediaQueries.BeforeTablet]: {
					marginLeft: -30,
					marginRight: -30,
					marginBottom: -30,
				},

				'[data-animating="true"] &': {
					opacity: 0,
				},
			},
		}),
	{
		name: 'OfferSectionContact',
	}
);

const OfferSectionContact: FC<OfferDto | OfferAdminDto> = (offer) => {
	const cls = useStyles();
	const {
		CompanyName,
		Address,
		COAddress,
		ZipCode,
		City,
		PhoneNumber,
		Email,
		Website,
	} = offer;

	return (
		<OfferSection title='Kontakt'>
			<div>
				{CompanyName && <p>{CompanyName}</p>}
				{Address && <p>{Address}</p>}
				{COAddress && <p>{COAddress}</p>}
				{(ZipCode || City) && <p>{`${ZipCode} ${City}`}</p>}
				{PhoneNumber && (
					<p>
						<a href={`tel:${PhoneNumber}`}>{PhoneNumber}</a>
					</p>
				)}
				{Website && (
					<p>
						<a
							href={Website.startsWith('http') ? Website : `http://${Website}`}
							target='_blank'
							rel='noreferrer'
						>
							{Website}
						</a>
					</p>
				)}
				{Email && (
					<p>
						<a href={`mailto:${Email}`}>{Email}</a>
					</p>
				)}
			</div>
			<div className={cls.map}>
				<Map offers={[offer]} allowMarkerClick={false} />
			</div>
		</OfferSection>
	);
};

export default OfferSectionContact;
