import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { useApi } from 'src/@context/Api';
import { useApp } from 'src/@context/App';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';
import MenuItem, { StaticMenu } from './MenuItem';

const useStyles = makeStyles((theme) => ({
	root: {
		textAlign: 'center',
	},

	dropdownToggler: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontSize: '1.8rem',
		padding: `0 ${spacing.Gutter.Mobile}px`,
		fontWeight: 700,
		marginBottom: spacing.Gutter.Mobile,
		[mediaQueries.AfterMobile]: {
			fontSize: rem(36, bodyFontSize.Desktop),
			marginBottom: 15,
		},
	},

	dropdownContent: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '0',
		overflow: 'hidden',
		transition: 'all .3s ease',
		willChange: 'height',

		[mediaQueries.Mobile]: {
			'&[data-open="true"]': {
				height: 'auto',
				maxHeight: '80vh',
			},
		},
		[mediaQueries.AfterMobile]: {
			'&[data-open="true"]': {
				height: 'auto',
				maxHeight: '60vh',
			},
		},
	},
	btn: {
		textAlign: 'center',
		color: theme.palette.primary.main,
		marginBottom: spacing.Gutter.Mobile,
		padding: `0 ${spacing.Gutter.Desktop}px`,
		fontWeight: 600,
		fontSize: '1.1rem',
		[mediaQueries.AfterMobile]: {
			marginBottom: 15,
			fontSize: rem(20, bodyFontSize.Desktop),
		},
	},
}));

const MemberMenu = () => {
	const cls = useStyles();
	const { setMessageScreen } = useApp();
	const { get } = useApi();
	const [open, setOpen] = useState(false);

	const handleClick = () => setOpen(!open);

	const handlePartnersEmail = useCallback(async () => {
		// eslint-disable-next-line no-alert
		const sendEmails = window.confirm("Should we send partners email's?");

		if (sendEmails) {
			const { data: success, error } = await get<
				ApiResponse<{ Message: string }>
			>('/2.0/admin/colourguide-partners-warning');

			if (error) {
				setMessageScreen({
					type: 'Error',
					message: error?.Message || `${error.status} - Try again later`,
				});
				return;
			}

			setMessageScreen({
				type: 'Success',
				message: success?.data?.Message || 'Aye, aye! Email geschickt!',
			});
		}
	}, [get, setMessageScreen]);

	return (
		<div className={cls.root}>
			<button
				className={cls.dropdownToggler}
				type='button'
				onClick={() => handleClick()}
			>
				Admin
			</button>
			<div className={cls.dropdownContent} data-open={open}>
				<a href='/csvexport' className={cls.btn}>
					Export CSV
				</a>
				<button type='button' className={cls.btn} onClick={handlePartnersEmail}>
					Send Partners emails
				</button>
				<MenuItem
					menu={
						{
							type: 'static',
							url: '/send-push-notification',
							title: 'Push-Benachrichtigung senden',
						} as StaticMenu
					}
					isSubmenu
				/>
			</div>
		</div>
	);
};

export default React.memo(MemberMenu);
