import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type InpuPasswordProps = {
	register: any;
	label?: string;
	error: FieldError | undefined;
};

const InputPassword: FC<InpuPasswordProps> = ({
	register,
	error,
	label,
	children,
	...rest
}) => {
	const formCls = FormStyles();
	return (
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<input
				type='password'
				className={formCls.inputText}
				{...register}
				{...rest}
			/>
			{error && (
				// TODO: translate
				<div className={formCls.error}>
					{error.type === 'minLength'
						? `${error.ref?.name} muss mindestens 6 Zeichen enthalten.`
						: error.message || 'problem as occorded'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputPassword;
