import { makeStyles, createStyles } from '@material-ui/core/styles';
import fontIcon from 'src/constants/fontIcon';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';

export default makeStyles((theme) =>
	createStyles({
		cardItem: {
			minWidth: 0,
			position: 'relative',
			[mediaQueries.Mobile]: {
				height: '100%',
				width: '100%',
				borderRadius: 10,
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				overflow: 'hidden',
			},
		},
		cardItemInner: {
			[mediaQueries.Mobile]: {
				height: '100%',
				width: '100%',
			},
			[mediaQueries.AfterMobile]: {
				position: 'relative',
				aspectRatio: '3 / 4',
				borderRadius: spacing.BoderRadius.Big,
				zIndex: 0,
				overflow: 'hidden',
				border: 'none',
				cursor: 'pointer',
				backgroundColor: theme.palette.primary.main,
			},
		},
		cardItemTitleContainer: {
			height: 93,
			minWidth: 0,
			[mediaQueries.Mobile]: {
				padding: 12,
				boxSizing: 'border-box',
				lineHeight: 1,

				'& span': {
					display: 'block',
					padding: 10,
				},
			},
			[mediaQueries.AfterMobile]: {
				padding: spacing.Gutter.Desktop / 2,
			},
		},
		cardItemTitle: {
			fontSize: rem(18, bodyFontSize.Mobile),
			fontWeight: 500,
			letterSpacing: '0.01em',
			textOverflow: 'ellipsis',
			lineClamp: 4,
			display: 'box',
			boxOrient: 'vertical',
			overflow: 'hidden',
			[mediaQueries.AfterMobile]: {
				lineHeight: 1,
			},
		},
		cardItemFigure: {
			[mediaQueries.Mobile]: {
				position: 'absolute',
				top: '35%',
				bottom: 0,
				left: 0,
				right: 0,
			},

			[mediaQueries.AfterMobile]: {
				position: 'absolute',
				top: 93,
				bottom: 0,
				left: 0,
				right: 0,
			},
		},
		cardItemImage: {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			OObjectFit: 'cover',
			objectFit: 'cover',
			fontFamily: 'object-fit: cover;',
			OObjectPosition: 'center',
			objectPosition: 'center',
		},
		scores: {
			position: 'absolute',
			top: 0,
			left: 0,
			right: 0,
			zIndex: 2,
		},
		specialOffer: {
			position: 'absolute',
			padding: '5px 30px',
			display: 'block',
			fontSize: '0.9em',
			transformOrigin: 'right top',
			transform: 'translateX(-30%) rotate(45deg)',
			left: 0,
			bottom: -30, // height of the element before rotation
			backgroundColor: theme.palette.primary.main,
		},
		cardItemLink: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1,
		},
		link: {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			zIndex: 1,
		},
	})
);
