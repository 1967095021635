import { lighten, makeStyles } from '@material-ui/core/styles';
import fontIcon from 'src/constants/fontIcon';
import spacing from 'src/constants/spacing';
import { bodyFontSize, warnningAnimation } from '../constants/stylesheet';
import { rem } from '../utils/Units';
import mediaQueries from '../constants/mediaQueries';

const animation = {
	'0%, 20%, 50%, 80%, 100%': {
		transform: 'translateY(0)',
	},
	'40%': {
		transform: 'translateY(-5px)',
	},
	'60%': {
		transform: 'translateY(-2px)',
	},
};

export default makeStyles(
	(theme) => ({
		root: {
			[mediaQueries.Mobile]: {
				position: 'absolute',
				top: 120,
				bottom: 0,
				left: 0,
				right: 0,
			},
			[mediaQueries.AfterMobile]: {
				position: 'relative',
				opacity: 1,
				zIndex: 1,
				flexShrink: 0,
				transition: 'opacity 0.2s linear',
				background: theme.palette.primary.main,
			},
		},
		tabsContainer: {
			display: 'flex',
			justifyContent: 'center',

			[mediaQueries.Mobile]: {
				position: 'relative',
				margin: `0 ${spacing.Gutter.Mobile / 4}px`,
			},
			[mediaQueries.AfterMobile]: {
				position: 'absolute',
				bottom: '100%',
				left: 50,
				right: 50,
				textAlign: 'center',
			},
		},
		searchTab: {
			width: 66,
			paddingTop: 4,
			[mediaQueries.Mobile]: {
				width: 66,
			},
			[mediaQueries.AfterMobile]: {
				width: 66,
			},
		},
		searchIcon: {
			width: 26,
			height: 26,
		},
		filtersContainer: {
			[mediaQueries.Mobile]: {
				height: '100%',
				paddingBottom: 50,
				backgroundColor: theme.palette.primary.main,
				marginTop: -1,
				overflow: 'auto',
			},
			[mediaQueries.AfterMobile]: {
				overflow: 'hidden',
				transition: 'all 0.3s ease',
				'&[data-open="false"]': {
					maxHeight: 70,
				},
				'&[data-open="true"]': {
					maxHeight: '100vh',
				},
			},
		},
		filters: {
			paddingLeft: spacing.Gutter.Mobile / 2,
			paddingRight: spacing.Gutter.Mobile / 2,
			paddingTop: spacing.Gutter.Mobile,
			paddingBottom: spacing.Gutter.Mobile,
			[mediaQueries.Mobile]: {
				// display: 'flex',
				// flexDirection: 'column',
			},
			[mediaQueries.AfterMobile]: {
				paddingLeft: spacing.Gutter.Mobile,
				paddingRight: spacing.Gutter.Mobile,
				paddingTop: 2 * spacing.Gutter.Mobile,
				paddingBottom: 2 * spacing.Gutter.Mobile,
				opacity: 0,
				transition: 'opacity, .3s easy',
				willChange: 'opacity',

				'[data-open="true"] &': {
					opacity: 1,
				},
			},
		},
		filtersContent: {},
		filtersSection: {
			[mediaQueries.AfterMobile]: {
				position: 'relative',
				maxWidth: 950,
				margin: '0 auto',
			},
		},
		mainCategories: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fit, minmax(110px, 1fr));',
			gap: spacing.Gutter.Mobile / 2,
			[mediaQueries.AfterMobile]: {
				gridTemplateColumns: 'repeat(auto-fit, minmax(110px, 132px));',
				justifyContent: 'center',
				gap: spacing.Gutter.Mobile,
			},
		},
		filterBtnLabel: {
			display: 'block',
			maxWidth: '100%',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
		},
		filterCategoryBtn: {
			position: 'relative',
			color: 'inherit',
			lineHeight: 1.2,
			textAlign: 'center',
			display: 'block',
			borderRadius: spacing.BoderRadius.Small,
			fontSize: rem(20, bodyFontSize.Mobile),
			backgroundColor: theme.palette.primary.light,
			fontWeight: 500,
			letterSpacing: '0.03em',

			[mediaQueries.Mobile]: {
				paddingBottom: spacing.Gutter.Mobile / 2,
			},
			[mediaQueries.AfterMobile]: {
				width: 132,
				paddingBottom: spacing.Gutter.Desktop / 2,
			},
			'&[data-active="true"]': {
				backgroundColor: 'white',
			},
			'&[data-active="false"]:hover': {
				backgroundColor: lighten(theme.palette.primary.light, 0.5),
			},
		},
		iconContainer: {
			width: '100%',
			height: 70 + 2 * 12,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		dropdownsContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			gap: spacing.Gutter.Mobile,
		},
		dropdownContent: {
			marginTop: spacing.Gutter.Mobile,
			'&[data-active="false"]': {
				marginTop: 0,
				display: 'none',
			},
			[mediaQueries.Mobile]: {
				flex: '0 0 100%',
			},
			[mediaQueries.AfterMobile]: {
				flex: '0 0 50%',
			},
		},
		dropdown: {},
		dropdownInner: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',

			'&[data-active="true"]': {
				display: 'flex',
				alignItems: 'center',
			},
		},
		dropdownLegend: {
			lineHeight: 1,
			paddingRight: 10,

			[mediaQueries.Mobile]: {
				flex: '0 0 30%',
				fontSize: rem(15, bodyFontSize.Mobile),
			},
			[mediaQueries.AfterMobile]: {
				flex: '1 1 25%',
				fontSize: rem(15, bodyFontSize.Desktop),
			},
		},
		dropdpwnSelect: {
			WebkitAppearance: 'none',
			backgroundColor: 'transparent',
			borderColor: 'black',
			borderRadius: spacing.BoderRadius.Small,
			padding: 5,

			[mediaQueries.Mobile]: {
				flex: '1 1 70%',
			},

			[mediaQueries.AfterMobile]: {
				flex: '1 1 75%',
			},
		},
		generalFiltersContainer: {
			display: 'flex',
			gap: spacing.Gutter.Mobile / 2,
			flexWrap: 'wrap',
			marginTop: spacing.Gutter.Mobile,
			[mediaQueries.Mobile]: {
				justifyContent: 'space-between',
			},
			[mediaQueries.AfterMobile]: {
				gap: spacing.Gutter.Mobile,
				justifyContent: 'center',
			},
		},
		filterBtn: {
			position: 'relative',
			borderRadius: spacing.BoderRadius.Small,
			textAlign: 'center',
			padding: '5px 6px',
			cursor: 'pointer',
			backgroundColor: theme.palette.primary.light,
			color: 'black',
			fontWeight: 500,
			fontSize: rem(20, bodyFontSize.Desktop),
			textTransform: 'uppercase',
			letterSpacing: '0.08em',
			width: `calc(50% - ${spacing.Gutter.Mobile / 4}px)`,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',

			'&[data-active="true"]': {
				backgroundColor: 'white',
			},
			[mediaQueries.AfterMobile]: {
				padding: '5px 14px',
				width: 'auto',
				'&[data-active="false"]:hover': {
					backgroundColor: lighten(theme.palette.primary.light, 0.5),
				},
			},
		},
		offersBtn: {
			color: theme.palette.text.primary,
			textAlign: 'center',
			flex: 1,
			height: 70,
			fontWeight: 'bold',
			fontSize: rem(20, bodyFontSize.Desktop),
			letterSpacing: '0.1em',
			textTransform: 'uppercase',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',

			[mediaQueries.Mobile]: {
				padding: `${spacing.Gutter.Mobile / 2}px ${spacing.Gutter.Mobile}px`,
				backgroundColor: 'black',
				borderRadius: spacing.BoderRadius.Small,

				'&[data-active="false"]': {
					opacity: 0.5,
					cursor: 'default',
				},
			},
			[mediaQueries.AfterMobile]: {
				fontSize: rem(32, bodyFontSize.Desktop),
				flex: 'initial',
				width: 'auto',
				minWidth: 250,
				paddingLeft: 30,
				paddingRight: 30,
				backgroundColor: 'black',
				borderRadius: spacing.BoderRadius.Big,
			},
		},
		resetOffersBtn: {
			backgroundColor: theme.palette.primary.light,
			color: 'black',
			width: 90,
			fontSize: rem(40, bodyFontSize.Desktop),
			paddingTop: 5,

			'&:before': {
				...fontIcon.iconResetFilters,
			},

			[mediaQueries.Mobile]: {
				textAlign: 'center',
				padding: `${spacing.Gutter.Mobile / 2}px ${spacing.Gutter.Mobile}px`,
				display: 'block',
				borderRadius: spacing.BoderRadius.Small,
			},
			[mediaQueries.AfterMobile]: {
				borderRadius: spacing.BoderRadius.Big,
				'&:hover': {
					backgroundColor: lighten(theme.palette.primary.light, 0.5),
				},
			},
		},
		formSearch: {
			display: 'flex',
			flexDirection: 'column',
			gap: spacing.Gutter.Mobile / 2,
			[mediaQueries.Mobile]: {
				margin: 0,
			},
			[mediaQueries.AfterMobile]: {
				flexDirection: 'row',
				margin: `0 ${spacing.Gutter.Desktop}px`,
			},
		},
		searchInputText: {
			fontFamily: 'inherit',
			display: 'block',
			width: '100%',
			padding: 0,
			paddingLeft: 10,
			paddingRight: 10,
			height: 50,
			backgroundColor: 'transparent',
			color: 'black',
			border: '2px solid black',
			borderRadius: spacing.BoderRadius.Small,
			fontWeight: 500,
			fontSize: rem(20, bodyFontSize.Desktop),
			'&:focus': {
				outline: 'none',
				backgroundColor: theme.palette.primary.light,
			},
			'&::placeholder': {
				color: 'rgba(0, 0, 0, 0.3)',
			},
		},
		searchInputSubmit: {
			fontSize: rem(20, bodyFontSize.Desktop),
			fontWeight: 600,
			textTransform: 'uppercase',
			letterSpacing: 1.4,
			color: theme.palette.primary.main,
			margin: 0,
			padding: 0,
			paddingLeft: 20,
			paddingRight: 20,
			height: 50,
			display: 'inline-block',
			backgroundColor: 'black',
			borderRadius: spacing.BoderRadius.Small,
			border: 0,
			fontFamily: 'inherit',
		},
		filtersTogglerContainer: {
			[mediaQueries.Mobile]: {
				display: 'none',
			},
			[mediaQueries.AfterMobile]: {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				overflow: 'visible',
				padding: '0 20px',
				height: 70,

				display: 'flex',
				alignItems: 'center',

				'[data-open="true"] &': {
					top: '100%',
					maxHeight: 0,
					padding: 0,
				},
			},
		},
		warningBanner: {
			[mediaQueries.Mobile]: {
				display: 'none',
			},
			[mediaQueries.AfterMobile]: {
				flex: '1 1 auto',
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
				height: 30,
				overflow: 'hidden',

				'[data-open="true"] &': {
					maxHeight: 0,
					overflow: 'hidden',
				},
			},
		},
		warningText: {
			position: 'relative',
			flex: '0 0 auto',
			transform: 'translate3d(0, 0, 0)',
			// animation: '$warnningAnimation 30s infinite linear',
			animationName: '$warnningAnimation',
			animationTimingFunction: 'linear',
			animationIterationCount: 'infinite',
			fontSize: rem(20, bodyFontSize.Desktop),
			fontWeight: 'bold',
			letterSpacing: '0.06em',
			textTransform: 'uppercase',

			'& span': {
				display: 'inline-block',
				marginRight: 20,
			},
		},
		open: {},
		'@keyframes animation': animation,
		'@keyframes warnningAnimation': warnningAnimation,
	}),
	{ name: 'Filters' }
);
