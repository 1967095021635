import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Filters from 'src/components/Filters';
import Map from 'src/components/Map';
import OfferList from 'src/components/OfferList';
import spacing from 'src/constants/spacing';
import { useOffers } from 'src/@context/Offers';
import { useApp } from 'src/@context/App';
import IconButton from './IconButton';
import Header from './Header';

const mapStartLeftAt = '70%';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			root: {
				position: 'relative',
				height: '100%',
				width: '100%',
				display: 'flex',
			},
			contentSection: {
				position: 'relative',
				flex: '0 0 auto',
				minWidth: '35%',
				maxWidth: mapStartLeftAt,
				width: mapStartLeftAt,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				paddingRight: 5,
				paddingTop: spacing.HeaderHeight,
			},
			header: {
				zIndex: 2,
			},
			logo: {
				position: 'relative',
				width: 200,

				'& svg': {
					width: 242,
					height: 'auto',
					marginTop: '-6px',
				},
				'& svg path': {
					fill: theme.palette.primary.main,
				},
			},
			dragElement: {
				position: 'absolute',
				top: 0,
				right: 0,
				bottom: 0,
				width: 5,

				'&:hover': {
					cursor: 'ew-resize',
					backgroundColor: theme.palette.primary.main,
				},
			},
			mapSection: {
				flex: '1 1 auto',
				overflow: 'hidden',
			},
			map: {
				position: 'relative',
				width: '100%',
				height: '100%',
			},
			offersContainer: {
				position: 'relative',
				padding: spacing.Gutter.Desktop,
				overflow: 'auto',
				flex: '1 1 100%',

				backgroundPosition: 'center',
				backgroundSize: 'cover',
			},
			touchDeviceOnly: {
				display: 'none',
				'@media (hover: none)': {
					display: 'inline-flex',
				},
			},
			profileImage: {
				width: 60,
				height: 60,
				borderRadius: '50%',
				objectFit: 'cover',
			},
		}),
	{ name: 'HomeDesktop' }
);

const LocalStorageKey = 'HomeDesktop:width';
const initWidth = localStorage.getItem(LocalStorageKey);

const OffersMap: FC = () => {
	const { offers } = useOffers();
	return <Map offers={offers} allowMarkerClick />;
};

const HomeDesktop: FC<{ scrollContainer?: HTMLElement | null }> = ({
	scrollContainer,
}) => {
	const cls = useStyles();
	const { app } = useApp();
	const [showMap, setShowMap] = useState(true);
	const targetRef = useRef<HTMLElement>(null);
	const knobRef = useRef<HTMLDivElement>(null);

	const [width, setWidth] = useState<number | undefined>(
		(initWidth && parseFloat(initWidth)) || window.innerWidth * (2 / 3)
	);
	const [gridColumnNumber, setGridColumnNumber] = useState<number>();

	const onMouseMove = useCallback(({ pageX }: MouseEvent) => {
		setWidth(pageX);
	}, []);

	const onMouseUp = useCallback(() => {
		window.removeEventListener('mousemove', onMouseMove);
		window.removeEventListener('mouseup', onMouseUp);
	}, [onMouseMove]);

	const onMouseDown = useCallback(() => {
		if (!knobRef.current) {
			return;
		}

		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('mouseup', onMouseUp);
	}, [knobRef, onMouseUp, onMouseMove]);

	const onToggleMap = useCallback(() => {
		if (showMap) {
			setShowMap(false);
		} else {
			setWidth(window.innerWidth * (2 / 3));
			setShowMap(true);
		}
	}, [showMap]);

	useEffect(() => {
		const { current: knob } = knobRef;
		if (!knob) {
			return;
		}

		knob.addEventListener('mousedown', () => onMouseDown());

		// eslint-disable-next-line consistent-return
		return () => {
			if (knob) {
				knob.removeEventListener('mousedown', () => onMouseDown());
			}
		};
	}, [knobRef, onMouseDown, onMouseUp]);

	useEffect(() => {
		localStorage.setItem(LocalStorageKey, width?.toString() || '');
	}, [width]);

	useEffect(() => {
		if (!width) {
			setGridColumnNumber(5);
		} else if (width > 1200) {
			setGridColumnNumber(5);
		} else if (width > 900) {
			setGridColumnNumber(4);
		} else if (width > 640) {
			setGridColumnNumber(3);
		} else {
			setGridColumnNumber(2);
		}
	}, [width]);

	const bgImage = app && app?.Config && app?.Config?.BackgroundImage;

	return (
		<div className={cls.root}>
			<Header
				className={cls.header}
				scrollContainer={scrollContainer}
				variant='themeTransparent'
			>
				<IconButton
					type='button'
					className={cls.touchDeviceOnly}
					onClick={onToggleMap}
					aria-label='map view'
				>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='36'
						height='36'
						fill='none'
						stroke='currentColor'
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth='1.5'
						className='feather feather-map'
						viewBox='0 0 24 24'
					>
						<path d='M1 6L1 22 8 18 16 22 23 18 23 2 16 6 8 2 1 6z' />
						<path d='M8 2L8 18' />
						<path d='M16 6L16 22' />
					</svg>
				</IconButton>
			</Header>
			<section
				className={cls.contentSection}
				data-behavior='home-desktop-container'
				ref={targetRef}
				data-column-number={gridColumnNumber}
				style={
					showMap && width ? { width } : { width: '100%', maxWidth: '100%' }
				}
			>
				<Filters />
				<div
					className={cls.offersContainer}
					data-behavior='home-list'
					style={
						bgImage
							? { backgroundImage: `url(${app?.Config.BackgroundImage.Src})` }
							: undefined
					}
				>
					<OfferList />
				</div>
				<div ref={knobRef} className={cls.dragElement} data-behavior='knob' />
			</section>
			{showMap && (
				<section className={cls.mapSection}>
					<div className={cls.map} data-behavior='map'>
						<OffersMap />
					</div>
				</section>
			)}
		</div>
	);
};

export default HomeDesktop;
