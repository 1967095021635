const IsTouch = () => {
	const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
	const mq = (q: string) => window.matchMedia(q).matches;

	if (
		'ontouchstart' in window ||
		((window as any).DocumentTouch && document instanceof DocumentTouch)
	) {
		return true;
	}

	// include the 'heartz' as a way to have a non matching MQ to help terminate the join
	// https://git.io/vznFH
	const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join(
		''
	);
	return mq(query);
};

export default IsTouch;
