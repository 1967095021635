import { useMemo } from 'react';

interface UseMockUserOptions {
	actualUser?: UseraccountsDto;
	mockEnabled?: boolean;
}

function useMockUser({
	actualUser,
	mockEnabled = false,
}: UseMockUserOptions): UseraccountsDto | undefined {
	const mockUser = useMemo(
		() =>
			({
				Id: 43421,
				UserName: '    305567',
				Email: 'severin+112@ourcompany.ch',
				FirstName: 'sev112',
				LastName: '112',
				Gender: 'Other',
				CalculatedAge: 20,
				Birth: '2002-11-12T00:00:00',
				SubscriptionEnd: '2023-09-05T00:00:00',
				UserDefinedCode5: '0003',
				SubscriptionStart: '2021-07-23T00:00:00',
				Validation: 'Pending',
				Address: '112',
				CompanyName1: '',
				COAddress: '',
				City: '112',
				ZipCode: '112',
				Province: 'BL',
				Country: '',
				TimeLeft: 1,
				EpaymentType: 'None',
				EpaymentLastTransactionStatus: 1,
				Active: false,
				NotificationMail: 'severin+112@ourcompany.ch',
				NotificationName: '',
				IsAdmin: true,
				CategoryCode: 'ADM ',
			} as unknown as UseraccountsDto),
		[]
	);

	if (mockEnabled && process.env.NODE_ENV !== 'production') {
		return mockUser;
	}

	return actualUser;
}

export default useMockUser;
