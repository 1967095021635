import React, { FC } from 'react';
import { FieldError } from 'react-hook-form';
import FormStyles from './FormStyles';

type InpuTextProps = React.HTMLProps<HTMLInputElement> & {
	register: any;
	label?: string;
	// name: string;
	readonly?: boolean;
	error: FieldError | undefined;
};

const InputText: FC<InpuTextProps> = ({
	register,
	label,
	// name,
	error,
	readonly,
	children,
	...rest
}) => {
	const formCls = FormStyles();

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={formCls.inputContainer} data-error={!!error}>
			{label && <div className={formCls.label}>{label}</div>}
			<input
				type='text'
				className={formCls.inputText}
				readOnly={readonly}
				{...register}
				{...rest}
			/>
			{error && (
				<div className={formCls.error}>
					{error.type === 'required'
						? 'Pflichtfeld'
						: error.message || 'Ungültiger Wert'}
				</div>
			)}
			{children}
		</label>
	);
};

export default InputText;
