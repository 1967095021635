import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			group: {
				display: 'inline-flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				columnGap: 40,
				rowGap: 20,
			},
			root: {
				display: 'inline-flex',
				flexDirection: 'column',
				alignItems: 'center',
				color: 'inherit',
			},
			icon: ({ color = '#000000' }: any) => ({
				'& svg': {
					border: `2px solid ${color}`,
					borderRadius: '50%',
					width: 70,
					height: 70,
				},
			}),
			label: {
				marginTop: 4,
				fontWeight: 'bold',
				fontSize: rem(16, bodyFontSize.Mobile),
				letterSpacing: '0.01em',
				lineHeight: 1,
				textTransform: 'uppercase',
			},
		}),
	{
		name: 'LargeIconButton',
	}
);

interface LargeIconButtonGroupProps {
	children?: React.ReactNode;
}
export const LargeIconButtonGroup: FC<LargeIconButtonGroupProps> = ({
	children,
}) => {
	const cls = useStyles({ color: '#000000' });

	return <div className={cls.group}>{children}</div>;
};

interface LargeIconButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	color?: string;
	icon: React.ReactNode;
}
const LargeIconButton: FC<LargeIconButtonProps> = ({
	color = '#000000',
	icon,
	children,
	...rest
}) => {
	const cls = useStyles({ color });

	return (
		<button type='button' className={cls.root} {...rest}>
			<span className={cls.icon}>{icon}</span>
			<span className={cls.label}>{children}</span>
		</button>
	);
};

export default LargeIconButton;
