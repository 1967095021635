import React, { FC, useCallback, useEffect, useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useOffers } from 'src/@context/Offers';
import Card from '../components/Card';
import spacing from '../constants/spacing';
// import OfferCard from '../components/OfferCard';
// import AdvertisementCard from '../components/AdvertisementCard';

const useStyles = makeStyles(() =>
	createStyles({
		root: {
			display: 'grid',
			gap: spacing.Gutter.Desktop,
			'[data-column-number="2"] &': {
				gridTemplateColumns: '1fr 1fr',
			},
			'[data-column-number="3"] &': {
				gridTemplateColumns: '1fr 1fr 1fr',
			},
			'[data-column-number="4"] &': {
				gridTemplateColumns: '1fr 1fr 1fr 1fr',
			},
			'[data-column-number="5"] &': {
				gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
			},
		},
	})
);

const OfferList: FC = () => {
	const cls = useStyles();
	const { offers, hasMore, loading, load } = useOffers();
	const loader = useRef<HTMLDivElement>(null);

	const more = useCallback(
		(entries) => {
			const { isIntersecting } = entries[0];
			if (!isIntersecting || !hasMore || loading) {
				return;
			}
			load();
		},
		[hasMore, load, loading]
	);

	useEffect(() => {
		const options: IntersectionObserverInit = {
			rootMargin: '50px',
			threshold: 0.25,
		};

		const observer = new IntersectionObserver(more, options);
		const { current } = loader;
		if (current) {
			observer.observe(current);
		}

		return () => {
			if (current) observer.unobserve(current);
		};
	}, [loader, more]);

	return (
		<ul className={cls.root} data-collum-number={4}>
			{offers.map((m, i) => (
				<Card key={m.ObjectId || i} item={m} />
			))}
			{(hasMore || loading) && <div ref={loader}>load……</div>}
		</ul>
	);
};

export default OfferList;
