/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import classNames from 'classnames';
import { createStyles, fade, makeStyles } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';
import Icon, { IconProps } from './Icon';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			button: {
				width: 70,
				height: 70,
				borderRadius: '50%',
				fontWeight: 'bold',
				color: 'black',
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
			themeTransparent: {
				backgroundColor: fade(theme.palette.primary.main, 0.6),
				'&:hover': {
					backgroundColor: theme.palette.primary.main,
				},
			},
			whiteTransparent: {
				backgroundColor: fade('#fff', 0.5),
				'&:hover': {
					backgroundColor: '#fff',
				},
			},
			fontIcon: {
				fontSize: rem(33, bodyFontSize.Desktop),
				fontWeight: 'bold',
				lineHeight: 1,
				fontFamily: 'icons',
				'&::before': {
					content: 'attr(data-icon)',
				},
			},
		}),
	{ name: 'IconButton' }
);

type ButtonBaseProps = {
	icon?: IconProps['icon'];
	variant?: 'themeTransparent' | 'whiteTransparent';
	children?: React.ReactNode;
};

type ButtonAsButtonProps = ButtonBaseProps &
	Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof ButtonBaseProps> & {
		as?: 'button';
	};

type ButtonAsLinkProps = ButtonBaseProps &
	Omit<LinkProps, keyof ButtonBaseProps> & {
		as: 'link';
	};

export type IconButtonProps = ButtonAsButtonProps | ButtonAsLinkProps;

const IconButton: FC<IconButtonProps> = (commonProps) => {
	const cls = useStyles();
	const {
		icon,
		variant = 'themeTransparent',
		children,
		className,
		...props
	} = commonProps;

	const finalClassName = classNames(
		cls.button,
		{
			[cls.themeTransparent]: variant === 'themeTransparent',
			[cls.whiteTransparent]: variant === 'whiteTransparent',
		},
		className
	);

	const child = icon ? <Icon icon={icon} size='large' /> : children;

	if (props.as === 'link') {
		const { as: _as, ...rest } = props;

		return (
			<Link {...rest} className={finalClassName}>
				{child}
			</Link>
		);
	}

	const { as: _as, ...rest } = props;

	return (
		<button type='button' className={finalClassName} {...rest}>
			{child}
		</button>
	);
};

export default IconButton;
