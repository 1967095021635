/* eslint-disable react/destructuring-assignment */
import React, { FC } from 'react';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@material-ui/core';
import { rem } from '../utils/Units';
import { bodyFontSize } from '../constants/stylesheet';
import fontIcon from '../constants/fontIcon';

const useStyles = makeStyles(
	() =>
		createStyles({
			icon: {
				fontWeight: 'bold',
				lineHeight: 1,
				fontFamily: 'icons',
				'&::before': {
					content: 'attr(data-icon)',
				},
			},
			sizeLarge: {
				fontSize: rem(33, bodyFontSize.Desktop),
			},
		}),
	{ name: 'Icon' }
);

export type IconProps = Omit<React.HTMLProps<HTMLSpanElement>, 'size'> & {
	size?: 'default' | 'large';
	icon: keyof typeof fontIcon;
	children?: React.ReactNode;
	className?: string;
};

const Icon: FC<IconProps> = ({
	size = 'default',
	icon,
	className,
	...rest
}) => {
	const cls = useStyles();

	return (
		<span
			className={classNames(
				cls.icon,
				{ [cls.sizeLarge]: size === 'large' },
				className
			)}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: `&#x${fontIcon[icon].content.slice(2, -1)};`,
			}}
			{...rest}
		/>
	);
};

export default Icon;
