import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OfferSection from './OfferSection';
import OfferSavePdfButton from './OfferSavePdfButton';
import LargeIconButton, { LargeIconButtonGroup } from './LargeIconButton';

const useStyles = makeStyles((theme) => createStyles({}), {
	name: 'OfferSectionAdmin',
});

const OfferSectionAdmin: FC<
	(OfferDto | OfferAdminDto) & { setShowEdit: (show: boolean) => void }
> = ({ setShowEdit, ...offer }) => {
	const cls = useStyles();

	return (
		<OfferSection title='Admin'>
			<LargeIconButtonGroup>
				<OfferSavePdfButton offer={offer as OfferAdminDto} />
				<LargeIconButton
					color='#A3C038'
					onClick={() => setShowEdit(true)}
					icon={
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='127'
							height='126'
							fill='none'
							viewBox='0 0 127 126'
						>
							<path
								fill='#A3C038'
								d='M30.821 93.219l5.654-17.582 35.63-35.63 11.851 11.85-35.63 35.63-17.505 5.732zm11.851-17.662l2.014 1.936 29.356-29.356-1.936-2.014-29.434 29.434zm33.461-39.58l7.746-7.746 11.928 11.851-7.823 7.746-11.85-11.851z'
							/>
						</svg>
					}
				>
					Edit
				</LargeIconButton>
			</LargeIconButtonGroup>
		</OfferSection>
	);
};

export default OfferSectionAdmin;
