/* eslint-disable no-restricted-syntax */
import React, { FC, useEffect, useState } from 'react';
import FormStyles from './Form/FormStyles';
import { useUsers } from '../@context/Users';

// Implemented based on the copy-paste subscribe form from Campaign Monitor: https://github.com/our-company-ltd-team/colourkey.ch-legacy/issues/388#issuecomment-1862782985
const NewsletterSignupForm: FC = () => {
	const { current } = useUsers();
	const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);
	const formCls = FormStyles();

	useEffect(() => {
		if (!containerEl) {
			return;
		}

		const script = document.createElement('script');

		script.src =
			'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js';

		containerEl.appendChild(script);
	}, [containerEl]);

	return (
		<div
			ref={setContainerEl}
			// eslint-disable-next-line react/no-danger
			dangerouslySetInnerHTML={{
				__html: `
					<div>
						<form
							class="js-cm-form"
							id="subForm"
							action="https://www.createsend.com/t/subscribeerror?description="
							method="post"
							data-id="30FEA77E7D0A9B8D7616376B9006323181187239E41DEBFC9304E601446CAF6B1BFE8827030D3C653EECD99680EC15E7F5DA9B70966FE9344A067AFAEB75C977"
						>
							<label class="${formCls.inputContainer}">
								<div class="${formCls.label}">Name</div>
								<input class="${formCls.inputText}" value="${
					current?.FirstName || ''
				}" aria-label="Name" id="fieldName" maxlength="200" name="cm-name" placeholder="Name" />
							</label>
							<label class="${formCls.inputContainer}">
								<div class="${formCls.label}">E-Mail-Adresse</div>
								<input
									class="js-cm-email-input qa-input-email ${formCls.inputText}"
									value="${current?.Email || ''}"
									autocomplete="Email"
									aria-label="E-Mail"
									id="fieldEmail"
									maxlength="200"
									name="cm-yulhmh-yulhmh"
									placeholder="E-Mail-Adresse"
									required=""
									type="email"
								/>
							</label>
							<button class="${formCls.submit}" type="submit">Anmelden</button>
						</form>
					</div>
				`,
			}}
		/>
	);
};

export default NewsletterSignupForm;
