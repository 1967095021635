import { makeStyles, createStyles } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useOffers } from 'src/@context/Offers';
import { useUsers } from 'src/@context/Users';
import { bodyFontSize } from 'src/constants/stylesheet';
import { rem } from 'src/utils/Units';
import spacing from '../constants/spacing';
import Image from './Image';
import OfferScoreStrip from './OfferScoreStrip';
import { usePlatformMobile } from '../@context/PlatformMobileContext';

const useStyles = makeStyles(
	(theme) =>
		createStyles({
			list: {
				padding: `${spacing.Gutter.Mobile * 2}px ${spacing.Gutter.Mobile}px ${
					spacing.Gutter.Mobile
				}px ${spacing.Gutter.Mobile}px`,
			},
			listItem: {
				position: 'relative',
				height: 114,
				borderWidth: 0,
				borderStyle: 'solid',
				borderRadius: spacing.BoderRadius.Small,
				marginBottom: spacing.Gutter.Mobile,
				overflow: 'hidden',
				backgroundColor: theme.palette.primary.main,
			},
			listItemAd: {
				position: 'relative',
				borderRadius: spacing.BoderRadius.Small,
				marginBottom: spacing.Gutter.Mobile,
				overflow: 'hidden',
				zIndex: 0,
				height: 0,

				'&[data-size="false"]': {
					paddingBottom: '30%',
				},

				'&[data-size="true"]': {
					paddingBottom: '50%',
				},
			},
			titleContainer: {
				padding: spacing.Gutter.Mobile / 2,
				width: '42%',
			},
			title: {
				hyphens: 'auto',
				hyphenateLimitChars: '5 5',
				'-webkitHyphenateLimitBefore': 5 /* For Safari */,
				'-webkitHyphenateLimitAfter': 5 /* For Safari */,
				fontSize: rem(18, bodyFontSize.Mobile),
				fontWeight: 500,
				letterSpacing: '0.01em',
				lineHeight: 1,
				lineClamp: 5,
				display: 'box',
				boxOrient: 'vertical',
				overflow: 'hidden',
			},
			figure: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				width: '58%',

				'$listItemAd &': {
					width: '100%',
				},
			},
			image: {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				objectFit: 'cover',
				fontFamily: '"object-fit: cover;"',
				objectPosition: 'center',
			},
			scores: {
				position: 'absolute',
				top: 0,
				right: 0,
				left: 0,
				zIndex: 2,
			},
			special: {
				position: 'absolute',
				display: 'block',
				fontSize: rem(12, bodyFontSize.Mobile),
				padding: '5px 25px',
				transformOrigin: 'left bottom',
				transform: 'translateX(41px) rotate(-45deg)',
				right: 0,
				bottom: -18,
				backgroundColor: theme.palette.primary.main,
			},
			link: {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 1,
			},
		}),
	{ name: 'MobileOfferList' }
);

const MobileOfferList: FC = () => {
	const cls = useStyles();
	const { admin } = useUsers();
	const { offers, hasMore, loading, load } = useOffers();
	const loader = useRef<HTMLDivElement>(null);
	const platformMobile = usePlatformMobile()

	const more = useCallback(
		(entries) => {
			const { isIntersecting } = entries[0];
			if (!isIntersecting || !hasMore || loading) {
				return;
			}
			load();
		},
		[hasMore, load, loading]
	);

	useEffect(() => {
		const options: IntersectionObserverInit = {
			rootMargin: '50px',
			threshold: 0.25,
		};

		const observer = new IntersectionObserver(more, options);
		const { current } = loader;
		if (current) {
			observer.observe(current);
		}

		return () => {
			if (current) observer.unobserve(current);
		};
	}, [loader, more]);

	return (
		<div className={cls.list}>
			{offers.map((o) => {
				switch (o.type) {
					case 'Advertisement':
						// eslint-disable-next-line no-case-declarations
						const ad = o as unknown as AdvertisementDto;

						return (
							<article
								className={cls.listItemAd}
								key={ad.ImageList.Id}
								data-size={ad.isBig}
							>
								<div className={cls.figure}>
									{/.gif$/.test(ad.ImageList.Src) ? (
										<img
											src={ad.ImageList.Src}
											alt={ad.ImageList.Legend}
											className={cls.image}
										/>
									) : (
										<Image
											pictureRef={ad.ImageList}
											className={cls.image}
											sizes='(max-width: 767px) 100vw, 70vw'
										/>
									)}
								</div>
								{/* Don't open ads in new tab on mobile. This way they open inside the app if possible */}
								<Link to={ad.Link} target={platformMobile.isActive ? undefined : '_blank'} className={cls.link} />
							</article>
						);

					default:
						return (
							<article key={o.UID} className={cls.listItem}>
								<div className={cls.titleContainer}>
									<h3 className={cls.title}>{o.Title}</h3>
								</div>
								<figure className={cls.figure}>
									<Image pictureRef={o.PreviewPicture} className={cls.image} />
									<OfferScoreStrip className={cls.scores} offer={o} />
								</figure>
								{o.Banner && !o.IsGratis && (
									<span className={cls.special}>{o.Banner}</span>
								)}
								{o.IsGratis && <span className={cls.special}>gratis</span>}
								<Link
									to={
										admin
											? `/offers/${(o as OfferAdminDto).ObjectId}`
											: `/angebot/${o.UID}`
									}
									className={cls.link}
								/>
							</article>
						);
				}
			})}
			{(hasMore || loading) && <div ref={loader}>load……</div>}
		</div>
	);
};

export default MobileOfferList;
