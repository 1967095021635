import React, { FC, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useUsers } from 'src/@context/Users';
import mediaQueries from 'src/constants/mediaQueries';
import spacing from 'src/constants/spacing';
import { bodyFontSize } from 'src/constants/stylesheet';

import { rem } from 'src/utils/Units';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FilterIcon } from './FilterIcons';
import Image from './Image';
import OfferEdit from './OfferEdit';
import PageLayout, { PageLayoutProps } from './PageLayout';
import OfferSectionContact from './OfferSectionContact';
import OfferSectionCampaign from './OfferSectionCampaign';
import OfferSectionShare from './OfferSectionShare';
import OfferSectionAdmin from './OfferSectionAdmin';
import OfferSectionSustainability from './OfferSectionSustainability';
import fontStyles from '../constants/fontStyles';
import OfferSectionLikes from './OfferSectionLikes';
import { useOffers } from '../@context/Offers';

const useStyles = makeStyles(
	() =>
		createStyles({
			pageLayout: {
				[mediaQueries.AfterMobile]: {
					paddingTop: '50vh',
				},
				[mediaQueries.BeforeTablet]: {
					paddingTop: '0',
				},
			},
			// Make it possible to swipe through offers without jank
			pageLayoutCarousel: {
				touchAction: 'pan-y',
			},
			root: {
				position: 'relative',
				'&[data-contact="true"]': {
					height: '100%',
					overflow: 'hidden',
				},

				[mediaQueries.AfterMobile]: {
					borderRadius: 8,
					borderBottomRightRadius: 0,
					borderBottomLeftRadius: 0,
					width: 'max(690px, 50vw)',
					margin: 'auto',
				},
			},
			figure: {
				position: 'relative',
				height: 0,
				paddingBottom: '53.52%',
				[mediaQueries.AfterMobile]: {
					display: 'none',
				},
			},
			iconContainer: {
				paddingTop: 40,
				paddingBottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				'& svg': {
					width: 100,
					height: 100,
				},
				[mediaQueries.AfterMobile]: {
					paddingTop: 50,
					paddingBottom: 40,
				},
			},
			image: {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				objectFit: 'cover',
				objectPosition: 'center',
				fontFamily: 'object-fit: cover;',
			},
			bgImage: {
				backgroundColor: 'white',
				position: 'absolute',
				inset: 0,
				width: '100%',
				height: '100%',
				objectFit: 'cover',
				objectPosition: 'center',
				fontFamily: 'object-fit: cover;',
				pointerEvents: 'none',
				[mediaQueries.BeforeTablet]: {
					display: 'none',
				},
			},
			content: {
				position: 'relative',
				textAlign: 'center',

				[mediaQueries.Mobile]: {
					padding: `${spacing.Gutter.Mobile * 2}px ${spacing.Gutter.Mobile}px`,
				},
				[mediaQueries.AfterMobile]: {
					padding: spacing.Gutter.Desktop * 2,
					paddingTop: 0,
				},
			},
			title: {
				...fontStyles.title,
				position: 'relative',
				color: 'black',
				marginBottom: rem(20, bodyFontSize.Mobile),
				[mediaQueries.AfterMobile]: {
					...(fontStyles.title as any)[mediaQueries.AfterMobile],
					padding: '0 50px',
				},
			},
			text: {
				...fontStyles.defaultText,

				'& a': {
					textDecoration: 'underline',
				},
			},
			hashtagsContainer: {
				...fontStyles.defaultText,
				display: 'flex',
				gap: 16,
				flexWrap: 'wrap',
				justifyContent: 'center',
				marginTop: rem(20, bodyFontSize.Mobile),
			},
			hashtag: {
				display: 'inline-block',
				fontSize: rem(20, bodyFontSize.Mobile),
				padding: '6px 12px',
				backgroundColor: '#DEDEDE',
				borderRadius: 8,
			},
			inactive: {},
			mobile: {},
			desktop: {},
		}),
	{
		name: 'Offer',
	}
);

const Offer: FC<
	(OfferDto | OfferAdminDto) & {
		isActive?: boolean;
		isCarousel: boolean;
		setScrollContainer?: PageLayoutProps['setScrollContainer'];
	}
> = (props) => {
	const cls = useStyles();
	const {
		children,
		isActive = true,
		isCarousel,
		setScrollContainer,
		...offer
	} = props;
	const { Title, PreviewPicture, PlaceText } = offer;
	const { admin } = useUsers();
	const [showEdit, setShowEdit] = useState(false);
	const hashtags = offer.Hashtags?.split(' ') || [];
	const { filters, setFilters } = useOffers();

	const toggleEdit = (v: boolean) => setShowEdit(v);

	return (
		<>
			<Image
				pictureRef={PreviewPicture}
				sizes='(max-width: 767px) 100vw,(max-width: 1024px) 70vw, 50vw'
				className={cls.bgImage}
				alt={PreviewPicture?.Legend || ''}
			/>
			<PageLayout
				background='transparent'
				spaceForHeader={false}
				fullWidth
				scrollLockScrollable
				setScrollContainer={setScrollContainer}
				className={classNames(
					cls.pageLayout,
					isCarousel && cls.pageLayoutCarousel
				)}
			>
				<section className={cls.root}>
					<figure className={cls.figure}>
						<Image
							pictureRef={PreviewPicture}
							sizes='(max-width: 767px) 100vw,(max-width: 1024px) 70vw, 50vw'
							className={cls.image}
							alt={PreviewPicture?.Legend || ''}
						/>
					</figure>
					<div className={cls.iconContainer}>
						<FilterIcon category={offer.Icon} />
					</div>
					<article className={cls.content}>
						<h2 className={cls.title}>{Title}</h2>
						<div
							className={cls.text}
							dangerouslySetInnerHTML={{
								__html: PlaceText?.replace(/\n/g, '<br />') || '',
							}}
						/>
						{hashtags.length > 0 && (
							<div className={cls.hashtagsContainer}>
								{hashtags.map((hashtag, i) => {
									const onClick = () => {
										// We need to manually setFilters, otherwise the filters on the OfferPage won't get updated
										setFilters({ ...filters, Query: hashtag });
									};

									return (
										<Link
											// eslint-disable-next-line react/no-array-index-key
											key={i}
											// Link to the offers page with the Query set so that it gets picked up user is opening new tab
											to={`/?Query=${encodeURIComponent(hashtag)}`}
											className={cls.hashtag}
											onClick={onClick}
										>
											{hashtag}
										</Link>
									);
								})}
							</div>
						)}
						<OfferSectionCampaign {...offer} />
						<OfferSectionLikes fetchFreshOffer={isActive} {...offer} />
						<OfferSectionSustainability {...offer} />
						<OfferSectionContact {...offer} />
						<OfferSectionShare {...offer} />
						{admin && (
							<OfferSectionAdmin {...offer} setShowEdit={setShowEdit} />
						)}
					</article>
					{children}
				</section>
			</PageLayout>
			{showEdit && (
				<OfferEdit {...(offer as OfferAdminDto)} closeEdit={toggleEdit} />
			)}
		</>
	);
};

export default Offer;
