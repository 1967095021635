import React, { FC, useCallback } from 'react';
import { useOffers } from 'src/@context/Offers';
import { useApp } from 'src/@context/App';
import useStyles from './FiltersAdmin.styles';
import FilterDropdown from './FilterDropdown';

const allCriterias = [
	'Special',
	'Free',
	'Sustainability',
	'Likes',
] as OfferSortCriteria[];

const allOrders = [
	'Modification',
	'EndDate',
	'StartDate',
	'Random',
	'Likes',
	'Sustainability',
] as OfferSortOrder[];

const allFilters = ['Online', 'Expired'] as (keyof SearchQueryBinding)[];

const FiltersAdmin: FC = () => {
	const cls = useStyles();
	const { filters, setFilters, sort, setSort, saveSort } = useOffers();
	const { app } = useApp();

	const setCriteria = useCallback(
		(id: OfferSortCriteria | undefined) => {
			setSort({ ...sort, Criteria: id });
		},
		[sort, setSort]
	);

	const setOrder = useCallback(
		(order: OfferSortOrder | undefined) => {
			setSort({ ...sort, Order: order });
		},
		[sort, setSort]
	);

	const setFilter = useCallback(
		(name: string) => (value: string) => {
			const result = value === '' ? undefined : value === `${true}`;
			setFilters({ ...filters, [name]: result });
		},
		[filters, setFilters]
	);

	return (
		<section className={cls.root} data-behavior='filters-container'>
			<div className={cls.title}>Admin options</div>
			<div className={cls.filtersSection}>
				<div className={cls.dropdownContent}>
					<FilterDropdown
						label='Sort Criteria'
						values={[
							{ value: 'Online', name: 'All' },
							...allCriterias.map((v) => ({ value: v, name: v })),
						]}
						value={sort.Criteria || app?.Config?.OfferSortCriteria || ''}
						onSelect={(id) => setCriteria(id as OfferSortCriteria)}
					/>
				</div>
				<div className={cls.dropdownContent}>
					<FilterDropdown
						label='Sort Order'
						values={allOrders.map((v) => ({ value: v, name: v }))}
						value={sort.Order || app?.Config?.OfferSortOrder || ''}
						onSelect={(id) => setOrder(id as OfferSortOrder)}
					/>
				</div>
			</div>
			<div className={cls.dropdownContent}>
				<div className={cls.title}>Show only</div>

				<div className={cls.filtersSection}>
					{allFilters.map((filter) => (
						<div className={cls.dropdownContent} key={filter}>
							<FilterDropdown
								label={filter}
								values={[
									{ value: '', name: 'All' },
									{ value: `${true}`, name: 'Yes' },
									{ value: `${false}`, name: 'No' },
								]}
								value={
									filters[filter] === undefined
										? ''
										: (filters[filter]?.toString() as string)
								}
								onSelect={setFilter(filter)}
							/>
						</div>
					))}
				</div>
			</div>

			<div className={cls.btnWrapper}>
				<button className={cls.btn} type='button' onClick={saveSort}>
					save
				</button>
			</div>
		</section>
	);
};

export default FiltersAdmin;
