import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useUsers } from 'src/@context/Users';
import Image from './Image';
import useStyles from './OfferCard.styles';
import OfferScoreStrip from './OfferScoreStrip';

const OfferCard: FC<OfferDto | OfferAdminDto> = ({ children, ...offer }) => {
	const { UID, PreviewPicture, Title, Banner, IsGratis } = offer;
	const cls = useStyles();
	const { admin } = useUsers();

	return (
		<li className={cls.cardItem} key={UID}>
			<div className={cls.cardItemInner}>
				<div className={cls.cardItemTitleContainer}>
					<h2 className={cls.cardItemTitle}>{Title}</h2>
				</div>
				<div className={cls.cardItemFigure}>
					{/.gif$/.test(PreviewPicture.Src) ? (
						<img
							src={PreviewPicture.Src}
							alt={PreviewPicture.Legend}
							className={cls.cardItemImage}
						/>
					) : (
						<Image pictureRef={PreviewPicture} className={cls.cardItemImage} />
					)}
					<OfferScoreStrip className={cls.scores} offer={offer} />
				</div>
				{Banner && !IsGratis && (
					<div className={cls.specialOffer}>{Banner}</div>
				)}
				{IsGratis && <div className={cls.specialOffer}>gratis</div>}
				<Link
					to={
						admin
							? `/offers/${(offer as OfferAdminDto).ObjectId}`
							: `/angebot/${UID}`
					}
					className={cls.link}
				/>
			</div>
			{children}
		</li>
	);
};
export default React.memo(OfferCard);
