import React, { FC, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PageLayout from './PageLayout';
import GlobeIcon from './MonkeySvgs/GlobeIcon';

const animation = {
	'0%': {
		transform: 'translateX(-100%)',
	},
	'100%': {
		transform: 'translateX(0)',
	},
};

const useStyles = makeStyles(
	({ palette }) =>
		createStyles({
			root: {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 999,
				backgroundColor: 'rgba(0,0,0)',

				display: 'flex',
				alignItems: 'center',
				overflow: 'hidden',
			},
			spaceInvaderContainer: {
				flex: '1 1 auto',

				position: 'relative',
				display: 'flex',
				alignItems: 'center',

				transform: 'translate3d(0, 0, 0)',
				animationName: '$warnningAnimation',
				animationTimingFunction: 'linear',
				animationIterationCount: 'infinite',
				animationDuration: '2.5s',
				willChange: 'transform',
			},
			svgContainer: {
				flex: '1 0 100%',

				'& svg': {
					width: 200,
					height: 200,
				},
			},
			svgColor: {
				fill: palette.primary.main,
			},
			'@keyframes warnningAnimation': animation,
		}),
	{
		name: 'LoadingScreen',
	}
);

const LoadingScreen: FC = () => {
	const cls = useStyles();
	const [rightActive, setRightActive] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => setRightActive(!rightActive), 500);
		return () => {
			clearInterval(interval);
		};
	}, [rightActive, setRightActive]);

	return (
		<PageLayout background='default'>
			<div className={cls.root}>
				<div className={cls.spaceInvaderContainer}>
					<div className={cls.svgContainer}>
						<GlobeIcon className={cls.svgColor} />
					</div>
					<div className={cls.svgContainer}>
						<GlobeIcon className={cls.svgColor} />
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default LoadingScreen;
